import { Select, ListSubheader, MenuItem } from "@mui/material"
import { useTracks } from "../hooks/useTracks"
import TrackName from "../components/TrackName"


export default function TrackSelect({ track, onChange }) {
    const tracks = useTracks()
    return (<Select
        labelId="trackselect"
        value={track}
        variant="standard"
        size='large'
        defaultValue={track}
        label='Leaderboard'
        onChange={(e, v) => {
            onChange(e, v)
        }}
    >
        <ListSubheader sx={{ height: '30px', lineHeight: '30px', justifyContent: 'center' }}>{"Amateur Circuit"}</ListSubheader>
        {tracks.filter(t => t.circuit == 0).map((t) => <MenuItem value={t.tracknum - 1} key={t.tracknum - 1}><TrackName track={t.tracknum - 1} /></MenuItem>)}
        <ListSubheader sx={{ height: '30px', lineHeight: '30px', justifyContent: 'center' }}>{"Semi-Pro Circuit"}</ListSubheader>
        {tracks.filter(t => t.circuit == 1).map((t) => <MenuItem value={t.tracknum - 1} key={t.tracknum - 1}><TrackName track={t.tracknum - 1} /></MenuItem>)}
        <ListSubheader sx={{ height: '30px', lineHeight: '30px', justifyContent: 'center' }}>{"Galactic Circuit"}</ListSubheader>
        {tracks.filter(t => t.circuit == 2).map((t) => <MenuItem value={t.tracknum - 1} key={t.tracknum - 1}><TrackName track={t.tracknum - 1} /></MenuItem>)}
        <ListSubheader sx={{ height: '30px', lineHeight: '30px', justifyContent: 'center' }}>{"Invitational Circuit"}</ListSubheader>
        {tracks.filter(t => t.circuit == 3).map((t) => <MenuItem value={t.tracknum - 1} key={t.tracknum - 1}><TrackName track={t.tracknum - 1} /></MenuItem>)}
    </Select>)
}