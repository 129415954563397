import { Container, Stack } from "@mui/material";
import HeroBanner from "./HeroButton";
import Title from "../../tools/Title";
export default function StatHome() {
    return (
        <Container>
            <Title text='STATISTICS' color='#FFFFFF' />
            <Stack spacing={2}>
                <HeroBanner title='GAME' link={'/stats/game'} height = {300} img='https://cdn.discordapp.com/attachments/441842584592056320/748340900891197590/3ontatooineedited.png' />
                <HeroBanner title='SPEEDRUNS' link={'/stats/speedruns'} height = {300}  img = 'https://media.discordapp.net/attachments/554524314632978433/1094870945367347281/bengazza.png?width=1142&height=643'/>
                <HeroBanner title='TOURNAMENTS' link={'/stats/tournaments'} height = {300}  img='https://cdn.discordapp.com/attachments/441842584592056320/748360733732896868/Episode_I_Racer_8_26_2020_9_44_52_PM.png' />
                <HeroBanner title='CHALLENGES' link={'/stats/challenges'} height = {300}  img='https://media.discordapp.net/attachments/442116200147714049/841955878261096458/welcome3.jpg?width=990&height=518' />
            </Stack>
        </Container>
    )
}