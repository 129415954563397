

export function challengestats({ randomchallenges, randomchallengetimes, randomchallengefeedback, DataTab, Conditions, users, ChallengeTypes, FilterPlayers } = {}) {
    function matchConditions(Conditions, challenge) {
        let con = {
            trak: challenge.conditions.skips ? 'fs' : 'ft',
            laps: 'l' + challenge.conditions.laps,
            upgr: challenge.conditions.nu ? 'nu' : 'mu',
            dire: challenge.conditions.backwards ? 'bw' : 'fw',
            mirr: challenge.conditions.mirror ? 'mi' : 'um',
        }
        let r = !Object.keys(con).map(c => Conditions[c].includes(con[c])).includes(false)
        return r
    }
    // options: conditions + daily/random challenge/custom challenge
    // tracks (group by planet/circuit)
    // 	roll rate
    // 	play rate
    // 	likes
    // 	dislikes
    // 	custom challenges
    // racers
    // 	roll rate
    // 	play rate
    // 	likes
    // 	dislikes
    // 	custom challenges
    // players
    // 	challenges
    // 	truguts earned
    // 	truguts spent
    // 	achievements
    // 	records

    //step 0: initialize stat structure

    let rows = []
    //initialize stats
    let stats = {
        races: 0,
        track: {},
        racer: {},
        players: {},
        commentators: {}
    }
    for (let i = 0; i < 25; i++) {
        stats.track[i] = { plays: 0, rolls: 0, likes: 0, dislikes: 0, completion: {} }
        if (i < 23) {
            stats.racer[i] = { plays: 0, rolls: 0, likes: 0, dislikes: 0, completion: {} }
        }
    }
    let records = {}

    //types
    //individual random challenges
    //custom challenges
    //bounties
    //challenge of the day

    //step 1: iterate through all matches and get stats
    randomchallengetimes.filter(challenge => matchConditions(Conditions, challenge) && (FilterPlayers.includes(challenge.player) || FilterPlayers.length == 0)).filter(time => {
        let challenge = randomchallenges.find(c => c.id == time.challenge)
        return ChallengeTypes.length == 0 ||
            (ChallengeTypes.includes('Private (random)') && [null, undefined, '', 'private'].includes(challenge?.type)) ||
            (ChallengeTypes.includes('Daily (random)') && challenge?.type == 'cotd') ||
            (ChallengeTypes.includes('Bounty (random)') && challenge?.bounty) ||
            (ChallengeTypes.includes('Multiplayer (random)') && challenge?.type == 'multiplayer')
    }
    ).forEach(time => {
        if (!stats.players[time.user]) {
            stats.players[time.user] = { challenges: 0 }
        }
        if (Array.isArray(time.track)) {
            time.track.forEach(t => {

            })
        } else {
            stats.track[time.track].plays++
            stats.racer[time.racer].plays++
            stats.players[time.user].challenges++
            stats.track[time.track].completion[[time.racer, ...Object.values(time.conditions)].join("_")] = 1
            stats.racer[time.racer].completion[[time.track, ...Object.values(time.conditions)].join("_")] = 1
            if (!time.challenge) {
                stats.track[time.track].rolls++
                stats.racer[time.racer].rolls++
            }
            let challengestring = [time.racer, time.track, Object.values(time.conditions).join("_")].join("_")
            if (!records[challengestring] || (Number(time.time) - Number(records[challengestring].time) < 0)) {
                records[challengestring] = time
            }
        }


    })
    randomchallenges.filter(challenge => matchConditions(Conditions, challenge) && (FilterPlayers.includes(challenge.player?.member) || FilterPlayers.length == 0)).filter(challenge =>
        ChallengeTypes.length == 0 ||
        (ChallengeTypes.includes('Private (random)') && [null, undefined, '', 'private'].includes(challenge?.type)) ||
        (ChallengeTypes.includes('Daily (random)') && challenge?.type == 'cotd') ||
        (ChallengeTypes.includes('Bounty (random)') && challenge?.bounty) ||
        (ChallengeTypes.includes('Multiplayer (random)') && challenge?.type == 'multiplayer')
    ).forEach(challenge => {
        if (Array.isArray(challenge.track)) {

        } else {
            stats.track[challenge.track].rolls++
            stats.racer[challenge.racer].rolls++
        }
    })
    randomchallengefeedback.filter(challenge => matchConditions(Conditions, challenge) && (FilterPlayers.includes(challenge.user) || FilterPlayers.length == 0)).forEach(feedback => {
        stats.track[feedback.track][feedback.feedback == '👍' ? 'likes' : 'dislikes']++
        stats.racer[feedback.racer][feedback.feedback == '👍' ? 'likes' : 'dislikes']++
    })

    //step 2: assemble tables
    let headers = [
        { name: "Track", id: 'game', type: 'track' },
        [{ name: "Creation Rate", id: 'rolls', tip: 'Percentage of total rolls that feature this track', color: '#FFFF00', type: 'percent' },
        { name: "Challenges", id: 'challenges', tip: 'Number of challenges that feature this track', color: '#FFFF00', type: 'number' }
        ],
        [{ name: "Play Rate", id: 'playrate', tip: 'Percentage of times this track was played', color: '#00FFFF', type: 'percent' },
        { name: "Plays", id: 'plays', tip: 'Number of times this track was played', color: '#00FFFF', type: 'number' }
        ],
        { name: "Likes", id: 'likes', tip: 'Sum of likes on challenges featuring this track', color: '#FF0000', type: 'number' },
        { name: "Dislikes", id: 'dislikes', tip: 'Sum of dislikes on challenges featuring this track', color: '#00FF00', type: 'number' },
        { name: "Saturation", id: 'completion', tip: 'Percentage of unique track/racer/conditions combinations completed on this track', color: '#FF00FF', type: 'percent' }
    ]
    if (DataTab == 0) {
        for (let i = 0; i < 25; i++) {
            let track = {
                type: 'track',
                track: i,
                challenges: stats.track[i].rolls,
                rolls: (100 * stats.track[i].rolls / Object.values(stats.track).map(t => t.rolls).reduce((a, b) => a + b)).toFixed(2),
                plays: stats.track[i].plays,
                playrate: (100 * stats.track[i].plays / Object.values(stats.track).map(t => t.plays).reduce((a, b) => a + b)).toFixed(2),
                likes: stats.track[i].likes,
                dislikes: stats.track[i].dislikes,
                completion: (100 * Object.keys(stats.track[i].completion).length / (23 * 2 * 2 * 2 * 2 * 5)).toFixed(2)
            }
            rows.push(track)
        }
    } else if (DataTab == 1) {
        for (let i = 0; i < 23; i++) {
            let racer = {
                type: 'racer',
                racer: i,
                challenges: stats.racer[i].rolls,
                rolls: (100 * stats.racer[i].rolls / Object.values(stats.racer).map(t => t.rolls).reduce((a, b) => a + b)).toFixed(2),
                plays: stats.racer[i].plays,
                playrate: (100 * stats.racer[i].plays / Object.values(stats.racer).map(t => t.plays).reduce((a, b) => a + b)).toFixed(2),
                likes: stats.racer[i].likes,
                dislikes: stats.racer[i].dislikes,
                completion: (100 * Object.keys(stats.racer[i].completion).length / (25 * 2 * 2 * 2 * 2 * 5)).toFixed(2)
            }
            rows.push(racer)
        }
        headers[0] = { name: "Racer", id: 'game', type: 'racer' }
    } else if (DataTab == 2) {
        let players = users.filter(u => stats.players[u.discordID])
        players.forEach(player => {
            let user = {
                type: 'user',
                user: player.discordID,
                challenges: stats.players[player.discordID]?.challenges,
                truguts_earned: player.random.truguts_earned,
                truguts_spent: player.random.truguts_spent,
                achievements: player.random.achievements ? Object.values(player.random.achievements).map(ach => ach == true ? 1 : 0).reduce((a, b) => a + b) : 0,
                records: Object.values(records).map(r => r.user).filter(u => u == player.discordID).length
            }
            rows.push(user)
            headers = [
                { name: "Player", id: 'game', type: 'user' },
                { name: "Challenges", id: 'challenges', tip: 'Total number of completed challenges', color: '#FFFF00', type: 'number' },
                { name: "Truguts Earned", id: 'truguts_earned', tip: 'Total trugut earnings', color: '#00FFFF', type: 'number' },
                { name: "Truguts Spent", id: 'truguts_spent', tip: 'Total trugut spending', color: '#FF0000', type: 'number' },
                { name: "Achievements", id: 'achievements', tip: 'Total achievements completed', color: '#00FF00', type: 'number' },
                { name: "Records", id: 'records', tip: 'Number of unique challenges this player is the record holder', color: '#FF00FF', type: 'number' }
            ]
        })

    }

    return [headers, rows]
}