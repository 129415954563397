import { useState, useEffect } from 'react';
import { projectDatabase } from '../firebase/config';

export function useTournaments() {
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    const tourneyRef = projectDatabase.ref('tourney/tournaments');
    tourneyRef.once('value', (snapshot) => {
      const tourneyData = snapshot.val();
      const tourneyList = [];
      for (let tourneyId in tourneyData) {
        tourneyList.push({
          id: tourneyId,
          ...tourneyData[tourneyId],
        });
      }
      setTournaments(tourneyList);
    });
  }, []);

  return tournaments;
}