import { useState, useEffect } from 'react';
import { projectDatabase } from '../firebase/config';

export function useRulesets() {
  const [rulesets, setRulesets] = useState([]);

  useEffect(() => {
    const rulesetsRef = projectDatabase.ref('tourney/rulesets/saved');
    rulesetsRef.once('value', (snapshot) => {
      const rulesetsData = snapshot.val();
      const rulesetsList = [];
      for (let rulesetsId in rulesetsData) {
        rulesetsList.push({
          id: rulesetsId,
          ...rulesetsData[rulesetsId],
        });
      }
      setRulesets(rulesetsList);
    });
  }, []);

  return rulesets;
}