import React, { useState } from 'react';
import { styled } from '@mui/system';
import firebase from 'firebase/app';
import 'firebase/database';
import { Box, Autocomplete, InputAdornment, IconButton, TextField, MenuItem, Typography, Stack, CircularProgress, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import UserName from '../tools/UserName';
import { getName } from '../components/UserName'
import TrackName from './TrackName';
import RacerName from './RacerName'
import { useTracks } from '../hooks/useTracks';
import { useRacers } from '../hooks/useRacers';
import { useTournaments } from '../hooks/useTournaments';
import { timetoSeconds, timefix } from '../tools';
import TimeDisplay from '../tools/TimeDisplay';
import { Link } from 'react-router-dom'
function SearchBar() {
    const [searchResults, setSearchResults] = useState([]);
    const tracks = useTracks()
    const racers = useRacers()
    const tournaments = useTournaments()
    const [loading, setLoading] = useState(false)

    const CustomTextField = styled(TextField)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {
            // Customize the style of the outlined input
            '&.Mui-focused': {
                // Customize the style when the input is focused
            },
            // Add more styles as needed
        },
    }));

    const handleSearchInputChange = (event) => {
        let newSearchTerm = event.target.value;
        if (newSearchTerm) {
            newSearchTerm = newSearchTerm.toLowerCase().trim()
        }

        // Debounce the search query to avoid making too many requests
        const debounceTimeout = setTimeout(async () => {
            setLoading(true)
            // Search the Firebase database for users whose display names match the search term
            if (newSearchTerm && newSearchTerm.length >= 2) {
                const userRef = firebase.database().ref('users');
                const timeRef = firebase.database().ref('speedruns')
                const userquery = userRef.orderByChild('name').startAt(newSearchTerm.toUpperCase()).endAt(newSearchTerm.toLowerCase() + '\uf8ff');

                const newSearchResults = [];
                await userquery.once('value', (snapshot) => {
                    snapshot.forEach((childSnapshot) => {
                        const user = childSnapshot.val();
                        user.key = childSnapshot.key;
                        user.link = `/players/${user.key}`
                        user.type = 'user'
                        newSearchResults.push(user);
                    });
                });
                if (!isNaN(timetoSeconds(newSearchTerm))) {
                    const timequery = timeRef.orderByChild('time').startAt(timetoSeconds(newSearchTerm) - .001).limitToFirst(3);
                    await timequery.once('value', (snapshot) => {
                        snapshot.forEach((childSnapshot) => {
                            const time = childSnapshot.val();
                            time.key = childSnapshot.key;
                            time.link = `/runs/${time.key}`
                            time.type = 'time'
                            newSearchResults.push(time);
                        });
                    });
                }
                racers.forEach((racer, index) => {
                    if (racer?.name.toLowerCase().replaceAll("'", "").includes(newSearchTerm) || Object.values(racer?.nickname).includes(newSearchTerm)) {
                        newSearchResults.push({ ...racer, type: 'racer', key: index, link: `/racers/${racer.name.replaceAll("'", "").replaceAll(" ", "_")}` })
                    }
                })
                tracks.forEach((track, index) => {
                    if (track?.name.toLowerCase().replaceAll("'", "").includes(newSearchTerm) || Object.values(track?.nickname).includes(newSearchTerm)) {
                        newSearchResults.push({ ...track, type: 'track', key: index, link: `/tracks/${track.name.replaceAll("'", "").replaceAll(" ", "_")}` })
                    }
                })
                tournaments.forEach((tournament, index) => {
                    if (tournament?.name.toLowerCase().replaceAll("'", "").includes(newSearchTerm) || tournament?.nickname.includes(newSearchTerm)) {
                        newSearchResults.push({ ...tournament, type: 'tourney', key: index, link: `/tournaments/${tournament.nickname.replaceAll("'", "").replaceAll(" ", "_").replaceAll(".", "").toLowerCase()}` })
                    }
                })
                setLoading(false)
                setSearchResults(newSearchResults);
            } else {
                setLoading(false)
            }

        }, 300);

        // Clear the debounce timeout on subsequent input changes
        return () => clearTimeout(debounceTimeout);
    };

    return (<Box pr={2} pl={2} sx={{ minWidth: { md: '250px', xs: '50%' }, flexGrow: 1, display: { sx: 'flex', md: 'block' } }}>
        <Autocomplete
            size="small"
            fullWidth
            onInputChange={handleSearchInputChange}
            selectOnFocus
            clearOnEscape={false}
            clearOnBlur={false}
            handleHomeEndKeys
            freeSolo
            options={searchResults}
            getOptionLabel={(option) => option.type == 'time' ? timefix(option.time) : ['track', 'racer'].includes(option.type) ? [option.name, ...option.nickname].join(" ") : getName(option)}
            renderOption={(props, option) => (
                <Link to={option.link} style={{ textDecoration: 'none', color: '#FFFFFF' }}>
                    <MenuItem>
                        {option.type == 'user' ? <UserName user_object={option} avatar={true} size={20} /> :
                            option.type == 'track' ? <TrackName track={option.key} /> :
                                option.type == 'racer' ? <RacerName racer={option.key} /> :
                                    option.type == 'time' ? <TimeDisplay time={option.time} /> :
                                        option.type == 'tourney' ? option.name :
                                            ""}

                    </MenuItem></Link>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && searchResults ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}
                            </>
                        )
                    }}
                    placeholder="Take a look around..."
                />
            )}
        />
    </Box>
    );
}

export default SearchBar;
