import { Grid, Typography, Stack, Box, Avatar } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom'
import { useState, useEffect } from "react";
import { projectDatabase } from "../../../firebase/config";
import { getWinner, getScore } from "../../../hooks/useTourneyRuns";
import MicIcon from '@mui/icons-material/Mic';
import TokenIcon from '@mui/icons-material/Token';
import RepeatIcon from '@mui/icons-material/Repeat';
const AspectRatioBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    border: '6px ridge #ffffff',
    '&::before': {
        content: '""',
        display: 'block',
        paddingTop: '56.25%', // 16:9 aspect ratio
    },
}));

export default function Live() {
    const { id } = useParams()
    const [match, setMatch] = useState(null);
    const [players, setPlayers] = useState([])
    const [tourney, setTourney] = useState(null)
    let color = match?.color ?? '#22AAFF'
    useEffect(() => {
        const matchRef = projectDatabase.ref(`tourney/live/${id}`);
        matchRef.on('value', async (snapshot) => {
            let matchData = snapshot.val();
            setMatch(matchData);

            let keys = [...new Set(Object.values(matchData.players).concat(Object.values(matchData.commentators)))]
            const playerPromises = keys.map((key) => {
                return projectDatabase.ref(`users`).orderByChild('discordID').equalTo(key).once("value")
            })
            Promise.all(playerPromises).then((playerSnapshots) => {
                const playerData = playerSnapshots.map((snapshot) => Object.values(snapshot.val())[0])
                setPlayers(playerData)
            })
            projectDatabase.ref(`tourney/tournaments/${matchData.tourney}`).once('value').then(snapshot => {
                console.log(snapshot.val())
                setTourney(snapshot.val())
            })
        });
    }, [projectDatabase]);
    const scores = match ? getScore(match) : {}
    if (match?.commentators) {
        console.log(players.filter(p => Object.values(match?.commentators).includes(p.discordID)))
    }
    return (<Box width='100vw' height='100vh' m={0} p={0}>

        <img src='/stream/stars.png'
            style={{
                width: '100%',
                height: '100%',
                position: 'fixed',
                objectFit: 'fill',
                zIndex: 0
            }}
        />
        <img src='/stream/logo.png'
            style={{
                width: '45%',
                position: 'fixed',
                zIndex: 0,
                top: '9%',
                left: '27.5%',
            }}
        />
        <img src='/stream/holder.png'
            style={{
                width: '45%',
                position: 'fixed',
                zIndex: 0,
                top: '2%',
                left: '0%',
            }}
        />
        <img src='/stream/holder.png'
            style={{
                width: '45%',
                position: 'fixed',
                zIndex: 0,
                top: '2%',
                left: '55%',
            }}
        />
        <Box height='100%' width='100%' style={{ position: 'fixed', opacity: '30%', zIndex: 1 }} sx={{ background: `linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, ${color}  20%, ${color}  65%, rgba(255, 0, 0, 0) 100%)` }}></Box>
        <Box height='18%' />
        <Grid style={{ position: 'fixed', zIndex: 2, padding: 4 }} container spacing={1} alignItems='center' justifyContent='center' >
            {players.length && players.filter(p => Object.values(match.players).includes(p.discordID)).map((player) => {
                return <Grid item xs={6}>
                    <Stack spacing={0} alignItems='center' justifyContent='center'>
                        <AspectRatioBox sx={{ bgcolor: '#000000', width: '100%', border: `4px ridge ${color}` }}>
                        </AspectRatioBox>
                        <Stack justifyContent='center' alignItems='center' direction='row' spacing={2}>
                            <Typography sx={{ fontFamily: 'Auraboo', fontSize: '4vw' }} style={{ zIndex: 2, opacity: '7%', position: 'absolute' }}>
                                {player?.sgName}
                            </Typography>
                            {player?.avatar && <Avatar src={player?.avatar} style={{ zIndex: 2, borderRadius: '20%', width: '10%', height: '10%' }} />}
                            <Typography
                                sx={{ fontFamily: 'atrament-web', fontWeight: 700, fontStyle: 'italic', fontSize: '4vw', textDecoration: 'none', textShadow: `2px 5px 0px ${color}` }}
                                style={{ zIndex: 2 }}>{player?.sgName}</Typography>
                        </Stack>
                        <img src='/stream/holder.png'
                            style={{
                                width: '45%',
                                position: 'absolute',
                                zIndex: 0,
                                transform: 'translate(0%, 220%)'
                            }}
                        />

                        <Typography sx={{ fontFamily: 'impact', fontStyle: 'italic', fontSize: '4vw', lineHeight: '1' }} style={{ zIndex: 2 }}>
                            {scores[player?.discordID]}
                        </Typography>
                    </Stack>
                </Grid>
            })}
        </Grid>
        <Stack width='100%' height='15%' style={{ position: 'fixed', bottom: '5%' }} justifyContent='center' alignItems='center' spacing={0}>
            <Typography sx={{ fontFamily: 'atrament-web', fontWeight: 400, fontStyle: 'italic', fontSize: '3vw' }} style={{ zIndex: 4, opacity: '50%' }}>
                {tourney?.name?.toUpperCase()}
            </Typography>
            <Typography sx={{ fontFamily: 'atrament-web', fontWeight: 200, fontStyle: 'italic', fontSize: '3.5vw' }} style={{ zIndex: 4 }}>
                {((tourney?.stages?.[match.bracket]?.bracket ?? "") + " " + (tourney?.stages?.[match.bracket]?.round ?? "")).toUpperCase()}
            </Typography>
        </Stack>
        <Box height='8%' width='33%' style={{ position: 'fixed', opacity: '50%', zIndex: 1, transform: 'skewX(-30deg)', left: '3%', bottom: '3%' }} sx={{ background: `linear-gradient(to bottom, rgba(255, 0, 0, 0) -50%, ${color}  100%)`, border: `4px ridge ${color}` }} />
        <Stack style={{ position: 'fixed', zIndex: 2, left: '4%', bottom: '4.5%' }} direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
            <MicIcon fontSize='large' />
            {players.length && match?.commentators && players.filter(p => Object.values(match?.commentators).includes(p.discordID)).map((player) => {
                return <Stack justifyContent='flex-start' alignItems='center' direction='row' spacing={1}>
                    <Typography
                        sx={{ fontFamily: 'atrament-web', fontWeight: 400, fontStyle: 'italic', fontSize: '3vw', textDecoration: 'none' }}
                        style={{ zIndex: 4 }}>{player?.sgName}</Typography>
                </Stack>
            })}
        </Stack>
    </Box >)
}