import { Grid, Typography, Divider, Stack } from '@mui/material';
import MatchCard from './MatchCard';
import { useMatches } from '../../../hooks/useMatches';
import { useRulesets } from '../../../hooks/useRulesets';
import { useRef, useState } from 'react';

const Bracket = ({ tourney, bracket }) => {
    const containerRef = useRef(null);
    const [dragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const handleMouseDown = (event) => {
        setDragging(true);
        setStartX(event.pageX - containerRef?.current?.offsetLeft);
        setScrollLeft(containerRef?.current?.scrollLeft);
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleMouseMove = (event) => {
        if (!dragging) return;
        const x = event.pageX - containerRef?.current?.offsetLeft;
        const walk = (x - startX) * 1; // Adjust the scroll speed if needed
        containerRef.current.scrollLeft = scrollLeft - walk;
    };
    const matches = useMatches()
    const rulesets = useRulesets()
    const bracketmatches = matches.filter(m => m.tourney == tourney?.id && tourney?.stages[m.bracket]?.bracket == bracket)
    const rounds = tourney ? [...new Set(Object.values(tourney?.stages).filter(s => s.bracket == bracket).map(s => { return { order: s.round_order, name: s.round, ruleset: s.ruleset } }))].sort((a, b) => a.round_order - b.round_order) : []
    return (
        <Stack
            ref={containerRef}
            direction="row"
            divider={<Divider orientation="vertical" />}
            sx={{
                overflowX: "hidden",
                padding: 3,
                minHeight: '100%',
                WebkitOverflowScrolling: 'touch',
                cursor: dragging ? 'grabbing' : 'grab',
            }}
            onMouseDown={handleMouseDown}
            onMouseLeave = {handleMouseUp}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            spacing={2}>
            {rounds.map(r =>
                <Stack alignItems='center'>
                    <Typography variant='caption'>{r.name.toUpperCase()}</Typography>
                    <Typography color='text.secondary' mb={1} variant='caption'>{rulesets.find(s => s.id == r.ruleset)?.general?.name ?? 'no ruleset'}</Typography>
                    <Stack alignItems='center' justifyContent='center' width={rounds.length == 1 ? null : 200} minHeight='95%'>
                        <Grid justifyContent="center"
                            spacing={2}
                            container
                            direction={rounds.length == 1 ? "" : "column"}>
                            {bracketmatches.filter(m => tourney.stages[m.bracket].round_order === r.order).map(m => <Grid item xs="auto" >
                                <MatchCard match={m} tourney={tourney} verbose={false} />
                            </Grid>)}
                        </Grid>
                    </Stack>
                </Stack>)}

        </Stack>
    );
};

export default Bracket