import { useParams } from 'react-router-dom'
import { useCollection } from '../../hooks/useCollection';
import { Link } from 'react-router-dom'
import { Button } from '@mui/material';
import { Typography, Grid, Card, Divider, Box, Chip, Stack, Avatar, CardContent, IconButton, Container, Tabs, Tab, Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { planets, racers, tracks } from '../../data.js'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { getRanks } from '../tournaments/ranking/getRanks';
import StarsIcon from '@mui/icons-material/Stars';
import { useAuthContext } from '../../hooks/useAuthContext.js';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import { ordinalSuffix, getCountry, timefix, timestring, numberWithCommas } from '../../tools.js'
import DoneIcon from '@mui/icons-material/Done';
import CountrySelect from './CountrySelect';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import RacerName from '../../components/RacerName';
import TrackName from '../../components/TrackName';
import TimeSheetTab from './TimeSheetTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitch } from '@fortawesome/free-brands-svg-icons'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { faMicrophoneLines } from '@fortawesome/free-solid-svg-icons'
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons'
import { useMatches } from '../../hooks/useMatches';
import { useTournaments } from '../../hooks/useTournaments';
import { useUsers } from '../../hooks/useUsers';
import { useRulesets } from '../../hooks/useRulesets';
import { useSpeedruns } from '../../hooks/useSpeedruns';
import { useUser } from '../../hooks/useUser';
import UserName from '../../tools/UserName';
import { projectDatabase } from '../../firebase/config';
import { useRoles } from '../../hooks/useRoles';

let previous = [], next = []
let recent = []
let finishes = []
let recentmatches = []
let recentruns = []
let recentrchallenges = []
let recentcchallenges = []
let completedchallenges = []

let most = {
    racers: {},
    tracks: {}
}

let profilekey = null

let speedrunstats = {
    tracks: {}
}
export default function Player() {
    const { id } = useParams()
    const matches = useMatches()
    const rulesets = useRulesets()
    const tourneys = useTournaments()
    const { user, user_ref } = useUser({ user_id: id })
    const speedruns = useSpeedruns()
    const [profile, setProfile] = useState(null)
    const userref = projectDatabase.ref(`users/${id}`)
    const { user: logged_in_user } = useAuthContext()
    const roles = useRoles(logged_in_user?.id)

    useEffect(() => {
        const fetchData = async () => {
            userref.on('value', (snapshot) => {
                const data = snapshot.val();
                setProfile(data)
            }
            )
        }
        if (id) {
            fetchData()
        }
    }, [id])

    let TimeSheet = {}
    let Career = { start: null, end: null }
    let career = { start: null, end: null }

    const [CurrentTab, setCurrentTab] = useState(0) //tabs use the index of the tab
    const [Editing, setEditing] = useState(false)
    const [Country, setCountry] = useState('')
    const [Conditions, setConditions] = useState(
        {
            trak: ['ft'],
            skip: ['fs', 'ai', 'bg'],
            time: ['tt'],
            upgr: ['mu'],
            dire: ['fw'],
            laps: ['l3']
        }
    )

    let random = null
    let challengeruns = null
    let challenges = null
    let rank = null
    let player = null
    let ranks = null
    let ranklist = []

    let timesheet = {}
    previous = []
    next = []
    recent = []
    finishes = []
    recentmatches = []
    recentruns = []
    recentrchallenges = []
    recentcchallenges = []
    completedchallenges = []

    speedrunstats = {
        ttft: {},
        flft: {},
        ttsk: {},
        flsk: {}
    }
    most = {
        racers: {},
        tracks: {}
    }

    for (let i = 0; i < 25; i++) {
        most.racers[i] = {
            count: 0,
            playtime: 0,
            index: i
        }
        most.tracks[i] = {
            count: 0,
            playtime: 0,
            index: i
        }
        timesheet[i] = {
            time: null,
            racer: null,
            source: null,
            cats: [],
            values: []
        }
    }

    //get speedrun stats
    if (speedruns) {
        Object.keys(speedruns).forEach(key => {
            let run = speedruns[key]
            if (run.user == id || (run.src?.players?.data?.[0]?.id == profile?.src?.id && ![run.src?.players?.data?.[0]?.id, profile?.src?.id].includes(undefined))) {
                if (run.date) {
                    adjustCareer(moment(run.date).valueOf())
                } else if (run.src?.date) {
                    adjustCareer(moment(run.src.date).valueOf())
                }
                if (![null, undefined, ""].includes(getRacer(run))) {
                    most.racers[getRacer(run)].count++
                    most.racers[getRacer(run)].playtime += Number(getTime(run))
                }
                if (![null, undefined, ""].includes(getTrack(run))) {
                    most.tracks[getTrack(run)].count++
                    most.tracks[getTrack(run)].playtime += Number(getTime(run))

                    //mirror
                    let conditions = getConditions(run)
                    if (sharedContent(Conditions.laps, conditions.laps) && sharedContent(Conditions.time, conditions.time) && sharedContent(Conditions.trak, conditions.trak) && sharedContent(Conditions.upgr, conditions.upgr) && sharedContent(Conditions.dire, conditions.dire) && sharedContent(Conditions.skip, conditions.skip)) {
                        console.log('added')
                        fillTimeSheet(
                            {
                                time: getTime(run),
                                racer: getRacer(run),
                                track: getTrack(run),
                                source: 0,
                                conditions: conditions,
                                link: key
                            }, timesheet
                        )
                    }

                    let ftconditions = {
                        game: ['fp'],
                        laps: ['l3'],
                        mirr: ['um'],
                        time: ['tt'],
                        trak: ['ft'],
                        skip: [],
                        upgr: ['mu'],
                        dire: ['fw']
                    }
                    if (sharedContent(ftconditions.laps, conditions.laps) && sharedContent(ftconditions.upgr, conditions.upgr) && sharedContent(ftconditions.dire, conditions.dire)) {
                        if (sharedContent(['ft'], conditions.trak)) {
                            if (sharedContent(['tt'], conditions.time)) {
                                speedrunstats.ttft[getTrack(run)] = "completed"
                            } else if (sharedContent(['fl'], conditions.time)) {
                                speedrunstats.flft[getTrack(run)] = "completed"
                            }
                        } else if (sharedContent(['sk'], conditions.trak)) {
                            if (sharedContent(['tt'], conditions.time)) {
                                speedrunstats.ttsk[getTrack(run)] = "completed"
                            } else if (sharedContent(['fl'], conditions.time)) {
                                speedrunstats.flsk[getTrack(run)] = "completed"
                            }
                        }
                    }
                }
                recentruns.push(
                    {
                        date: moment((run.date ?? run.src.date)).valueOf(),
                        link: '',
                        cattrack: (getTrack(run) !== "" ? tracks[getTrack(run)].name + " - " : "") + getCategory(run),
                        racer: getRacer(run),
                        time: timefix(getTime(run))
                    }
                )
                recent.push(
                    {
                        date: moment((run.date ?? run.src.date)).valueOf(),
                        link: '',
                        desc: 'Submitted ' + getCategory(run) + " " + getTrack(run) + " " + getRacer(run) + " " + timefix(getTime(run))
                    }
                )
            }
        })

    }

    //get tourney stats
    if (matches && tourneys && rulesets) {
        Object.keys(matches).forEach(key => {
            let match = matches[key]
            let players = match.players ? match.players : match.races ? Object.values(match.races[0].runs).map(run => run.player) : []
            if (match.commentators && Object.values(match.commentators).includes(profile?.discordID)) {
                recent.push(
                    {
                        date: match.datetime,
                        link: '/tournaments/matches/' + match.key,
                        desc: 'Commentated ' + (players.length > 3 ? "" : Object.values(players).map(p => p).join(" vs ") + " - ") + tourneys[match.tourney]?.nickname + " - " + tourneys[match.tourney]?.stages[match.bracket].bracket
                    }
                )
            }
            if (Object.values(players).includes(profile?.discordID)) {
                adjustCareer(match.datetime)
                match.key = key
                recentmatches.push(match)
                if (!match.forfeit) {
                    recent.push(
                        {
                            date: match.datetime,
                            link: '/tournaments/matches/' + match.key,
                            desc: 'Played ' + (players?.length > 3 ? (players?.length - 1) + " Players" : Object.values(players)?.filter(p => p !== profile?.discordID).map(p => p).join(", ") + " in ") + tourneys[match.tourney]?.nickname + " - " + tourneys[match.tourney]?.stages[match.bracket]?.bracket
                        }
                    )
                }
                if (match.races) {
                    Object.values(match.races).forEach((race, index) => {
                        let thistrack = null
                        let conditions = {
                            game: ['fp'],
                            laps: ['l3'],
                            mirr: ['um'],
                            time: ['tt'],
                            trak: ['ft'],
                            skip: [],
                            upgr: ['mu'],
                            dire: ['fw']
                        }
                        if (rulesets[match.ruleset]?.playlist) {
                            thistrack = rulesets[match.ruleset]?.playlist[index].track
                            let trackconditions = { ...rulesets[match.ruleset]?.playlist[index].conditions }
                            Object.keys(conditions).forEach(con => {
                                if (!Object.keys(trackconditions).includes(con)) {
                                    trackconditions[con] = conditions[con]
                                }
                            })
                            Object.values(trackconditions).forEach(con => {
                                con = [con].flat()
                            })
                            conditions = { ...trackconditions }
                        } else {
                            if (race.events) {
                                Object.values(race.events).forEach(event => {
                                    if (event.event == 'selection' && event.type == 'track') {
                                        thistrack = event.selection
                                    }
                                    if (event.event == 'override' && event.type == 'condition') {
                                        if (event.selection == 'fl') {
                                            conditions.time = ['fl']
                                        }
                                        if (event.selection == 'sk') {
                                            conditions.trak = ['sk']
                                        }
                                        if (event.selection == 'nu') {
                                            conditions.upgr = ['nu']
                                        }
                                    }
                                })
                            }
                        }

                        if (race.runs) {
                            Object.values(race.runs).forEach(run => {
                                if (run.player == profile?.discordID) {
                                    if (run.retroskip) {
                                        conditions.trak = ['sk']
                                    }
                                    if (sharedContent(Conditions.laps, conditions.laps) && sharedContent(Conditions.time, conditions.time) && sharedContent(Conditions.trak, conditions.trak) && sharedContent(Conditions.upgr, conditions.upgr) && sharedContent(Conditions.dire, conditions.dire) && sharedContent(Conditions.skip, conditions.skip)) {
                                        fillTimeSheet(
                                            {
                                                time: run?.time,
                                                track: thistrack,
                                                racer: run?.pod,
                                                source: 1,
                                                conditions: conditions,
                                                link: match.key
                                            }, timesheet
                                        )
                                    }
                                    if (![null, undefined, ""].includes(run.pod)) {
                                        most.racers[run.pod].count++
                                        if (run.time !== 'DNF') {
                                            most.racers[run.pod].playtime += Number(run.time)
                                        }
                                    }
                                    if (thistrack) {
                                        most.tracks[thistrack].count++
                                        if (run.time !== 'DNF') {
                                            most.tracks[thistrack].playtime += Number(run.time)
                                        }
                                    }
                                }
                            })
                        }
                    })
                }
            }
        })
        Object.keys(tourneys).forEach(key => {
            let tourney = tourneys[key]
            if (tourney.standings) {
                Object.values(tourney.standings).forEach((players, place) => {
                    if (players.includes(profile?.discordID)) {
                        let final = Object.values(matches).filter(m => m.tourney == key && (m.players ? m.players : Object.values(m.races[0].runs).map(r => r.player)).includes(profile?.discordID)).sort((a, b) => b.datetime - a.datetime)[0]
                        finishes.push(
                            {
                                finish: "Finished " + ordinalSuffix(place) + " in " + tourney?.stages[final?.bracket]?.bracket + " " + tourney?.stages[final?.bracket]?.round,
                                place: place,
                                tourn: tourney.name,
                                link: key,
                                players: Object.values(tourney.standings).flat().length,
                                date: tourney.startdate
                            }
                        )
                    }
                })
            }
        })
    }

    //get custom challenge stats
    if (challengeruns && challenges) {
        console.log('getting challenge stuff')
        Object.keys(challengeruns).forEach(key => {
            let run = challengeruns[key]
            if (run.user == profile?.discordID) {
                let conditions = {
                    game: ['fp'],
                    laps: ['l3'],
                    mirr: ['um'],
                    time: ['tt'],
                    trak: ['ft'],
                    skip: [],
                    upgr: ['mu'],
                    dire: ['fw']
                }

                adjustCareer(run.timestamp)
                let racer = run.racer
                if (!racer) {
                    racer = challenges[run.challengeid].racer
                }
                if (!completedchallenges.includes(run.challengeid)) {
                    completedchallenges.push(run.challengeid)
                }
                if (!challenges[run.challengeid].multi && !String(challenges[run.challengeid]?.track).includes("[")) {
                    most.racers[racer].count++
                    most.racers[racer].playtime += Number(run.time)
                    if (most.tracks[challenges[run.challengeid]?.track]) {
                        most.tracks[challenges[run.challengeid]?.track].count++
                        most.tracks[challenges[run.challengeid]?.track].playtime += Number(run.time)
                    }
                    if (run.time) {
                        if (sharedContent(Conditions.laps, conditions.laps) && sharedContent(Conditions.time, conditions.time) && sharedContent(Conditions.trak, conditions.trak) && sharedContent(Conditions.upgr, conditions.upgr) && sharedContent(Conditions.dire, conditions.dire) && sharedContent(Conditions.skip, conditions.skip)) {
                            fillTimeSheet(
                                {
                                    time: run.time,
                                    racer: racer,
                                    track: challenges[run.challengeid]?.track,
                                    source: 2,
                                    conditions: conditions,
                                    link: key
                                }, timesheet
                            )
                        }
                    }
                }
                recentcchallenges.push(
                    {
                        date: run.timestamp,
                        challenge: challenges[run.challengeid].name,
                        time: timefix(run.time)
                    }
                )
                recent.push(
                    {
                        date: run.timestamp,
                        link: run.proof,
                        desc: 'Completed the "' + challenges[run.challengeid].name + '" Challenge'
                    }
                )
            }
        })
    }

    //get random challenge stats
    if (random && profile?.random) {
        console.log('getting random stuff')
        Object.keys(random).forEach(key => {
            let r = random[key]
            if (r.user == profile?.discordID) {
                let conditions = {
                    game: ['fp'],
                    laps: ['l3'],
                    mirr: ['um'],
                    time: ['tt'],
                    trak: ['ft'],
                    skip: [],
                    upgr: ['mu'],
                    dire: ['fw']
                }
                adjustCareer(r.date)
                most.racers[r.racer].count++
                most.racers[r.racer].playtime += Number(r.time)
                most.tracks[r.track].count++
                most.tracks[r.track].playtime += Number(r.time)
                if (r.nu) {
                    conditions.upgr = ['nu']
                }
                if (r.skips) {
                    conditions.trak = ['sk']
                    conditions.skip = ['fs']
                }
                conditions.laps = ['l' + r.laps]
                if (sharedContent(Conditions.laps, conditions.laps) && sharedContent(Conditions.time, conditions.time) && sharedContent(Conditions.trak, conditions.trak) && sharedContent(Conditions.upgr, conditions.upgr) && sharedContent(Conditions.dire, conditions.dire) && sharedContent(Conditions.skip, conditions.skip)) {
                    fillTimeSheet(
                        {
                            time: r.time,
                            racer: r.racer,
                            track: r.track,
                            source: 3,
                            conditions: conditions,
                            link: key
                        }, timesheet
                    )
                }
                let challengestring = "Race as " + racers[r.racer].name + (r.nu ? " with NO UPGRADES " : "") + " on " + tracks[r.track].name + (r.laps !== 3 ? " for " + r.laps + " LAPS" : "") + (r.skips ? " with SKIPS" : "") + (r.mirror ? ", MIRRORED!" : "")
                recent.push(
                    {
                        date: r.date,
                        link: "",
                        desc: challengestring.replace('Race', 'Raced')
                    }
                )
                recentrchallenges.push(
                    {
                        date: r.date,
                        link: "",
                        desc: challengestring,
                        time: timefix(r.time)
                    }
                )
            }
        })
    }

    if (profile?.discord?.joinedTimestamp) {
        adjustCareer(profile?.discord.joinedTimestamp)
    }

    recentruns = recentruns.sort((a, b) => b.date - a.date).slice(0, 5)
    recentmatches = recentmatches.sort((a, b) => b.datetime - a.datetime).slice(0, 5)
    recentrchallenges = recentrchallenges.sort((a, b) => b.date - a.date).slice(0, 5)
    recentcchallenges = recentcchallenges.sort((a, b) => b.date - a.date).slice(0, 5)
    finishes = finishes.sort((a, b) => b.date - a.date).slice(0, 5)
    recent = recent.sort((a, b) => b.date - a.date).slice(0, 5)


    const changeCountry = (newCountry) => {
        profile.country = newCountry
        user_ref.child(profilekey).update(profile)
    }

    let name = null
    let names = null

    const placementcolors = [{ fg: 'FFCE21', bg: '#1021A5', gstart: '#2931DE', gend: '#040790' }, { fg: 'FFFFFF', bg: '#DE0000', gstart: '#FF1000', gend: '#800101' }, { fg: '#BC2F00', bg: '#E9D7CE', gstart: '#F3E0D9', gend: '#BAA19D' }]
    const catcolors = ['#F2DE5C', '#35F095', '#7765F0', '#F04B35']

    function adjustCareer(date) {
        if (career.start === null || date < career.start) {
            career.start = date
        }
        if (career.end === null || date > career.end) {
            career.end = date
        }
    }
    function fillTimeSheet(run, timesheet) {
        if ((timesheet[run.track]?.time === null || run?.time - timesheet[run.track]?.time < 0) && run?.time !== 0) {
            timesheet[run.track].time = run.time
            timesheet[run.track].racer = run.racer
            timesheet[run.track].source = run.source
            timesheet[run.track].conditions = run.conditions
            timesheet[run.track].link = run.link
        }
    }
    function joinPronouns(pronouns) {
        if (Array.isArray(pronouns)) {
            if (pronouns.length > 1) {
                return pronouns.map(p => p.split("/")[0]).join("/")
            } else {
                return pronouns[0]
            }
        } else {
            return pronouns
        }


    }
    function getName(user) {
        let name = 'no name'
        if (!user) {
            return name
        }
        if (user.name) {
            return user.name
        } else if (user.src?.names?.international) {
            return user.src.names.international
        } else if (user.src?.name) {
            return user.src.name
        } else if (user.cs?.name) {
            return user.cs.name
        } else if (user.cs?.nickname) {
            return user.cs.nickname
        } else if (user.alt?.name) {
            return user.alt.name
        } else if (user.alt?.nickname) {
            return user.alt.nickname
        } else {
            return name
        }
    }

    const changeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const CustomColor = styled(Typography)(({ theme }) => ({
        fontWeight: 1000,
        fontSize: 40,
        background: profile?.src?.['name-style'] ? "-webkit-linear-gradient(0deg," + profile?.src['name-style']['color-from'].dark + ", " + profile?.src['name-style']['color-to'].dark + ")" : "",
        WebkitBackgroundClip: profile?.src?.['name-style'] ? "text" : "",
        WebkitTextFillColor: profile?.src?.['name-style'] ? "transparent" : ""
    }));

    function getRacer(run) {
        let r = run.racer ?? run.src?.values?.Character ?? null
        let thisracer = ""
        if (![null, undefined, ""].includes(r)) {
            racers.forEach((racer, index) => {
                if (Number(r) == index || r.toLowerCase().trim().replaceAll("'", "") == racer.name.toLowerCase().trim().replaceAll("'", "")) {
                    thisracer = index
                }
            })
        }
        return thisracer
    }
    function getTrack(run) {
        let t = run.track ?? run.src?.level ?? null
        let thistrack = ""
        if (![null, undefined, ""].includes(t)) {
            tracks.forEach((track, index) => {
                if (Number(t) == index || t.toLowerCase().trim().replaceAll("'", "") == track.name.toLowerCase().trim().replaceAll("'", "")) {
                    thistrack = index
                }
            })
        }
        return thistrack
    }
    function getTime(run) {
        return run.time ?? run.src?.times?.primary_t ?? null
    }
    function getCategory(run) {
        return run.src?.category ?? run.cat
    }
    function getConditions(run) {
        let conditions = {
            game: ['fp'],
            laps: ['l3'],
            mirr: ['um'],
            time: ['tt'],
            trak: ['ft'],
            skip: [],
            upgr: ['mu'],
            dire: ['fw']
        }
        let cat = (run.src?.category ?? run.cat ?? "").toLowerCase()
        if (cat.includes("1")) {
            conditions.time = ["fl"]
        }
        if (cat.includes('first')) {
            conditions.laps = ['l1']
        }
        if (cat.includes("5")) {
            conditions.laps = ["l5"]
        }
        if (cat.includes("backward")) {
            conditions.dire = ['bw']
        }
        let skips = run.src?.values?.Skips ?? ""
        skips = skips.toLowerCase()
        if (skips.includes('skips')) {
            if (skips.includes('no')) {
                conditions.trak = ['ft']
            } else {
                conditions.trak = ['sk']
            }
        }

        if (skips.includes('mfg') && !conditions.skip.includes('bg')) {
            conditions.skip.push('bg')
            conditions.trak = ['sk']
        }
        if (skips.includes('ai') && !conditions.skip.includes('ai')) {
            conditions.skip.push('ai')
            conditions.trak = ['sk']
        }
        if (run.src?.values?.Upgrades === "No Upgrades") {
            conditions.upgr = ['nu']
        }
        if (run.upgrades === false) {
            conditions.upgr = ['nu']
        }
        if (run.skips === true) {
            conditions.trak = ['sk']
        }
        return conditions
    }
    function sharedContent(array1, array2) {
        let filter = false
        if (array1.length && Array.isArray(array1)) {
            array1.forEach(a => {
                if (array2.includes(a)) {
                    filter = true
                }
            })
        }
        if (array2.length == 0) {
            filter = true
        }
        return filter
    }
    let stats = {}

    name = getName(user)
    names = [profile?.name, profile?.src?.name, profile?.cs?.name, profile?.cs?.nickname, profile?.alt?.name, profile?.alt?.nickname, profile?.src?.names?.international, profile?.discord?.displayName, profile?.discord?.tag, profile?.src?.twitter?.uri.replace("https://www.twitter.com/", ""), profile?.src?.twitch?.uri.replace("https://www.twitch.tv/", "")].filter(n => ![null, undefined, ""].includes(n)) //profile?.src?.youtube?.uri.replace("https://www.youtube.com/user/", "") youtube link ugly
    names = [...new Set(names)]
    ranks = getRanks(Object.values(matches), Date.now(), rulesets)

    ranklist = Object.keys(ranks).map(key => { return { player: key, rank: ranks[key].rank, change: ranks[key].change, matches: ranks[key].matches, stats: stats[key] } }).filter(r => r.matches >= 4).sort(function (a, b) {
        return b.rank - a.rank
    })

    ranklist.some((row, index) => (
        row.player == profile?.discordID ? (rank = index + 1, player = row) : null
    ))


    return (
        <Box sx={{ background: 'linear-gradient(135deg,' + profile?.src?.['name-style']?.['color-from']?.dark + ' -200%, rgba(255, 0, 0, 0) 50%,' + profile?.src?.['name-style']?.['color-to']?.dark + "  200%)" }}>
            <Container sx={{ minHeight: '100vh' }}>
                <Grid container direction='row' justifyContent='space-between' alignItems='center' pt={2}>
                    <Grid item xs={'auto'} sx={{ overflowX: 'clip', maxWidth: '100%' }} >
                        <Stack direction='row' sx={{ overflowX: 'clip' }}>
                            <UserName user_id={id} size={'50px'} avatar={true} sx={{ overflowX: 'clip' }} />
                            {user && (roles.includes('ADMIN') || profile.discordID == logged_in_user.id) && <IconButton onClick={() => setEditing(!Editing)}>{(Editing ? <DoneIcon /> : <EditIcon />)}</IconButton>}
                        </Stack>
                        <Stack alignItems='flex-start'>
                            <Stack color='text.secondary' direction='row' alignItems='center' spacing={1}>
                                {Editing && <CountrySelect Country={Country} changeCountry={changeCountry} />}
                                {(profile?.country || profile?.src?.country) && !Editing &&
                                    <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
                                        <Avatar src={`https://flagcdn.com/w80/${(profile?.country ?? profile?.src.country).toLowerCase()}.png`} variant='square' sx={{ width: '30px', height: '20px' }} />
                                        <Typography>
                                            {getCountry(profile?.country ?? profile?.src.country)}
                                        </Typography>
                                    </Stack>}
                                {(profile?.pronouns || profile?.src?.pronouns) && profile?.country && <Divider orientation="vertical" flexItem />}
                                {Editing && <FormControl sx={{ m: 1, minWidth: 240 }}>
                                    <InputLabel id='bracketselect'>Pronouns</InputLabel>
                                    <Select
                                        labelId="bracket"
                                        id="demo-simple-select-helper"
                                        value={[profile?.src?.pronouns?.split(", ")].flat() ?? profile?.pronouns ?? []}
                                        variant="standard"
                                        multiple
                                        label='Platform'
                                        onChange={(e) => {
                                            profile.pronouns = e.target.value
                                            user_ref.child(profilekey).update(profile)
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>-</em>
                                        </MenuItem>
                                        {['He/Him', 'She/Her', 'They/Them'].map((s) => <MenuItem value={s}>{s}</MenuItem>)}
                                    </Select>
                                </FormControl>}
                                {(profile?.src?.pronouns || profile?.pronouns) && !Editing && <Typography>
                                    {joinPronouns(profile?.src?.pronouns ? profile?.src.pronouns.split(", ") : (profile?.pronouns ?? []))}
                                </Typography>}
                            </Stack>
                            {false && <Stack direction='row' spacing={1}>
                                {names.map(name => {
                                    return <Chip label={name} variant='outlined' />
                                })}
                            </Stack>}

                        </Stack>
                    </Grid>
                    <Grid item spacing={1} alignItems='center' sx={'auto'} justifyContent='flex-end'>
                        <Stack alignItems='flex-end'>
                            <Typography variant='button' color='text.secondary'>{new Date(Career.start).toLocaleString().split(",")[0] + " - " + (Career.end < (Date.now() - 1000 * 60 * 60 * 24 * 30) ? new Date(Career.end).toLocaleString().split(",")[0] : "PRESENT")}</Typography>
                            {Editing && <Stack alignItems='flex-end'>
                                <FormControl sx={{ m: 1, minWidth: 240 }}>
                                    <InputLabel id='bracketselect'>Platform</InputLabel>
                                    <Select
                                        labelId="bracket"
                                        id="demo-simple-select-helper"
                                        value={profile?.platform}
                                        variant="standard"
                                        label='Platform'
                                        onChange={(e) => {
                                            profile.platform = e.target.value
                                            user_ref.child(profilekey).update(profile)
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>-</em>
                                        </MenuItem>
                                        {['PC', 'Dreamcast', 'Nintendo 64', 'PlayStation', 'Xbox', 'Switch'].map((s) => <MenuItem value={s}>{s}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, minWidth: 240 }}>
                                    <InputLabel id='bracketselect'>Input Method</InputLabel>
                                    <Select
                                        labelId="bracket"
                                        id="demo-simple-select-helper"
                                        value={profile?.input}
                                        variant="standard"
                                        label='Platform'
                                        onChange={(e) => {
                                            profile.input = e.target.value
                                            user_ref.child(profilekey).update(profile)
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>-</em>
                                        </MenuItem>
                                        {['Keyboard', 'Controller', 'N64 Controller', 'Switch Controller', 'Xbox Controller', 'PlayStation Controller', 'GameCube Controller'].map((s) => <MenuItem value={s}>{s}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Stack>
                            }
                            {!Editing && profile?.platform && <Typography variant='caption' >{[profile?.platform, profile?.input].filter(p => ![null, undefined, ''].includes(p)).join(" | ")}</Typography>}
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container direction='row-reverse' justifyContent='space-between' alignItems='center'>
                    <Grid item xs={12} md={'auto'}>
                        <Stack direction='row' spacing={1} alignItems='center'>
                            {profile?.src?.youtube && <IconButton target="_blank" href={profile?.src.youtube.uri} color="inherit" sx={{ "&:hover": { color: "#FF0000", transition: "all 0.15s ease-in-out" } }}><YouTubeIcon /></IconButton>}
                            {profile?.src?.twitter && <IconButton target="_blank" href={profile?.src.twitter.uri} color="inherit" sx={{ "&:hover": { color: "#1DA1F2", transition: "all 0.15s ease-in-out" } }}><TwitterIcon /></IconButton>}
                            {profile?.src?.twitch && <IconButton target="_blank" href={profile?.src.twitch.uri} color="inherit" sx={{ "&:hover": { color: "#6441a5", transition: "all 0.15s ease-in-out" } }}><FontAwesomeIcon icon={faTwitch} /></IconButton>}
                            {profile?.src?.user && <IconButton target="_blank" href={profile?.src.user} color="inherit" sx={{ "&:hover": { color: "#FAD55A", transition: "all 0.15s ease-in-out" } }}><EmojiEventsIcon /></IconButton>}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <Tabs value={CurrentTab}
                            onChange={changeTab}
                            sx={{ fontSize: '12px' }}
                            variant="scrollable">
                            <Tab label='Overview' />
                            <Tab label='Time Sheet' />
                            <Tab label='Speedruns' />
                            <Tab label='Tournaments' />
                            <Tab label='Challenges' />
                        </Tabs>
                    </Grid>

                </Grid>

                {CurrentTab == 0 && <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card sx={{ borderLeft: 1 }} >
                                    <Box sx={{ background: "linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, #FFFFFF  1000%)" }}>
                                        <CardContent>
                                            <Stack>
                                                <Typography sx={{ fontSize: 14 }} variant='button' color="text.secondary" gutterBottom>
                                                    BIO
                                                </Typography>
                                                {Editing ? <TextField
                                                    id="outlined-multiline-static"
                                                    multiline
                                                    rows={4}
                                                    value={profile?.bio}
                                                    onChange={(e) => {
                                                        profile.bio = e.target.value
                                                        user_ref.child(profilekey).update(profile)
                                                    }}
                                                /> :
                                                    <Typography >
                                                        {profile?.bio ?? "Just who is this mysterious podracer?"}
                                                    </Typography>}
                                            </Stack>
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Grid>
                            {recentruns.length > 0 && <Grid item xs={12}>
                                <Card sx={{ width: '100%', borderLeft: 1, borderColor: '#F2DE5C' }} >
                                    <Box sx={{ background: 'linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, ' + catcolors[0] + " 1000%)" }}>
                                        <CardContent>
                                            <Typography sx={{ fontSize: 14 }} variant='button' color="text.secondary" gutterBottom>
                                                SPEEDRUNS
                                            </Typography>
                                            {speedrunstats && <Stack direction='row' spacing={5} alignItems='center' justifyContent='center'>
                                                <Stack spacing={0.5} alignItems='center' justifyContent='center'>
                                                    <Typography sx={{ fontWeight: 1000, fontSize: '24px' }}>{Object.values(speedrunstats.ttft).length + " / 25"}</Typography>
                                                    <Typography variant='caption' color='text.secondary' >3-LAP FT</Typography>
                                                </Stack>
                                                <Stack spacing={0.5} alignItems='center' justifyContent='center'>
                                                    <Typography sx={{ fontWeight: 1000, fontSize: '24px' }}>{Object.values(speedrunstats.flft).length + " / 25"}</Typography>
                                                    <Typography variant='caption' color='text.secondary' >FLAP FT</Typography>
                                                </Stack>
                                                <Stack spacing={0.5} alignItems='center' justifyContent='center'>
                                                    <Typography sx={{ fontWeight: 1000, fontSize: '24px' }}>{Object.values(speedrunstats.ttsk).length + " / 20"}</Typography>
                                                    <Typography variant='caption' color='text.secondary' >3-LAP SKIPS</Typography>
                                                </Stack>
                                                <Stack spacing={0.5} alignItems='center' justifyContent='center'>
                                                    <Typography sx={{ fontWeight: 1000, fontSize: '24px' }}>{Object.values(speedrunstats.flsk).length + " / 20"}</Typography>
                                                    <Typography variant='caption' color='text.secondary' >FLAP SKIPS</Typography>
                                                </Stack>
                                            </Stack>}
                                            {recentruns.length !== 0 && <div><Typography sx={{ fontWeight: 1000 }} m={1}>Recent Submissions</Typography>
                                                <TableContainer component={Paper}>
                                                    <Table size="small" component={Paper} aria-label="simple table" elevation={7}>
                                                        <TableBody>
                                                            {recentruns.map(run => (
                                                                <TableRow
                                                                    hover
                                                                    to={run.link}
                                                                    component={Link}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'text-decoration': "none" }}
                                                                >
                                                                    <TableCell align="left">
                                                                        <Stack>
                                                                            <Typography variant='body2' color='text.secondary'>
                                                                                {new Date(run.date).toLocaleString().split(",")[0]}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <Stack>
                                                                            <Typography variant='body2' >
                                                                                {run.cattrack}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <RacerName racer={run.racer} />
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <Stack>{
                                                                            <Typography variant='body2' sx={{ fontWeight: 1000, fontFamily: 'verdana' }}>
                                                                                {run.time}
                                                                            </Typography>}
                                                                        </Stack>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer></div>}
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Grid>}
                            {(ranks?.[profile?.discordID] || recentmatches.length !== 0) && <Grid item xs={12}>
                                <Card sx={{ width: '100%', borderLeft: 1, borderColor: '#35F095' }}>
                                    <Box sx={{ background: 'linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, ' + catcolors[1] + "  1000%)" }}>
                                        <CardContent>
                                            <Typography sx={{ fontSize: 14 }} variant='button' color="text.secondary" gutterBottom>
                                                TOURNAMENTS
                                            </Typography>

                                            {ranks?.[profile?.discordID] && ranks?.[profile?.discordID].matches >= 4 && <Stack direction='row' spacing={5} alignItems='center' justifyContent='center'>
                                                <Stack spacing={0.5} alignItems='center' justifyContent='center'>
                                                    <Typography sx={{ fontWeight: 1000, fontSize: '24px' }}>{ordinalSuffix(rank - 1)}</Typography>
                                                    <Typography variant='caption' color='text.secondary' >RANK</Typography>
                                                </Stack>
                                                <Stack spacing={0.5} alignItems='center' justifyContent='center'>
                                                    <Stack direction='row' alignItems='center' justifyContent='center'>
                                                        <StarsIcon /><Typography sx={{ fontWeight: 1000, fontSize: '24px' }}>{player?.rank.toFixed(0)}</Typography>
                                                    </Stack>
                                                    <Typography variant='caption' color='text.secondary' >RATING</Typography>
                                                </Stack>
                                            </Stack>}
                                            {recentmatches.length !== 0 && <div><Typography sx={{ fontWeight: 1000 }} m={1}>Recent Matches</Typography>
                                                <TableContainer component={Paper}>
                                                    <Table size="small" component={Paper} aria-label="simple table" elevation={7}>
                                                        <TableBody>
                                                            {recentmatches.map(match => (
                                                                <TableRow
                                                                    hover
                                                                    to={`/tournaments/matches/${match.key}`}
                                                                    component={Link}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'text-decoration': "none" }}
                                                                >
                                                                    <TableCell align="left">
                                                                        <Stack>
                                                                            <Typography variant='body2' color='text.secondary'>
                                                                                {new Date(match?.datetime).toLocaleString().split(",")[0]}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <Stack>
                                                                            <Typography variant='body2' sx={{ fontWeight: 1000 }}>
                                                                                {tourneys[match.tourney]?.nickname + " - " + tourneys[match.tourney]?.stages[match.bracket].bracket + " " + tourneys[match.tourney]?.stages[match.bracket].round}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <Stack>{
                                                                            <Typography variant='body2'>
                                                                                {"vs " + (match.players ?
                                                                                    match.players.length > 4 ?
                                                                                        (match.players.length - 1) + " Players" :
                                                                                        Object.values(match.players).filter(p => p !== profile?.discordID).map(p => p).join(", ") :
                                                                                    Object.values(match.races[0].runs).map(r => r.player).filter(p => p !== profile?.discordID).map(p => p).join(", "))}
                                                                            </Typography>}
                                                                        </Stack>
                                                                    </TableCell>

                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer></div>}
                                            {finishes.length !== 0 && <div><Typography sx={{ fontWeight: 1000 }} m={1}>Recent Finishes</Typography>
                                                <TableContainer component={Paper}>
                                                    <Table size="small" component={Paper} aria-label="simple table" elevation={7}>
                                                        <TableBody>
                                                            {finishes.map(finish => (
                                                                <TableRow
                                                                    hover
                                                                    to={`/tournaments/tournaments/${finish.link}`}
                                                                    component={Link}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'text-decoration': "none", textShadow: finish.place < 3 ? '0px 1px 2px #000000' : "", background: finish.place < 3 ? 'linear-gradient(to right, rgba(255, 0, 0, 0) 0%, ' + placementcolors[finish.place].gstart + " 70%, " + placementcolors[finish.place].gend + " 100%)" : "" }}
                                                                >
                                                                    <TableCell align="left">
                                                                        <Typography variant='body2'>
                                                                            {finish.tourn}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <Typography variant='body2' color='text.secondary'>
                                                                            {finish.players + " Players"}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="left" >
                                                                        <Typography variant='body2' sx={{ fontWeight: 1000 }}>
                                                                            {finish.finish.split(" in ")[0]}
                                                                        </Typography>
                                                                        <Typography variant='body2' color='text.secondary'>
                                                                            {finish.finish.split(" in ")[1]}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {finish.place < 3 && <img src={`/placement flags/fill_${finish.place}.png`} width={33} height={45} />}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer></div>}
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Grid>}
                            {recentcchallenges.length > 0 && <Grid item xs={12}>
                                <Card sx={{ width: '100%', borderLeft: 1, borderColor: '#7765F0' }}>
                                    <Box sx={{ background: 'linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, ' + catcolors[2] + "  1000%)" }}>
                                        <CardContent>
                                            <Typography sx={{ fontSize: 14 }} variant='button' color="text.secondary" gutterBottom>
                                                CUSTOM CHALLENGES
                                            </Typography>

                                            <Stack direction='row' spacing={5} alignItems='center' justifyContent='center'>
                                                <Stack spacing={0.5} alignItems='center' justifyContent='center'>
                                                    <Typography variant='body1' sx={{ fontWeight: 1000, fontSize: '24px' }}>{completedchallenges.length + " / " + Object.values(challenges).length}</Typography>
                                                    <Typography variant='caption' color='text.secondary' >COMPLETED</Typography>
                                                </Stack>
                                                <Stack spacing={0.5} alignItems='center' justifyContent='center'>
                                                    <Typography sx={{ fontWeight: 1000, fontSize: '24px' }}>{Object.values(challenges).filter(c => c.author == profile?.discordID).length}</Typography>
                                                    <Typography variant='caption' color='text.secondary' >CREATED</Typography>
                                                </Stack>
                                            </Stack>


                                            {recentcchallenges.length !== 0 && <div><Typography sx={{ fontWeight: 1000 }} m={1}>Recent Challenges</Typography>

                                                <TableContainer component={Paper}>
                                                    <Table size="small" component={Paper} aria-label="simple table" elevation={7}>
                                                        <TableBody>
                                                            {recentcchallenges.map(challenge => (
                                                                <TableRow
                                                                    hover
                                                                    to={''}
                                                                    component={Link}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'text-decoration': "none" }}
                                                                >
                                                                    <TableCell align="left">
                                                                        <Stack>
                                                                            <Typography variant='body2' color='text.secondary'>
                                                                                {new Date(challenge.date).toLocaleString().split(",")[0]}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <Stack>
                                                                            <Typography variant='body2' >
                                                                                {challenge.challenge}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </TableCell>

                                                                    <TableCell align="right">
                                                                        <Typography variant='body2' sx={{ fontWeight: 1000, fontFamily: 'verdana' }}>
                                                                            {challenge.time}
                                                                        </Typography>
                                                                    </TableCell>

                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer></div>}
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Grid>}
                            {profile?.random && <Grid item xs={12}>
                                <Card sx={{ width: '100%', borderLeft: 1, borderColor: '#F04B35' }}>

                                    <Box sx={{ background: 'linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, ' + catcolors[3] + "  1000%)" }}>
                                        <CardContent>
                                            <Typography sx={{ fontSize: 14 }} variant='button' color="text.secondary" gutterBottom>
                                                RANDOM CHALLENGES
                                            </Typography>

                                            <Stack direction='row' spacing={5} alignItems='center' justifyContent='center'>
                                                <Stack spacing={0.5} alignItems='center' justifyContent='center'>
                                                    <Typography variant='body1' sx={{ fontWeight: 1000, fontSize: '24px' }}>{profile?.random?.achievements ? Object.values(profile?.random?.achievements).filter(a => a).length + " / " + Object.values(profile?.random.achievements).length : "0 / 8"}</Typography>
                                                    <Typography variant='caption' color='text.secondary' >ACHIEVEMENTS</Typography>
                                                </Stack>
                                                <Stack spacing={0.5} alignItems='center' justifyContent='center'>
                                                    <Typography sx={{ fontWeight: 1000, fontSize: '24px' }}>{numberWithCommas(profile?.random.truguts_earned - profile?.random.truguts_spent)}</Typography>
                                                    <Typography variant='caption' color='text.secondary' >TRUGUTS</Typography>
                                                </Stack>
                                            </Stack>


                                            {recentrchallenges.length !== 0 && <div><Typography sx={{ fontWeight: 1000 }} m={1}>Recent Challenges</Typography>

                                                <TableContainer component={Paper}>
                                                    <Table size="small" component={Paper} aria-label="simple table" elevation={7}>
                                                        <TableBody>
                                                            {recentrchallenges.map(challenge => (
                                                                <TableRow
                                                                    hover
                                                                    to={''}
                                                                    component={Link}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'text-decoration': "none" }}
                                                                >
                                                                    <TableCell align="left">
                                                                        <Stack>
                                                                            <Typography variant='body2' color='text.secondary'>
                                                                                {new Date(challenge.date).toLocaleString().split(",")[0]}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <Stack>
                                                                            <Typography variant='body2' >
                                                                                {challenge.desc}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </TableCell>

                                                                    <TableCell align="right">
                                                                        <Stack>{
                                                                            <Typography variant='body2' sx={{ fontWeight: 1000, fontFamily: 'verdana' }}>
                                                                                {challenge.time}
                                                                            </Typography>}
                                                                        </Stack>
                                                                    </TableCell>

                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer></div>}
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Grid>}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                            {recent.length !== 0 && <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} variant='button' color="text.secondary" >
                                            RECENT ACTIVITY
                                        </Typography>
                                    </CardContent>

                                    <TableContainer component={Paper}>
                                        <Table size="small" component={Paper} aria-label="simple table" >
                                            <TableBody>
                                                {recent.map(r => (
                                                    <TableRow
                                                        hover
                                                        to={r.link}
                                                        component={Link}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'text-decoration': "none" }}
                                                    >
                                                        <TableCell align="left">

                                                            <Stack direction='row' alignItems='center' spacing={1}>
                                                                <FontAwesomeIcon width='30px' size='lg' icon={r.desc.includes('Commentate') ? faMicrophoneLines : r.desc.includes('Played') ? faFlagCheckered : faStopwatch} />
                                                                <Stack>
                                                                    <Typography variant='body2'>
                                                                        {r.desc}
                                                                    </Typography>
                                                                    <Typography variant='body2' color='text.secondary'>
                                                                        {moment(r.date).fromNow()}
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>

                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </Card>
                            </Grid>}
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} variant='button' color="text.secondary" gutterBottom>
                                            TOP RACERS
                                        </Typography>
                                    </CardContent>
                                    <TableContainer component={Paper}>
                                        <Table size="small" component={Paper} aria-label="simple table" >
                                            <TableBody>
                                                {Object.values(most.racers).sort((a, b) => b.playtime - a.playtime).slice(0, 5).filter(r => r.playtime > 0).map(r => (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'text-decoration': "none" }}
                                                    >
                                                        <TableCell align="left">
                                                            <RacerName racer={r.index} />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Stack>
                                                                <Typography variant='body' sx={{ fontWeight: 1000 }}>
                                                                    {timestring(timefix(Math.round(r.playtime)))}
                                                                </Typography>
                                                                <Typography variant='subtitle' color='text.secondary'>
                                                                    {r.count + " runs"}
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} variant='button' color="text.secondary" gutterBottom>
                                            TOP TRACKS
                                        </Typography>
                                    </CardContent>
                                    <TableContainer component={Paper}>
                                        <Table size="small" component={Paper} aria-label="simple table" >
                                            <TableBody>
                                                {Object.values(most.tracks).sort((a, b) => b.playtime - a.playtime).slice(0, 5).filter(t => t.playtime > 0).map(t => (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'text-decoration': "none" }}
                                                    >
                                                        <TableCell align="left">
                                                            <TrackName track={t.index} />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Stack>
                                                                <Typography variant='body' sx={{ fontWeight: 1000 }}>
                                                                    {timestring(timefix(Math.round(t.playtime)))}
                                                                </Typography>
                                                                <Typography variant='subtitle' color='text.secondary'>
                                                                    {t.count + " runs"}
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} variant='button' color="text.secondary" gutterBottom>
                                            LIFETIME STATS
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
                {CurrentTab == 1 && <TimeSheetTab timesheet={TimeSheet} Conditions={Conditions} setConditions={setConditions} />}
            </Container>
            <Box sx={{ minHeight: '5vh' }} />
        </Box>
    )
}
