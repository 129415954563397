import { useState, useEffect } from 'react';
import { projectDatabase } from '../firebase/config';

export function useSpeedrun({ id = null }) {
  const [run, setRun] = useState({});
  const [loading, setLoading] = useState(true)
  console.log(id)
  useEffect(() => {
    setLoading(true)
    let runRef = projectDatabase.ref(`speedruns/${id}`);
    runRef.once('value', (snapshot) => {
      const runData = snapshot.val();
      console.log(runData)
      setRun(runData);
      setLoading(false)
    });
  }, [id]);

  return { run, loading };
}