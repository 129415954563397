import React from 'react';
import { Grid, Box } from '@material-ui/core';
import VideoEmbed from '../../../components/VideoEmbed';

const VideoCarousel = (videos) => {
  console.log(videos)
  return (
    <Grid container spacing={2}>
      {videos.videos.map((video) => (
        <Grid item key={video.url} xs={12} sm={6} md={4} >
          <VideoEmbed url={video.url} />
        </Grid>
      ))}
    </Grid>
  );
};

export default VideoCarousel;