import { useState, useEffect } from 'react';
import { projectDatabase } from '../firebase/config';

export function useParts() {
  const [parts, setParts] = useState({});

  useEffect(() => {
    const partsRef = projectDatabase.ref('parts');
    partsRef.once('value', (snapshot) => {
      const partsData = snapshot.val();
      // const partsList = [];
      // for (let id in partsData) {
      //   partsList.push({
      //     id: id,
      //     ...partsData[id],
      //   });
      // }
      setParts(partsData);
    });
  }, []);

  return parts;
}