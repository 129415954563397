import { Table, TableHead, TableRow, TableCell, Badge, Stack, TableContainer } from '@mui/material';
import { useMatches } from '../../../hooks/useMatches';
import { useRulesets } from '../../../hooks/useRulesets';
import { getSummary } from '../../../hooks/useTourneyRuns';
import UserName from '../../../tools/UserName';
import TimeDisplay from '../../../tools/TimeDisplay';

const BracketSummary = ({ tourney, bracket }) => {
    const matches = useMatches()
    const rulesets = useRulesets()
    const bracketmatches = matches.filter(m => m.tourney == tourney?.id && tourney?.stages[m.bracket]?.bracket == bracket)
    let bracketsummary = {}
    bracketmatches.forEach(match => {
        let summary = getSummary(match)
        Object.keys(summary).forEach(key => {
            if (bracketsummary[key]) {
                Object.keys(summary[key]).forEach(k => {
                    bracketsummary[key][k] += Number(summary[key][k])
                })
            } else {
                bracketsummary[key] = summary[key]
            }
        })
    })
    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Player
                        </TableCell>
                        <TableCell align='center'>
                            Matches
                        </TableCell>
                        <TableCell align='center'>
                            Races
                        </TableCell>
                        <TableCell align='right'>
                            Total Time
                        </TableCell>
                        <TableCell align='center'>
                            Total Score
                        </TableCell>
                    </TableRow>
                </TableHead>
                {Object.keys(bracketsummary).map(key => (
                    <TableRow>
                        <TableCell>
                            <UserName user_id={key} discord={true} avatar={true} />
                        </TableCell>
                        <TableCell align='center'>
                            {bracketsummary[key].matches}
                        </TableCell>
                        <TableCell align='center'>
                            {bracketsummary[key].races}
                        </TableCell>
                        <TableCell align='right'>
                            <Stack direction='row' spacing={1} justifyContent={'flex-end'}>
                                {![null, undefined, "", 0].includes(bracketsummary[key].deaths) && <Badge badgeContent={bracketsummary[key].deaths > 1 ? bracketsummary[key].deaths : ""}><img src={'/deaths.png'} width={20} height={20} /></Badge>}
                                <TimeDisplay time={bracketsummary[key].time} />
                            </Stack>
                        </TableCell>
                        <TableCell align='center'>
                            {bracketsummary[key].score}
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
        </TableContainer>
    );
};

export default BracketSummary