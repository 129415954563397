import { Container, Grid, Stack } from "@mui/material";
import HeroBanner from "../stats/HeroButton";
import ChallengeOfTheDay from "./ChallengeOfTheDay";
import UpcomingMatches from "./UpcomingMatches";
import RecentActivity from "./RecentActivity";
export default function Home() {
    return (<Container sx={{ minHeight: '100vh' }}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <HeroBanner title='GALACTIC TOURNAMENT 2023' link='/tournaments/2023' height={300} img='https://cdn.discordapp.com/attachments/441842584592056320/749704685690028042/Episode_I_Racer_8_30_2020_2_42_20_PM.png' />
            </Grid>
            <Grid item xs={8}>
                <Stack spacing={2}>
                    <ChallengeOfTheDay />
                    <UpcomingMatches />
                    <RecentActivity />
                </Stack>

            </Grid>
            <Grid item xs={4}>
                <iframe src="https://discord.com/widget?id=441839750555369474&theme=dark" width="100%" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
            </Grid>
        </Grid>


    </Container>)
}