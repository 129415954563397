import { BrowserRouter, Route, Switch, Redirect, Link } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext.js';
import { React, useState, Fragment, useContext } from 'react'
import { Paper } from '@mui/material';
import Navbar from './components/Navbar.js';
import Tourneys from './pages/tournaments/tourneys/Tourneys.js';
import Matches from './pages/tournaments/matches/Matches.js'
import RulesetMaker from './pages/rulesets/RulesetMaker.js'
import Login from './pages/login/Login.js'
import Signup from './pages/signup/Signup.js'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { yellow } from '@mui/material/colors'
import Ranks from './pages/tournaments/ranking/Ranks.js';
import Leaderboards from './pages/leaderboard/Leaderboards.js';
import Match from './pages/tournaments/matches/Match.js';
import MatchEdit from './pages/tournaments/matches/MatchEdit.js';
import Stats from './pages/stats/Stats.js'
import Player from './pages/players/Player.js'
import Footer from './components/Footer.js';
import Racers from './pages/racers/Racers.js';
import Tourney from './pages/tournaments/tourneys/Tourney.js';
import { projectDatabase, projectAuth } from './firebase/config';
import Tracks from './pages/tracks/Tracks.js';
import Home from './pages/home/Home.js';
import Run from './pages/leaderboard/Run.js';
import Live from './pages/tournaments/matches/Live.js';
import StatHome from './pages/stats/StatHome.js';
import Sim from './pages/simulators/Sim.js';
import DatabankHome from './pages/databank/DatabankHome.js';
import { AuthContext } from './context/AuthContext.js';
function App() {
  const { login } = useContext(AuthContext);
  const { authIsReady, user } = useAuthContext()
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: yellow,
      planet: {
        main: '#ffffff',
        dark: '#000099',
        light: '#6666ff',
      }
    },
  })

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const cachedUser = localStorage.getItem("discord_token");
  if (cachedUser && !user) {
    const userData = JSON.parse(cachedUser);
    login(userData, null)
  }

  return (
    <div className="App">

      <ThemeProvider theme={darkTheme}>

        <BrowserRouter>
          <Paper square style={{ backgroundColor: "#111111" }}>
            <Switch>
              <Route exact path="/live/:id">
                <Live />
              </Route>
              <Fragment>
                <Navbar />
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path='/databank'>
                  <DatabankHome />
                </Route>
                <Route exact path="/tournaments/">
                  <Tourneys />
                </Route>
                <Route exact path="/tournaments/:id">
                  <Tourney />
                </Route>
                <Route exact path="/tournaments/:id/matches/">
                  <Matches />
                </Route>
                <Route exact path="/tournaments/:id/matches/new">
                  <MatchEdit />
                </Route>
                <Route path="/tournaments/:id/matches/:id/edit">
                  <MatchEdit />
                </Route>
                <Route path="/tournaments/:id/matches/:id">
                  <Match />
                </Route>
                <Route path="/rulesets">
                  <RulesetMaker />
                </Route>
                <Route exact path="/runs/:id">
                  <Run />
                </Route>
                <Route path="/rankings/">
                  <Ranks />
                </Route>
                <Route exact path="/stats">
                  <StatHome />
                </Route>
                <Route path="/stats/:id">
                  <Stats />
                </Route>
                <Route path='/players/:id'>
                  <Player />
                </Route>
                <Route path="/leaderboards/" exact>
                  <Redirect to='/leaderboards/The_Boonta_Training_Course' />
                </Route>
                <Route path="/leaderboards/:id">
                  <Leaderboards />
                </Route>
                <Route path="/racers/">
                  <Racers />
                </Route>
                <Route path="/tracks/">
                  <Tracks />
                </Route>
                <Route path="/simulators/">
                  <Sim />
                </Route>
                <Route path="/login">
                  {user && <Redirect to="/" />}
                  {!user && <Login />}
                </Route>
                {/* <Route path="/signup">
                  {user && <Redirect to="/" />}
                  {!user && <Signup />}
                </Route> */}
                <Footer />
              </Fragment>
            </Switch>

          </Paper>
        </BrowserRouter>
      </ThemeProvider>
    </div >
  );
}

export default App;
