import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@mui/material';
const useStyles = makeStyles({
  color: "#FFFFFF",
  underline: {
    textDecoration: 'none',
    borderRadius: '5px',
    padding: '5px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.04)'
    },
  }
});

const UnformattedLink = ({ to, children }) => {
  const classes = useStyles();
  return (

    <Link to={to} style={{ color: "#FFFFFF", textDecoration: 'none' }} onClick={() => {
      window.scrollTo({
        top: 0
      });
    }}>
      <Box className={classes.underline}>
        {children}
      </Box>
    </Link>
  )
}

export default UnformattedLink