

export default function VideoEmbed({ url }) {

    if (!url.includes('yout')) {
        return (<p>Video could not be embeded</p>)
    }

    if (url.includes('&list')) {
        url = url.split('&list')[0]
        
    }

    return (<div
        className="video"
        style={{
            position: "relative",
            paddingBottom: "56.25%" /* 16:9 */,
            paddingTop: 25,
            height: 0
        }}
    >
        <iframe
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
            }}
            src={`https://www.youtube.com/embed/${url?.replace('https://youtu.be/', '').replace('https://www.youtube.com/watch?v=', '')}`}
            frameBorder="0"
        />
    </div>)
}