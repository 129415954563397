import { Typography, Grid, Card, Box, Stack, Avatar, CardContent, CardMedia, CardActionArea, Container, CardHeader } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useRacers } from '../../hooks/useRacers.js';
import TrackTitle from '../../tools/TrackTitle.js';
import { usePlanets } from '../../hooks/usePlanets';
import { useTracks } from '../../hooks/useTracks';
import Title from '../../tools/Title.js'
import { styled } from '@material-ui/core/styles';

// Define a custom styled component for the card
const ColoredCard = styled(Card)(({ theme, backgroundColor }) => ({
    background: `linear-gradient(to top, ${backgroundColor} -200%, rgba(0, 0, 0, 0) 50%)`,
    border: `solid 1px ${backgroundColor}`
}));

export default function Tracks() {
    const tracks = useTracks();
    const planets = usePlanets()
    console.log(planets)
    console.log('running')
    return (
        <Container>
            {[tracks].includes(null) &&
                <Box sx={{
                    display: 'flex', alignItems: "center", height: 'calc(100vh - 90px)',
                    justifyContent: "center"
                }}>
                    <CircularProgress />
                </Box>
            }
            {tracks && planets && <Box sx={{ width: '100%', minHeight: '100vh' }}>
                <Title text="TRACKS" color="#FFFFFF" />
                <Grid mt={1}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                >
                    {Object.values(tracks).map((track, index) => {
                        return <Grid item lg={6} xs={12}>
                            <ColoredCard elevation={0} backgroundColor={planets[track.planet]?.color}>
                                <CardHeader
                                    avatar={planets[track.planet] &&
                                        <Avatar variant='square' src={planets[track.planet].img} />
                                    }
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}
                                    title={<Typography variant="h6" style={{ fontSize: 20, fontFamily: 'Roboto' }}>
                                        {track.name}
                                    </Typography>}
                                    subheader={planets[track.planet] ? planets[track.planet].name : ""}
                                />
                                <CardMedia
                                    component="img"
                                    height="194"
                                    image={track.img}
                                    style={{ objectFit: 'contain' }}
                                />
                            </ColoredCard>
                        </Grid>
                    })}
                </Grid>



            </Box>}

        </Container>
    )
}
