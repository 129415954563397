import { Typography, Stack, Table, TableHead, TableRow, TableCell, TableBody, TableContainer} from '@mui/material';
import UserName from '../../tools/UserName.js'
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TimeDisplay from '../../tools/TimeDisplay.js';
import TrackName from '../../components/TrackName.js';
const useStyles = makeStyles({
  tableCell: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
      cursor: 'pointer',
    },
  },
});
export default function LeaderboardAll({ BestTimes, tracks }) {
    const classes = useStyles();
    return (
        <TableContainer sx={{ pt: 2 }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell >Track</TableCell>
                        <TableCell align='center'>Record</TableCell>
                        <TableCell align='center'>Next Best Pod</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(BestTimes).map((leaderboard, trackindex) => {
                        return <TableRow key={trackindex} sx={{ alignItems: "center", justifyContent: "center" }}>
                            <TableCell className={classes.tableCell} sx={{ textDecoration: 'none' }} component={Link} to={'/leaderboards/' + tracks[trackindex]?.name.replaceAll(" ", "_").replaceAll("'", '')}>
                                <TrackName track = {trackindex}/>
                            </TableCell>
                            <TableCell align='center' >
                                {leaderboard.first?.time && <Stack
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={0}>
                                    <TimeDisplay component={Link} to={'/tournaments/matches/' + leaderboard.first.match} color="#FFFFFF" time = {leaderboard.first.time}/>
                                    <Stack direction='row' alignItems='center' spacing={1}>
                                        <img sx={{ opacity: "0.5" }} src={`/flags/${leaderboard.first.pod}.png`} width={18} height={15} />
                                        <Typography variant='h5' component={Link} to={`/players/${leaderboard.first.player}`} style={{ textDecoration: 'none' }} color="#FFFFFF">
                                            {UserName({user: leaderboard.first.user, avatar : false})}
                                        </Typography>
                                    </Stack>
                                </Stack>}
                                {!leaderboard.first?.time && <Typography>-</Typography>}
                            </TableCell >
                            <TableCell align='center'>
                                {leaderboard.second?.time && <Stack
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={0}>
                                    <TimeDisplay component={Link} to={'/tournaments/matches/' + leaderboard.second.match} color="#FFFFFF" time = {leaderboard.second.time}/>
                                    <Stack direction='row' alignItems='center' spacing={1}>
                                        <img sx={{ opacity: "0.5" }} src={`/flags/${leaderboard.second.pod}.png`} width={18} height={15} />
                                        <Typography variant='h5' component={Link} to={`/players/${leaderboard.second.player}`} style={{ textDecoration: 'none' }} color="#FFFFFF">
                                            {UserName({user: leaderboard.second.user, avatar: false})}
                                        </Typography>
                                    </Stack>
                                </Stack>}
                                {!leaderboard.second?.time && <Typography>-</Typography>}
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}