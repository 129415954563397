import { useParams, Link } from 'react-router-dom'
import { Button, Typography, Grid, Box, Stack, Tooltip, Alert, AlertTitle, Divider, Container, Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';

import { timefix } from '../../../tools.js'

import TokenIcon from '@mui/icons-material/Token';
import RepeatIcon from '@mui/icons-material/Repeat';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { getRanks } from '../ranking/getRanks.js';
import StarsIcon from '@mui/icons-material/Stars';

import { useMatches } from '../../../hooks/useMatches.js';
import { useRulesets } from '../../../hooks/useRulesets.js';

import { getPlayers } from '../../../hooks/useTourneyRuns.js';
import UserName from '../../../tools/UserName.js';
import MatchCard from '../tourneys/MatchCard.js';
import MatchHeader from './MatchHeader.js';
import RaceAccordion from './RaceAccordion.js';
import { useState } from 'react';
import MatchHeaderEdit from './MatchHeaderEdit.js';
import { projectDatabase } from '../../../firebase/config.js';
//import HandshakeIcon from '@mui/icons-material/Handshake';
export default function Match() {
    const { id } = useParams()
    const matches = useMatches()
    const rulesets = useRulesets()
    const [edit, setEdit] = useState(false)
    const [editMatch, setEditMatch] = useState(matches.find(m => m.id == id))
    const matchref = projectDatabase.ref(`tourney/matches/${editMatch?.id}`)
    const [TimeDiff, setTimeDiff] = useState(false)

    let thismatch = null, thisruleset = null
    let previous = [], next = []
    let racetracks = null, specialcon = []
    let players = []
    let besttimes = {}
    let futurebest = {}
    let futurepb = {}
    let pb = {}
    let matchsummary = {}
    let sharedmatches = []
    let ranks = {}
    let previousranks = {}

    if (matches.length && rulesets.length) {
        thismatch = matches.find(m => m.id == id)
        if (!editMatch?.id || editMatch.id !== thismatch.id) {
            setEditMatch(thismatch)
        }
        if (thismatch) {
            thisruleset = rulesets.find(r => r.id == thismatch.ruleset)
            racetracks = []
            previousranks = getRanks(matches, thismatch.datetime - 1, rulesets)
            ranks = getRanks(matches, thismatch.datetime, rulesets)
            previous = matches.filter(m => {
                return Number(m.datetime) < Number(thismatch.datetime)
            }).sort(function (a, b) {
                return Number(b.datetime) - Number(a.datetime)
            }).map(m => m.id)
            next = matches.filter(m => {
                return Number(m.datetime) > Number(thismatch.datetime)
            }).sort(function (a, b) {
                return Number(a.datetime) - Number(b.datetime)
            }).map(m => m.id)
            players = getPlayers(thismatch)
            players.forEach(player => {
                matchsummary[player] = {
                    score: 0,
                    time: 0,
                    truetime: true,
                    deaths: 0,
                    fpspent: 0,
                    runbacks: 0,
                    timediff: [],
                    player: player,
                    alldeaths: []
                }
            })

            matches.forEach(match => {
                if (players.length == 2 && match.races) {
                    let matchplayers = getPlayers(match)
                    if ((matchplayers.includes(players[0]) && matchplayers.includes(players[1])) || ((matchplayers.includes(players[0]) || matchplayers.includes(players[1])) && match.ruleset == thismatch.ruleset)) {
                        sharedmatches.push(match)
                    }
                }
                if (match.races && thisruleset) {
                    Object.values(match.races).forEach((race, raceindex) => {
                        let thistrack = null
                        let conditions = {}
                        if (thisruleset.general.type == '1v1') {
                            conditions = { ...thisruleset.general.default }
                            if (race.events) {
                                Object.values(race.events).forEach(event => {
                                    if (event.event == 'selection' && event.type == 'track') {
                                        thistrack = event.selection
                                    }
                                    if (event.event == 'override' && event.type == 'condition') {
                                        if (event.selection == 'nu') {
                                            conditions.upgr = 'nu'
                                        }
                                        if (event.selection == 'fl') {
                                            conditions.time = 'fl'
                                        }
                                        if (event.selection == 'sk') {
                                            conditions.trak = 'sk'
                                        }
                                    }
                                })
                            }

                        } else {
                            thistrack = thisruleset.playlist[raceindex]?.track
                            conditions = { ...thisruleset.playlist[raceindex]?.conditions }
                        }

                        if (race.runs && thisruleset.general.type !== 'Qualifier') {
                            Object.values(race.runs).forEach(run => {
                                let runconditions = { ...conditions }
                                if (run.retroskip) {
                                    runconditions.trak = 'sk'
                                }
                                runconditions = Object.values(runconditions)
                                if (String(run.time).toUpperCase() !== 'DNF') {
                                    if (Number(match.datetime) < Number(thismatch.datetime)) {
                                        if (thisruleset.general.type !== 'Qualifier') {
                                            if (!besttimes[thistrack]) {
                                                besttimes[thistrack] = {}
                                            }
                                            if (besttimes[thistrack][runconditions.join("")]) {
                                                if (Number(run.time) - Number(besttimes[thistrack][runconditions.join("")].time) < 0) {
                                                    besttimes[thistrack][runconditions.join("")] = run
                                                }
                                            } else {
                                                besttimes[thistrack][runconditions.join("")] = run
                                            }


                                            if (players.includes(run.player)) {
                                                if (!pb[thistrack]) {
                                                    pb[thistrack] = {}
                                                }
                                                if (!pb[thistrack][run.player]) {
                                                    pb[thistrack][run.player] = {}
                                                }
                                                if (pb[thistrack][run.player][runconditions.join("")]) {
                                                    if (run.time - pb[thistrack][run.player][runconditions.join("")].run.time < 0) {
                                                        pb[thistrack][run.player][runconditions.join("")].run = run
                                                    }
                                                } else {
                                                    pb[thistrack][run.player][runconditions.join("")] = { run: run, avg: [], deaths: [] }
                                                }
                                            }
                                        }
                                    } else {
                                        if (thisruleset.general.type !== 'Qualifier') {
                                            if (!futurebest[thistrack]) {
                                                futurebest[thistrack] = {}
                                            }
                                            if (futurebest[thistrack][runconditions.join("")]) {
                                                if (run.time - futurebest[thistrack][runconditions.join("")].time < 0) {
                                                    futurebest[thistrack][runconditions.join("")] = run
                                                }
                                            } else {
                                                futurebest[thistrack][runconditions.join("")] = run
                                            }
                                            if (players.includes(run.player)) {
                                                if (!futurepb[thistrack]) {
                                                    futurepb[thistrack] = {}
                                                }
                                                if (!futurepb[thistrack][run.player]) {
                                                    futurepb[thistrack][run.player] = {}
                                                }
                                                if (futurepb[thistrack][run.player][runconditions.join("")]) {
                                                    if (run.time - futurepb[thistrack][run.player][runconditions.join("")].time < 0) {
                                                        futurepb[thistrack][run.player][runconditions.join("")] = run
                                                    }
                                                } else {
                                                    futurepb[thistrack][run.player][runconditions.join("")] = run
                                                }
                                            }
                                        }
                                    }
                                    if (players.includes(run.player) && thisruleset.general.type !== 'Qualifier') {
                                        if (!pb[thistrack]) {
                                            pb[thistrack] = {}
                                        }
                                        if (!pb[thistrack][run.player]) {
                                            pb[thistrack][run.player] = {}
                                        }
                                        if (!pb[thistrack][run.player][runconditions.join("") + run.pod]) {
                                            pb[thistrack][run.player][runconditions.join("") + run.pod] = { run: {}, avg: [], deaths: [] }
                                        }
                                        pb[thistrack][run.player][runconditions.join("") + run.pod].avg.push(Number(run.time))

                                    }
                                }
                                if (players.includes(run.player) && ![null, undefined, ""].includes(run.deaths) && thisruleset.general.type !== 'Qualifier') {
                                    matchsummary[run.player].alldeaths.push(Number(run.deaths))
                                    if (!pb[thistrack]) {
                                        pb[thistrack] = {}
                                    }
                                    if (!pb[thistrack][run.player]) {
                                        pb[thistrack][run.player] = {}
                                    }
                                    if (!pb[thistrack][run.player][runconditions.join("") + run.pod]) {
                                        pb[thistrack][run.player][runconditions.join("") + run.pod] = { run: {}, avg: [], deaths: [] }
                                    }
                                    pb[thistrack][run.player][runconditions.join("") + run.pod].deaths.push(Number(run.deaths))
                                }
                            })

                        }


                    })
                }

            })
            sharedmatches.forEach(match => {
                if (!match.players) {
                    match.players = Object.values(match.races[0].runs).map(run => run.player)
                }
                match.score = {}
                if (match.players) {
                    Object.values(match.players).forEach(player => {
                        match.score[player] = { score: 0, time: 0, truetime: true, player: player, type: ['Qualifier', 'RTA'].includes(thisruleset.general.type) ? 'time' : 'score' }
                    })
                }
                console.log(match.score)
                if (match.races) {
                    Object.values(match.races).forEach(race => {
                        let winner = { time: null, player: null }
                        Object.values(race.runs).forEach(run => {
                            if (winner.time && run.time !== 'DNF' && run.time - winner.time < 0) {
                                winner.time = run.time
                                winner.player = run.player
                            } else if (!winner.time && run.time !== 'DNF') {
                                winner.time = run.time
                                winner.player = run.player
                            }
                            if (match.score[run.player]) {
                                if (run.time !== 'DNF') {
                                    match.score[run.player].time += Number(run.time)
                                } else {
                                    match.score[run.player].truetime = false
                                }
                            }

                        })
                        if (match.score[winner.player]) {
                            match.score[winner.player].score++
                        }
                    })
                    match.score = Object.values(match.score).sort((a, b) => {
                        if (a.type == 'time') {
                            if (!a.truetime && !b.truetime) {
                                return 0
                            } else if (!a.truetime) {
                                return 1
                            } else if (!b.truetime) {
                                return -1
                            } else {
                                return a.time - b.time
                            }
                        } else {
                            return b.score - a.score
                        }
                    })
                }
                if (match.rta) {
                    Object.values(match.rta).forEach(rta => {
                        if (match.score[rta.player]) {
                            match.score[rta.player].time = rta?.total

                        }
                    })
                }
            })
            sharedmatches = sharedmatches.filter(match => thismatch.datetime !== match.datetime)
            if (thismatch?.races) {
                thismatch.races.forEach((race, index) => {
                    let winner = { time: null, player: null }
                    let thistrack = null
                    let conditions = {}
                    let pod = null
                    if (thisruleset?.general?.type == '1v1') {
                        conditions = { ...thisruleset.general.default }
                        if (race.events) {
                            Object.values(race.events).forEach(event => {
                                if (event.cost && event.cost > 0) {
                                    matchsummary[event.player].fpspent += Number(event.cost)
                                }
                                if (event.event == 'selection' && event.type == 'track') {
                                    thistrack = Array.isArray(event.selection) ? event.selection[0] : event.selection
                                    if (event.repeat) {
                                        matchsummary[event.player].runbacks++
                                    }
                                }
                                if (event.event == 'override' && event.type == 'condition') {
                                    if (Array.isArray(event.selection)) {
                                        specialcon.push(event.selection[0])
                                    } else {
                                        specialcon.push(event.selection)
                                    }
                                    if (event.selection == 'nu') {
                                        conditions.upgr = 'nu'
                                    }
                                    if (event.selection == 'fl') {
                                        conditions.time = 'fl'
                                    }
                                    if (event.selection == 'sk') {
                                        conditions.trak = 'sk'
                                    }
                                }
                            })
                        }
                    } else {
                        thistrack = thisruleset?.playlist[index].track
                        conditions = { ...thisruleset?.playlist[index]?.conditions }
                        pod = thisruleset?.playlist[index].pod
                    }

                    racetracks.push({ track: thistrack, con: conditions, pod: pod })
                    Object.values(race.runs).forEach((run) => {

                        let runconditions = { ...conditions }
                        if (run.retroskip) {
                            runconditions.trak = 'sk'
                        }
                        runconditions = Object.values(runconditions)
                        if (thismatch.players && Object.values(thismatch.players).includes(run.player)) {
                            matchsummary[run.player].deaths += Number(run.deaths)
                            if (run.time !== 'DNF') {
                                matchsummary[run.player].time += Number(run.time)
                            } else {
                                matchsummary[run.player].truetime = false
                            }
                            if (winner.time && run.time !== 'DNF' && run.time - winner.time < 0) {
                                winner.time = run.time
                                winner.player = run.player
                            } else if (!winner.time && run.time !== 'DNF') {
                                winner.time = run.time
                                winner.player = run.player
                            }
                            if (players.length == 2 && run.time !== 'DNF' && Object.values(race.runs).filter(r => r.player !== run.player).map(r => r.time)[0] !== 'DNF') {
                                matchsummary[run.player].timediff.push(run.time - Object.values(race.runs).filter(r => r.player !== run.player).map(r => r.time)[0])
                            }
                            if (Number(run.time) - Number(besttimes[thistrack]?.[runconditions.join("")]?.time) < 0 && Object.values(race.runs).map(r => Number(run.time) <= Number(r.time)).reduce((a, b) => a && b)) {
                                run.record = true
                                if (Number(futurebest[thistrack]?.[runconditions.join("")]?.time) - Number(run.time) < 0) {
                                    run.formerrecord = true
                                }
                            }
                            if (Number(run.time) - Number(pb[thistrack]?.[run.player]?.[runconditions.join("")]?.run?.time) < 0) {
                                run.pb = true
                                if (Number(futurepb[thistrack]?.[run.player]?.[runconditions.join("")]?.time) - Number(run.time) < 0) {
                                    run.formerpb = true
                                }
                            }
                            if (!pb[thistrack]?.[run.player]?.[runconditions.join("")]?.run?.time) {
                                run.new = true
                            }
                            if (pb[thistrack]?.[run.player]?.[runconditions.join("") + run.pod]?.avg) {
                                let avg = pb[thistrack]?.[run.player]?.[runconditions.join("") + run.pod]?.avg
                                if (avg && avg.length > 0) {
                                    run.avg = avg.reduce((a, b) => a + b) / avg.length
                                }
                            }
                            if (pb[thistrack]?.[run.player]?.[runconditions.join("") + run.pod]?.deaths) {
                                let deaths = pb[thistrack]?.[run.player]?.[runconditions.join("") + run.pod]?.deaths
                                if (deaths && deaths.length > 0) {
                                    run.deathavg = (deaths.reduce((a, b) => a + b) / deaths.length).toFixed(2)
                                }
                            }
                        }

                    })
                    matchsummary[winner.player].score++

                })
            }
            matchsummary = Object.values(matchsummary).sort((a, b) => {
                if (['RTA', 'Qualifier'].includes(thisruleset?.general?.type)) {
                    if (thismatch.rta) {
                        return thismatch.rta[a.player].total - thismatch.rta[b.player].total
                    } else {
                        if (!a.truetime && !b.truetime) {
                            return 0
                        } else if (!a.truetime) {
                            return 1
                        } else if (!b.truetime) {
                            return -1
                        } else {
                            return a.time - b.time
                        }
                    }
                } else {
                    return b.score - a.score
                }
            })
        }
    }
    return (
        <Container sx={{ minHeight: '100vh' }}>
            <Stack direction="row"
                justifyContent="space-between"
                alignItems="center">
                {previous[0] ? <Button component={Link} to={`/tournaments/${previous[0]?.tourney ?? thismatch?.tourney}/matches/${previous[0] ?? id}`} sx={{ 'text-decoration': "none", mr: 1 }}><ChevronLeftIcon /></Button> : <ChevronLeftIcon />}
                <Button href={'/tournaments/matches'} sx={{ 'text-decoration': "none", mr: 1 }}>ALL MATCHES</Button>
                {next[0] ? <Button to={`/tournaments/${next[0]?.tourney}/matches/${next[0]}`} component={Link} sx={{ 'text-decoration': "none" }} disabled={next.length == 0}><ChevronRightIcon /></Button> : <ChevronRightIcon />}
            </Stack>
            {thismatch &&
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item lg={8} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <MatchHeader match={thismatch} edit={edit} setEdit={setEdit} />
                            </Grid>
                            <Grid item xs={12}>
                                {thismatch?.races?.map((race, index) => {
                                    let ref = matchref.child('races').child(index)
                                    return (
                                        <RaceAccordion race={race} index={index} TimeDiff={TimeDiff} setTimeDiff={setTimeDiff} edit={edit} reff={ref} />)
                                })}
                            </Grid>
                            {edit && <Grid item xs={12}>
                                <Button mt={2} size='small' fullWidth variant='contained' onClick={() => {
                                    let newraceindex = 0
                                    if (thismatch.races) {
                                        newraceindex = Object.values(thismatch.races).length
                                    } else {
                                        thismatch.races = []
                                    }
                                    thismatch.races[newraceindex] = {
                                        events: {},
                                        runs: {}
                                    }
                                    thismatch.players.forEach(player => {
                                        thismatch.races[newraceindex].runs[player] = {
                                            deaths: "",
                                            notes: "",
                                            platform: "",
                                            player: player,
                                            pod: "",
                                            time: ""
                                        }
                                    })
                                    matchref.update(thismatch)
                                }}><AddIcon />Add Race</Button>
                            </Grid>}
                            {thismatch?.forfeit?.player && <Grid item xs={12}><Alert severity="error">
                                <AlertTitle>Forfeit</AlertTitle>
                                This match was forfeited by <strong><UserName user_id={thismatch.forfeit.player} discord={true} /></strong><br />
                                Reason: {thismatch.forfeit.reason}
                            </Alert></Grid>}
                            {false && players.length == 2 && <Grid item xs={12}>
                                <Box sx={{ borderRadius: '10px', height: '50px', display: 'flex', flexDirection: 'row', pt: 0, alignItems: "center", justifyContent: "center" }} backgroundColor={matchsummary[0].player == players[0] ? "#54E4FF" : "#FF2180"}>
                                    <Typography variant='h5' style={{ textDecoration: 'none', fontWeight: 700 }}>
                                        <UserName user_id={matchsummary[0].player} discord={true} /> Wins!
                                    </Typography>
                                </Box>
                            </Grid>}
                            {thismatch.races &&
                                <Grid item xs={12}>
                                    <Box mb={10} mt={3}>
                                        <Divider>
                                            <Typography variant="button" sx={{ fontSize: 14, }} color="text.secondary">Match Summary</Typography>
                                        </Divider>

                                        {players.length == 2 &&
                                            <Grid
                                                direction='row'
                                                height='100px'
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                                p={2}
                                                mb={2}
                                            >

                                                <Grid item xs={4}>
                                                    <Stack alignItems='center'>
                                                        <UserName
                                                            user_id={matchsummary[0].player}
                                                            discord={true}
                                                            avatar={true}
                                                            link={true}
                                                            sub={thisruleset.general.ranked && ranks[matchsummary[0].player] &&
                                                                <Tooltip enterTouchDelay={0} placement='top' title={(ranks[matchsummary[0].player].change.toFixed(0) > 0 ? "+" : "") + String(ranks[matchsummary[0].player].change.toFixed(0))}>
                                                                    <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: "center" }} direction='row'>
                                                                        <StarsIcon sx={{ fontSize: 12 }} /><Typography variant='caption'>{ranks[matchsummary[0].player].rank.toFixed(0)}</Typography></Stack>
                                                                </Tooltip>} />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4} alignItems='center' justifyContent='center'>
                                                    <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
                                                        <Typography variant='h5' sx={{ textDecoration: 'none', fontWeight: 700 }}>
                                                            {thisruleset.general.type == 'RTA' ? (thismatch.rta ? timefix(thismatch.rta[matchsummary[0].player].total) : timefix(matchsummary[0].time)) : matchsummary[0].score}
                                                        </Typography>
                                                        <Typography variant='h5' sx={{ textDecoration: 'none', fontWeight: 700 }}>-</Typography>
                                                        <Typography variant='h5' sx={{ textDecoration: 'none', fontWeight: 700 }}>
                                                            {thisruleset.general.type == 'RTA' ? (thismatch.rta ? timefix(thismatch.rta[matchsummary[1].player].total) : timefix(matchsummary[1].time)) : matchsummary[1].score}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack alignItems='center'>
                                                        <UserName
                                                            user_id={matchsummary[1].player}
                                                            discord={true}
                                                            avatar={true}
                                                            link={true}
                                                            sub={thisruleset.general.ranked && ranks[matchsummary[1].player] &&
                                                                <Tooltip enterTouchDelay={0} placement='top' title={(ranks[matchsummary[1].player].change.toFixed(0) > 0 ? "+" : "") + String(ranks[matchsummary[1].player].change.toFixed(0))}>
                                                                    <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: "center" }} direction='row'>
                                                                        <StarsIcon sx={{ fontSize: 12 }} /><Typography variant='caption'>{ranks[matchsummary[1].player].rank.toFixed(0)}</Typography></Stack>
                                                                </Tooltip>} />
                                                    </Stack>
                                                </Grid>
                                            </Grid>}

                                        <TableContainer>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Player</TableCell>
                                                        {players.length !== 2 && !['RTA', 'Qualifier'].includes(thisruleset?.general.type) && <TableCell>Score</TableCell>}
                                                        {thismatch.rta && <TableCell align='right'>RTA</TableCell>}
                                                        <TableCell align="right">Total Time</TableCell>
                                                        <TableCell align="right">Time Diff.</TableCell>
                                                        <TableCell align="right">Deaths/Race</TableCell>
                                                        {thisruleset?.general.type == '1v1' && <TableCell align="right">Spending</TableCell>}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {matchsummary.map((summary, sumindex) => {
                                                        return <TableRow>
                                                            <TableCell >
                                                                <Stack direction='row'><UserName user_id={summary.player} discord={true} />{sumindex == 0 && <EmojiEventsIcon sx={{ fontSize: 15 }} />}</Stack></TableCell>
                                                            {players.length !== 2 && !['RTA', 'Qualifier'].includes(thisruleset?.general.type) && <TableCell>{summary.score}</TableCell>}
                                                            {thismatch.rta && <TableCell align='right' sx={{ fontFamily: 'monospace', textDecoration: 'none', fontWeight: 700 }}>{timefix(thismatch.rta[summary.player].total)}</TableCell>}
                                                            <TableCell align="right" sx={{ fontFamily: 'monospace', textDecoration: 'none', fontWeight: 700 }}>{timefix(summary.time)}</TableCell>
                                                            <TableCell align="right" sx={{ fontFamily: 'monospace', textDecoration: 'none', fontWeight: 700 }}>
                                                                {summary.timediff.length > 0 ?
                                                                    ((summary.timediff.reduce((a, b) => { return a + b }) / summary.timediff.length).toFixed(3) > 0 ?
                                                                        "+" : "") + (summary.timediff.reduce((a, b) => { return a + b }) / summary.timediff.length).toFixed(3) : "-"}</TableCell>
                                                            <Tooltip enterTouchDelay={0} placement='top' title={summary.alldeaths.length > 0 ? "Career Average: " + (summary.alldeaths.reduce((a, b) => a + b) / summary.alldeaths.length).toFixed(2) : ""}><TableCell align="right">{(summary.deaths / Object.values(thismatch.races).length).toFixed(2)}</TableCell></Tooltip>
                                                            {thisruleset?.general.type == '1v1' && <TableCell align="right">{" " + summary.fpspent}<TokenIcon sx={{ fontSize: 15 }} />{"  " + summary.runbacks}<RepeatIcon sx={{ fontSize: 15 }} /></TableCell>}
                                                        </TableRow>
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Grid>}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Stack spacing={4}>
                            {edit && <MatchHeaderEdit editMatch={editMatch} setEditMatch={setEditMatch} />}
                            {sharedmatches.filter(match => thismatch.datetime !== match.datetime).length > 0 &&
                                <Stack spacing={2}>
                                    <Typography variant="button" sx={{ fontSize: 14, }} color="text.secondary">Similar Matches</Typography>
                                    {sharedmatches.sort((a, b) => getPlayers(a).includes(players[0]) && getPlayers(a).includes(players[1]) ? -1 : 1).map(match => (
                                        <MatchCard match={match} verbose={true} />
                                    ))}
                                </Stack>}
                        </Stack>
                    </Grid>
                </Grid>
            }
        </Container>

    )
}
