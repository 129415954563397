
export const getRanks = (matches, stopdate, rulesets) => {
    let ranks = {}
    matches.sort(function (a, b) {
        return a.datetime - b.datetime;
    }).filter(m => m.datetime <= stopdate).forEach(match => {
        let ruleset = rulesets.find(ruleset => ruleset.id == match.ruleset)
        if (ruleset?.general?.ranked) { 
            let players = {}
            if(match.races){
                Object.values(match.races).forEach(race => {
                    let winner = { player: null, time: null }
                    Object.values(race.runs).forEach(run => {
                        if (!players[run.player]) {
                            players[run.player] = { player: run.player, score: 0, time: 0 }
                        }
                        if (run.time !== "DNF" && (winner.time == null || run.time - winner.time < 0)) {
                            winner.time = run.time
                            winner.player = run.player
                        }
                        if(run.time !== 'DNF'){
                            players[run.player].time += Number(run.time)
                        }
                    })
                    players[winner.player].score++
                })
            }
            
            players = Object.values(players)
            if(ruleset.general.type == 'RTA'){
                if(match.rta){
                    if(match.rta[players[0].player].total - match.rta[players[1].player].total < 0){
                        players[0].score = 1
                        players[1].score = 0
                    } else {
                        players[1].score = 1
                        players[0].score = 0
                    }
                } else {
                    if(players[0].time - players[1].time < 0){
                        players[0].score = 1
                        players[1].score = 0
                    } else {
                        players[1].score = 1
                        players[0].score = 0
                    }
                }
            }
            
            if (players.length == 2) {
                for (let j = 0; j < players.length; j++) {
                    //initialize player
                    if (ranks[players[j].player] == undefined) {
                        ranks[players[j].player] = { rank: 1000, matches: 0, change: 0 }
                    }
                }
                let r1 = ranks[players[0].player].rank
                let r2 = ranks[players[1].player].rank
                let p1 = 1 / (1 + 10 ** ((r2 - r1) / 400))
                let p2 = 1 - p1
                function getK(matches) {
                    let k = 25
                    if (matches < 26) {
                        k = 32
                    }
                    if (matches < 11) {
                        k = 40
                    }
                    if (matches < 6) {
                        k = 50
                    }
                    return k
                }
                let k1 = getK(ranks[players[0].player].matches)
                let k2 = getK(ranks[players[1].player].matches)
                let s1 = null, s2 = null
                if (!["", null, undefined].includes(players[0].score) && !["", null, undefined].includes(players[1].score)) {
                    if (players[0].score > players[1].score) {
                        s1 = 1
                        s2 = 0
                    } else if (players[1].score > players[0].score) {
                        s1 = 0
                        s2 = 1
                    }
                    ranks[players[0].player].rank += k1 * (s1 - p1)
                    ranks[players[1].player].rank += k2 * (s2 - p2)
                    ranks[players[0].player].change = k1 * (s1 - p1)
                    ranks[players[1].player].change = k2 * (s2 - p2)
                    ranks[players[0].player].matches++
                    ranks[players[1].player].matches++
                }
            }
        }
    })
    return ranks
}