import { useParams, Link, useHistory } from 'react-router-dom'
import { useState } from 'react'
import { Typography, Grid, Box, Button, Paper, Tabs, Tab, Select, Breadcrumbs, MenuItem, Stack, ToggleButtonGroup, ToggleButton, Container } from '@mui/material';
import { projectDatabase } from '../../../firebase/config';
import RuleIcon from '@mui/icons-material/Rule';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import HomeIcon from '@mui/icons-material/Home';
import SchemaIcon from '@mui/icons-material/Schema';
import TableRowsIcon from '@mui/icons-material/TableRows';
import EditIcon from '@mui/icons-material/Edit';

import { useTournaments } from '../../../hooks/useTournaments.js';
import Title from '../../../tools/Title.js';
import UserName from '../../../tools/UserName.js';
import VideoCarousel from './VideoCarousel.js';
import Bracket from './Bracket.js';
import BracketSummary from './BracketSummary.js';
import { useAuthContext } from '../../../hooks/useAuthContext.js';

export default function Tourney() {
    const { id } = useParams()
    const history = useHistory()
    const { user } = useAuthContext()
    const tourneys = useTournaments()
    const [Sheet, setSheet] = useState(0)
    const [View, setView] = useState(0)
    const thistourney = tourneys.find(t => t.nickname.toLowerCase().replaceAll(" ", "_").replaceAll(".", "") == id)
    const stages = thistourney ? [...new Set(Object.values(thistourney.stages).sort((a, b) => a.bracket_order - b.bracket_order).map(s => s.bracket))] : []

    async function createMatch() {
        let match = {
            tourney: thistourney.id,
            bracket: "",
            ruleset: "",
            datetime: Date.now(),
            players: [],
            commentators: [],
            stream: "",
        }
        const r = await projectDatabase.ref('tourney/matches').push(match)
        history.push(`/tournaments/${id}/matches/${r.key}/edit`)
    }

    return (
        <Container sx={{ minHeight: '100vh', paddingBottom: 8 }}>
            <Stack direction='row' justifyContent='space-between'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                        style={{ textDecoration: 'none', color: "#666666" }}
                        color="inherit"
                        to="/"
                    >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                        style={{ textDecoration: 'none', color: "#666666" }}
                        color="inherit"
                        to="/tournaments/"
                    >
                        Tournaments
                    </Link>
                    {thistourney && <Select
                        labelId="boardselect"
                        id="demo-simple-select-helper"
                        variant="standard"
                        size='large'
                        defaultValue={thistourney?.id}
                        label='Tournament'
                        onChange={(e) => {
                            console.log(e.target.value)
                            history.push('/tournaments/' + tourneys.find(t => t.id == e.target.value).nickname.toLowerCase().replaceAll(" ", "_"))
                        }}
                    >
                        {tourneys.sort((a, b) => b.startdate - a.startdate).map(t => <MenuItem value={t.id}>{t.name}</MenuItem>)}
                    </Select>}
                </Breadcrumbs>
                <EditIcon />
            </Stack>
            <Grid container alignItems='flex-start' direction="row">
                <Grid item xs={12} md={8}>
                    <Stack spacing={1}>
                        <Title text={thistourney?.name.toUpperCase()} color='#ffffff' />
                        <Typography >{thistourney?.description}</Typography>

                        {thistourney?.featured && <Box><Typography variant='h6' mb={1} >Featured Content</Typography><VideoCarousel videos={thistourney?.featured} /></Box>}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Stack spacing={2}>
                        {thistourney && <Stack mt={2} alignItems='flex-start'>
                            {thistourney.challonge && Object.values(thistourney.challonge).filter(l => l).map((link, index) => <Button target="_blank" href={link} startIcon={<EmojiEventsIcon />}>Challonge</Button>)}
                            {thistourney.rules && <Button target="_blank" href={thistourney.rules} startIcon={<RuleIcon />}> Rules</Button>}
                            {thistourney.matcherino && <Button target="_blank" href={thistourney.matcherino} startIcon={<AttachMoneyIcon />}> Matcherino</Button>}
                            {thistourney.playlist && <Button target="_blank" href={thistourney.playlist} startIcon={<SubscriptionsIcon />}> Playlist</Button>}
                        </Stack>}
                        {thistourney?.organizers && <Box><Typography variant='h6' mb={1}>{`Organizers (${thistourney.organizers.length})`}</Typography>
                            <Grid container spacing={1}>
                                {thistourney?.organizers.map(u => (<Grid item><UserName user_id={u} discord={true} /></Grid>))}
                            </Grid>
                        </Box>}
                    </Stack>
                </Grid>
            </Grid>
            <Stack direction='row' alignItems='center' justifyContent='space-between' mt={4} spacing={2}>
                <Tabs variant="scrollable" value={Sheet} onChange={(e, value) => setSheet(value)}>
                    {stages.map(s => <Tab label={s} />)}
                </Tabs>
                <ToggleButtonGroup value={View} sx={{ maxHeight: '30px' }}>
                    <ToggleButton onClick={(e, value) => setView(value)} value={0}><SchemaIcon /></ToggleButton>
                    <ToggleButton onClick={(e, value) => setView(value)} value={1}><TableRowsIcon /></ToggleButton>
                </ToggleButtonGroup>
                {false && user && <Button onClick={() => createMatch()}>Create Match</Button>}
            </Stack>
            {View === 0 && <Bracket tourney={thistourney} bracket={stages?.[Sheet]} />}
            {View === 1 && <BracketSummary tourney={thistourney} bracket={stages?.[Sheet]} />}
        </Container>
    )

}







