import { keyframes } from '@mui/system';
import { useState } from 'react'
import { Stack, Typography, Tooltip, Table, TableHead, TableRow, TableCell, TableBody, Badge, IconButton, TextField, TableContainer } from "@mui/material"

import NotesIcon from '@mui/icons-material/Notes';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import RestoreIcon from '@mui/icons-material/Restore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import TimeDisplay from '../../../tools/TimeDisplay.js';
import RacerName from '../../../components/RacerName.js';


import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { getConditions, getTrack } from "../../../hooks/useTourneyRuns.js";
import UserName from '../../../tools/UserName.js';
import { timefix, timetoSeconds } from '../../../tools.js';
import RacerSelect from '../../../controls/RacerSelect.js';


export default function RaceTable({ race, TimeDiff, setTimeDiff, edit, reff }) {
    const conditions = getConditions(race)
    const blink = keyframes`
  0% { opacity: .5; }
  50% { opacity: .86; }
  100% { opacity: .5; }
`;
    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow >
                        <TableCell >Player</TableCell>
                        <TableCell >Pod</TableCell>
                        {edit && <TableCell>Deaths</TableCell>}
                        {Object.values(race.runs)[0].laps && Object.values(race.runs)[0].laps.map((lap, num) => { return <TableCell align='right'>{"Lap " + (num + 1)}</TableCell> })}
                        <TableCell align={edit ? 'left' : "right"} >Time<IconButton onClick={() => { setTimeDiff(!TimeDiff) }}>
                            {TimeDiff ? <AccessTimeIcon sx={{ fontSize: '18px' }} /> : <MoreTimeIcon sx={{ fontSize: '18px' }} />}
                        </IconButton></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {race?.runs && Object.values(race.runs).sort((a, b) => {
                        if (a.time == "DNF" && b.time == "DNF") {
                            return 0
                        } else if (a.time == "DNF") {
                            return 1
                        } else if (b.time == "DNF") {
                            return -1
                        } else {
                            return Number(a.time) - Number(b.time);
                        }
                    }).map((run, runindex) =>
                    (<TableRow key={run.player}>
                        <TableCell >
                            <Stack direction='row' justifyItems='center' alignItems='center' spacing={1}>
                                <UserName user_id={run.player} discord={true} />
                                {![undefined, null, '', 'pc'].includes(run.platform) && <img src={`/platforms/${run.platform}.svg`} width={12} />}
                                {runindex == 0 && <EmojiEventsIcon sx={{ fontSize: 15 }} />}
                            </Stack>
                        </TableCell>
                        <TableCell sx={{ minWidth: '50px', maxWidth: '75px' }} >
                            {edit ? <RacerSelect racer={run.pod} onChange={(e, v) => { reff.child(run.player).child('pod').set(e.target.value) }} /> : <RacerName racer={run.pod} />}
                        </TableCell>
                        {edit && <TableCell align="left" sx={{ maxWidth: '20px' }}><TextField
                            id="outlined-number"
                            type="number"
                            fullWidth
                            value={run.deaths}
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                let value = e.target.value < 0 ? "" : e.target.value
                                run.deaths = value
                                reff.child(run.player).update(run)
                            }}
                        /></TableCell>}
                        {run.laps && run.laps.map(lap =>
                            <TableCell align='right'>
                                <Stack direction='row' spacing={1} justifyContent='flex-end'>
                                    <Stack direction='row'>
                                        {![null, undefined, "", 0].includes(Number(lap.deaths)) && <img src={'/deaths.png'} width={14} height={14} />}
                                        {![null, undefined, "", 0, 1].includes(Number(lap.deaths)) &&
                                            <Typography variant='caption' color='text.secondary' sx={{ fontFamily: 'monospace', textDecoration: 'none' }}>
                                                {"×" + lap.deaths + " "}
                                            </Typography>}
                                    </Stack>
                                    <Typography variant='caption' sx={{ fontFamily: 'monospace', textDecoration: 'none', fontWeight: 700 }} color={lap.time == [...run.laps].sort((a, b) => {
                                        if (a.time == "DNF" && b.time == "DNF") {
                                            return 0
                                        } else if (a.time == "DNF") {
                                            return 1
                                        } else if (b.time == "DNF") {
                                            return -1
                                        } else {
                                            return Number(a.time) - Number(b.time);
                                        }
                                    })[0].time ? '#ffffff' : 'text.secondary'}>
                                        {['DNF', '', null, undefined].includes(lap.time) ? 'DNF' : timefix(lap.time).slice(-1) == "0" ? timefix(lap.time).slice(0, -1) : timefix(lap.time)}
                                    </Typography>
                                </Stack>
                            </TableCell>)}
                        {edit ? <TableCell align="left" sx={{ maxWidth: '50px' }}><TextField
                            required
                            fullWidth
                            id="outlined-required"
                            defaultValue={timefix(run.time)}
                            onBlur={(e) => {
                                if (String(e.target.value).toLowerCase() !== 'dnf') {
                                    run.time = timetoSeconds(e.target.value)
                                } else {
                                    run.time = e.target.value
                                }
                                reff.child(run.player).update(run)
                            }}
                            variant="standard"
                        /></TableCell> :
                            <TableCell align="right">
                                <Stack direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={1} sx={{ fontFamily: 'monospace', textDecoration: 'none', fontWeight: 700, animation: (run.pb && !run.formerpb) || (run.record && !run.formerrecord) ? `${blink} 1s ease-in-out infinite` : "" }}
                                    color={run.record && !run.formerrecord ? '#16E300' : run.pb && !run.formerpb ? '#FFEB3B' : run.time == 'DNF' || run.time > run.avg ? 'text.secondary' : '#FFFFFF'}
                                >
                                    {run.retroskip && !Object.values(conditions).includes("sk") && <Tooltip enterTouchDelay={0} placement="top" title={"Retroactively marked as a skip run"}><RestoreIcon sx={{ fontSize: 18 }} /></Tooltip>}
                                    {run.record && !run.formerrecord && <Tooltip enterTouchDelay={0} placement="top" title={"Tournament Record"}><StarIcon sx={{ fontSize: 18 }} /></Tooltip>}
                                    {run.record && run.formerrecord && <Tooltip enterTouchDelay={0} placement="top" title={"Former Tournament Record"}><StarBorderIcon sx={{ fontSize: 18 }} /></Tooltip>}
                                    {run.new && run.time !== 'DNF' && <Tooltip enterTouchDelay={0} placement="top" title={"First run of this kind for this player"}><AcUnitIcon sx={{ fontSize: 18 }} /></Tooltip>}
                                    {run.pb && <Tooltip enterTouchDelay={0} placement="top" title={(run.formerpb ? "Former " : "") + "Personal Best"}><WorkspacePremiumIcon sx={{ fontSize: 18 }} /></Tooltip>}
                                    {run.notes !== "" && <Tooltip enterTouchDelay={0} placement="top" title={run.notes}><NotesIcon sx={{ fontSize: 18 }} /></Tooltip>}
                                    {![null, undefined, "", 0].includes(Number(run.deaths)) && <Tooltip enterTouchDelay={0} placement="top" title={"Career Average: " + run.deathavg}><Badge badgeContent={run.deaths > 1 ? run.deaths : ""}><img src={'/deaths.png'} width={20} height={20} /></Badge></Tooltip>}
                                    <Tooltip placement="top" enterTouchDelay={0} title={run.avg ? "Career Average: " + timefix(run.avg) : ""}><TimeDisplay TimeDiff={TimeDiff} first={runindex == 0} time={(TimeDiff && runindex > 0 ? (run.time - Object.values(race.runs).sort((a, b) => {
                                        if (a.time == "DNF" && b.time == "DNF") {
                                            return 0
                                        } else if (a.time == "DNF") {
                                            return 1
                                        } else if (b.time == "DNF") {
                                            return -1
                                        } else {
                                            return Number(a.time) - Number(b.time);
                                        }
                                    }).map(run => run.time)[0]) : run.time)} /></Tooltip>
                                </Stack>
                            </TableCell>}
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}