import { Select, MenuItem } from "@mui/material"
import { useRacers } from "../hooks/useRacers"
import RacerName from "../components/RacerName"


export default function RacerSelect({ racer, mult, onChange }) {
    const racers = useRacers()
    return (<Select
        labelId="racerselect"
        value={racer}
        fullWidth
        multiple={mult}
        variant="standard"
        size='large'
        defaultValue={racer}
        onChange={(e, v) => {
            onChange(e, v)
        }}
    >
        {racers.map((r, i) => <MenuItem elevation={0} value={i}><RacerName racer={i} /></MenuItem>)}
    </Select>)
}