import { useState, useEffect } from 'react';
import { projectDatabase } from '../firebase/config';

export function usePlanets() {
  const [planets, setPlanets] = useState([]);

  useEffect(() => {
    const planetsRef = projectDatabase.ref('planets');
    planetsRef.once('value', (snapshot) => {
      const planetsData = snapshot.val();
      const planetsList = [];
      for (let id in planetsData) {
        planetsList.push({
          id: id,
          ...planetsData[id],
        });
      }
      setPlanets(planetsList);
    });
  }, []);

  return planets;
}