import React from 'react'
import { Typography, Stack } from '@mui/material';
import { racers } from '../data.js'
import UnformattedLink from './Link.js';
export default function RacerName({ racer, link }) {
    const Racer = (
        <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start'>
            <img src={`/flags/${racer}.png`} width={20} height={15} />
            <Typography variant='body2' sx={{ fontWeight: 500, display: { xs: 'none', md: 'block' } }}>
                {![null, undefined, ''].includes(racer) ? racers[racer].name : ""}
            </Typography>
        </Stack>
    )
    return (
        link ? (
            <UnformattedLink to={`/racers/${racer}`} >
                {Racer}
            </UnformattedLink >
        ) : (
            <div>
                {Racer}
            </div>
        )
    )
}
