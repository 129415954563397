import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAuthContext } from '../../../hooks/useAuthContext.js';
import { useCollection } from '../../../hooks/useCollection';
import { Button, Typography, Stack, Grid, Box, FormGroup, Switch, FormControlLabel, Autocomplete, TextField, Container } from '@mui/material';
import { Link } from 'react-router-dom'
import { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { useFirebase } from '../../../hooks/useFirebase';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
export default function Matches() {
    const matchCollection = useCollection('tourney/matches')
    const participantCollection = useCollection('tourney/participants')
    const rulesetCollection = useCollection('tourney/rulesets/saved')
    const tourneyCollection = useCollection('tourney/tournaments')
    const [ShowScore, setShowScore] = useState(false)
    const [FilterPlayers, setFilterPlayers] = useState([])
    const [FilterTourneys, setFilterTourneys] = useState([])
    const { authIsReady, user } = useAuthContext()
    const { addDocument, updateDocument, response } = useFirebase('tourney/matches/')
    const handleChange = (event) => {
        setShowScore(event.target.checked)
    }
    const changeFilter = (event, value) => {
        if (!Array.isArray(value)) {
            value = [value]
        }
        setFilterPlayers(value.map(v => v.id))
    }
    const changeTourney = (event, value) => {
        if (!Array.isArray(value)) {
            value = [value]
        }
        console.log(value)
        setFilterTourneys(value.map(v => v.id))
    }
    function getUsername(user) {
        let participants = participantCollection.documents
        let name = ""
        Object.values(participants).forEach(participant => {
            if (participant.id == user) {
                name = participant.name
                return
            }
        })
        return name
    }
    async function newMatch() {
        let match = {
            tourney: "",
            bracket: "",
            ruleset: "",
            datetime: Date.now(),
            players: [],
            commentators: [],
            stream: "",
        }
        const r = await addDocument(match).then(() => console.log(response))
        console.log(response, r)

    }
    let participants = participantCollection.documents
    let rows = []
    let matches = matchCollection.documents
    let tourneys = tourneyCollection.documents
    let rulesets = rulesetCollection.documents
    if (matches && tourneys && rulesets) {
        Object.keys(matches).forEach(key => {
            let match = matches[key]
            let row = {
                key: key, players: "-",
                tourney: match.tourney,
                datetime: match.datetime,
                commentators: match.commentators,
                races: match.races ? match.races.length : "", ruleset: match.ruleset,
                score: {},
                timediff: []
            }

            let date = new Date(row.datetime).toLocaleString()
            row.date = date.replace(":00", "")
            if (tourneys[match.tourney]?.stages[match.bracket]) {
                row.bracket = tourneys[match.tourney].stages[match.bracket].bracket + " " + tourneys[match.tourney].stages[match.bracket].round
            } else if (![undefined, null, ""].includes(match.bracket)) {
                row.bracket = row.bracket + " " + match.round
            }
            if (![null, undefined, ""].includes(match.tourney)) {
                row.tourney = tourneys[row.tourney]?.nickname + ": " + row.bracket
            }
            let players = match.players ? Object.values(match.players) : match.races ? Object.values(match.races[0].runs).map(run => run.player) : []
            if (players) {
                players.forEach(player => {
                    row.score[player] = 0
                })
            }

            row.winner = { score: 0, player: null }
            if (match.forfeit) {
                row.score[match.forfeit.player] = -1
                row.winner.player = players.filter(p => p !== match.forfeit.player)[0]
            }
            if (match.races) {
                Object.values(match.races).forEach(race => {
                    let winner = { player: null, time: null }
                    let times = Object.values(race.runs).map(run => run.time).filter(time => time !== 'DNF')
                    if (times.length > 1) {
                        row.timediff.push((Math.max(...times) - Math.min(...times)) / (times.length - 1))
                    }
                    Object.values(race.runs).forEach(run => {
                        if (run.time !== 'DNF' && (winner.time == null || run.time - winner.time < 0)) {
                            winner.player = run.player
                            winner.time = run.time
                        }
                    })
                    row.score[winner.player]++
                    if (row.score[winner.player] > row.winner.score) {
                        row.winner.score = row.score[winner.player]
                        row.winner.player = winner.player
                    }
                })
            }

            let commentators = []
            if (match.commentators) {
                Object.values(match.commentators).forEach(comm => {
                    if (!commentators.includes(comm)) {
                        commentators.push(comm)
                    }
                })
            }
            row.players = players
            row.commentators = commentators
            if (row.ruleset) {
                row.ruleset = rulesets[row.ruleset].general.name

            }
            let filterout = false
            FilterPlayers.forEach(p => {
                if (!players.includes(p)) {
                    filterout = true
                }
            })
            if (!filterout && (FilterTourneys.includes(String(match.tourney)) || FilterTourneys.length == 0)) {
                rows.push(row)
            }
        })
        rows = rows.sort(function (a, b) {
            return b.datetime - a.datetime
        })
    }

    return (
        <Container>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
            >
                {user && <Button onClick={newMatch}>
                    New Match
                </Button>}

                <Grid container spacing={1} mt={1} p={1} justifyContent="flex-end" alignItems='flex-end'>
                    <Grid item xs={12} md={5}>
                        {participants && <Autocomplete
                            size='small'
                            disablePortal
                            disableCloseOnSelect
                            multiple
                            onChange={changeFilter}
                            id="combo-box-demo"
                            options={Object.values(participants).sort((a, b) => a.name.localeCompare(b.name)).map(participant => { return { label: participant.name, id: participant.id } })}
                            renderInput={(params) => <TextField {...params} label="Players" />}
                        />}
                    </Grid>
                    <Grid item xs={12} md={5}>
                        {tourneys && <Autocomplete
                            size='small'
                            disablePortal
                            disableCloseOnSelect
                            multiple
                            onChange={changeTourney}
                            id="combo-box-demo"
                            options={Object.keys(tourneys).sort((a, b) => tourneys[a].name.localeCompare(tourneys[b].name)).map(key => { return { label: tourneys[key].name, id: key } })}

                            renderInput={(params) => <TextField {...params} label="Tourneys" />}
                        />}
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormGroup >
                            <FormControlLabel control={<Switch checked={ShowScore} onChange={handleChange} />} label="Show Scores" />
                        </FormGroup>
                    </Grid>
                </Grid>


            </Stack>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>

            </Box>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Date</TableCell>
                            <TableCell align="center">Players</TableCell>
                            <TableCell align="right">Avg. Time Diff.</TableCell>
                            <TableCell align="center">Commentators</TableCell>
                            <TableCell align="center">Tournament/Bracket</TableCell>
                            <TableCell align="center">Ruleset</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (

                            <TableRow
                                hover
                                component={Link}
                                to={`/tournaments/matches/${row.key}`}
                                key={row.key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'text-decoration': "none" }}
                            >
                                <TableCell align="center">
                                    <Stack>
                                        <Typography variant='body2' color='text.secondary'>{row.date.substring(0, row.date.indexOf(","))}</Typography>
                                        <Typography variant='body2' color='text.secondary'>{row.date.substring(row.date.indexOf(",") + 2, row.date.length)}</Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell align="center">

                                    {row.players.map(p => {
                                        return <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography color={(p == row.winner.player || !ShowScore ? "#FFFFFF" : "text.secondary")} sx={{ fontWeight: 700 }}>{getUsername(p)}</Typography>
                                            {ShowScore && <Typography textAlign='right' color={(p == row.winner.player ? "#FFFFFF" : "text.secondary")} sx={{ fontWeight: 700 }}>{row.score[p]}</Typography>}
                                        </Stack>

                                    })}
                                </TableCell>
                                <TableCell align="right">
                                    <Typography sx={{ fontWeight: 500 }}>{row.timediff.length > 1 ? "±" + (Math.abs(row.timediff.reduce((a, b) => a + b) / row.timediff.length).toFixed(3)) : "-"}</Typography>
                                </TableCell>
                                <TableCell align="center"><Stack
                                    direction="column"
                                    justifyContent="center"
                                    spacing={0}
                                >{row.commentators.map(c => { return <Typography variant='body2' color='text.secondary'>{getUsername(c)}</Typography> })}</Stack></TableCell>
                                <TableCell align="center"><Typography variant='button' color='text.secondary'>{row.tourney}</Typography></TableCell>
                                <TableCell align="center"><Typography variant='button' color='text.secondary'>{row.ruleset}</Typography></TableCell>
                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {[tourneys, matches, rulesets].includes(null) &&
                <Box sx={{
                    display: 'flex', alignItems: "center", height: 'calc(100vh - 90px)',
                    justifyContent: "center"
                }}>
                    <Stack spacing={2} alignItems='center'> <CircularProgress />
                        <Typography>Loading Matches...</Typography></Stack>

                </Box>
            }
        </Container>
    );
}


