import { createContext, useReducer, useEffect } from 'react'
import { projectAuth } from '../firebase/config'
import { useState } from 'react'
export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    // Function to set the authenticated user and access token
    const login = (userData, token) => {
        setUser(userData);
        setAccessToken(token);
    };

    // Function to clear the authenticated user and access token
    const logout = () => {
        setUser(null);
        setAccessToken(null);
        localStorage.removeItem("discord_token");
    };

    return (
        <AuthContext.Provider value={{ user, accessToken, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}