import React, { useState } from "react";
import { ButtonGroup, Button, ThemeProvider, createTheme, Grid, IconButton, Tooltip, Checkbox, Tabs, Tab, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ScienceIcon from '@mui/icons-material/Science';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';

export default function LeaderboardControls({ color, options, disabled, Conditions, setConditions, setTas, Tas, setQualifying, Qualifying }) {

    const customtheme = createTheme({
        components: {
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: color,
                        '&:hover': {
                            color: "#FFFFFF"
                        },
                        "&.Mui-checked": {
                            color: color
                        },
                        "&.Mui-selected": {
                            color: color,
                            borderColor: color,
                        },
                        "&.Mui-indicator": {
                            borderColor: color
                        }
                    }
                }
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        backgroundColor: color
                    }
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: color,
                        '&:hover': {
                            color: "#FFFFFF"
                        },
                        "&.Mui-checked": {
                            color: color
                        }
                    }
                }
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: color,
                        '&:hover': {
                            color: "#FFFFFF"
                        }
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: '#ffffff',
                        '&:hover': {
                            backgroundColor: color
                        }
                    },
                    outlined: {
                        borderColor: color,
                        color: color,
                        '&:hover': {
                            borderColor: color,
                            backgroundColor: '#ffffff'
                        },
                        '&:disabled': {
                            borderColor: '#333333',
                            color: '#333333'
                        }
                    },
                    contained: {
                        color: '#000000',
                        backgroundColor: color,
                        '&:hover': {
                            backgroundColor: '#ffffff',
                            color: color,
                        }
                    }
                }
            }
        }
    });
    const [showMore, setShowMore] = useState(false)

    const handleClick = (condition, value) => {
        if (condition === "upgrade") {
            setConditions({ ...Conditions, upgr: [Conditions.upgr?.[0] == 'mu' ? 'nu' : 'mu'] })
        } else if (condition === "trak") {
            if (value == 'sk') {
                setConditions({ ...Conditions, trak: ['fs', 'bg', 'ai'] });
            } else {
                setConditions({ ...Conditions, trak: [value] });
            }
        } else if (condition === "time") {
            setConditions({ ...Conditions, time: ['fl'], laps: ['l3'] });
        } else if (condition === "laps") {
            setConditions({ ...Conditions, time: ['tt'], laps: [value] })
        } else if (condition === 'mirr') {
            setConditions({ ...Conditions, mirr: [Conditions.mirr?.[0] == 'mi' ? 'um' : 'mi'] })
        } else if (condition === 'dire') {
            setConditions({ ...Conditions, dire: [Conditions.dire?.[0] == 'fw' ? 'bw' : 'fw'] })
        }
    };

    console.log(options, disabled)

    return (
        <ThemeProvider theme={customtheme}>
            <Grid container spacing={1} alignItems='center'>
                {![options.mu, options.nu].includes(true) && <Grid item xs={12} md>
                    <ButtonGroup size='small' fullWidth >
                        <Button variant={Conditions.upgr.includes('mu') ? 'contained' : 'outlined'} disabled={disabled.mu} id='mu' onClick={() => handleClick('upgrade', 'mu')}>Upgrades</Button>
                        <Button variant={Conditions.upgr.includes('nu') ? 'contained' : 'outlined'} disabled={disabled.nu} id='nu' onClick={() => handleClick('upgrade', 'nu')}>No Upgrades</Button>
                    </ButtonGroup>
                </Grid>}
                {[options.fs, options.bg, options.ai].includes(false) && <Grid item xs={12} md>
                    <ButtonGroup size='small' fullWidth  >
                        <Button variant={Conditions.trak.includes('ft') ? 'contained' : 'outlined'} id='ft' onClick={() => handleClick('trak', 'ft')}>Full Track</Button>
                        {!showMore && <Button variant={Conditions.trak.includes('fs') || Conditions.trak.includes('bg') || Conditions.trak.includes('ai') ? 'contained' : 'outlined'} disabled={![disabled.fs, disabled.bg, disabled.ai].includes(false)} id='fs' onClick={() => handleClick('trak', 'sk')}>Skips</Button>}
                        {showMore && !options.fs && <Button variant={Conditions.trak.includes('fs') ? 'contained' : 'outlined'} disabled={disabled.fs} id='fs' onClick={() => handleClick('trak', 'fs')}>Freestyle</Button>}
                        {showMore && !options.bg && <Button variant={Conditions.trak.includes('bg') ? 'contained' : 'outlined'} disabled={disabled.bg} id='bg' onClick={() => handleClick('trak', 'bg')}>Bounce Glitch</Button>}
                        {showMore && !options.ai && <Button variant={Conditions.trak.includes('ai') ? 'contained' : 'outlined'} disabled={disabled.ai} id='ai' onClick={() => handleClick('trak', 'ai')}>AI</Button>}
                    </ButtonGroup>
                </Grid>}
                {[options.l1, options.l2, options.l4, options.l5, options.fl].includes(false) && <Grid item xs={12} md>
                    <ButtonGroup size='small' fullWidth>
                        {showMore && !options.l1 && <Button variant={Conditions.laps.includes('l1') ? 'contained' : 'outlined'} disabled={disabled.l1} id='l1' onClick={() => handleClick('laps', 'l1')}>1</Button>}
                        {showMore && !options.l2 && <Button variant={Conditions.laps.includes('l2') ? 'contained' : 'outlined'} disabled={disabled.l2} id='l2' onClick={() => handleClick('laps', 'l2')}>2</Button>}
                        <Button variant={Conditions.laps.includes('l3') && !Conditions.time.includes('fl') ? 'contained' : 'outlined'} disabled={disabled.l3} id='tt' onClick={() => handleClick('laps', 'l3')}>{showMore ? "3" : "3 Lap"}</Button>
                        {showMore && !options.l4 && <Button variant={Conditions.laps.includes('l4') ? 'contained' : 'outlined'} disabled={disabled.l4} id='l4' onClick={() => handleClick('laps', 'l4')}>4</Button>}
                        {showMore && !options.l5 && <Button variant={Conditions.laps.includes('l5') ? 'contained' : 'outlined'} disabled={disabled.l5} id='l5' onClick={() => handleClick('laps', 'l5')}>5</Button>}
                        {!options.fl && <Button variant={Conditions.time.includes('fl') ? 'contained' : 'outlined'} disabled={disabled.fl} id='fl' onClick={() => handleClick('time', 'fl')}>Fast Lap</Button>}
                    </ButtonGroup>
                </Grid>}
                {showMore && ![options.mi].includes(true) && <Grid item xs={12} md>
                    <ButtonGroup size='small' fullWidth >
                        <Button size='small' disabled={disabled.um} variant={Conditions.mirr.includes('um') ? 'contained' : 'outlined'} value='um' onClick={() => handleClick('mirr', 'um')}>Unmirrored</Button>
                        <Button size='small' disabled={disabled.mi} variant={Conditions.mirr.includes('mi') ? 'contained' : 'outlined'} value='mi' onClick={() => handleClick('mirr', 'mi')}>Mirrored</Button>
                    </ButtonGroup></Grid>}
                {showMore && ![options.bw].includes(true) && <Grid item xs={12} md>
                    <ButtonGroup size='small' fullWidth >
                        <Button size='small' disabled={disabled.fw} variant={Conditions.dire.includes('fw') ? 'contained' : 'outlined'} value='fw' onClick={() => handleClick('dire', 'fw')}>Vanilla</Button>
                        <Button size='small' disabled={disabled.bw} variant={Conditions.dire.includes('bw') ? 'contained' : 'outlined'} value='bw' onClick={() => handleClick('dire', 'bw')}>Backwards</Button>
                    </ButtonGroup>
                </Grid>}
                {!options.ts && <Tooltip sx={{ p: '0', pl: '5px' }} enterTouchDelay={0} placement="top" title="Show TAS Runs"><Checkbox onClick={() => setTas(!Tas)} fontSize='large' icon={<ScienceOutlinedIcon fontSize='large' />} defaultChecked={Tas} checkedIcon={<ScienceIcon fontSize='large' />} /></Tooltip>}
                {!options.qu && <Tooltip sx={{ p: '0', pl: '5px' }} enterTouchDelay={0} placement="top" title="Show Qualifying Runs"><Checkbox onClick={() => setQualifying(!Qualifying)} fontSize='large' icon={<HourglassEmptyIcon fontSize='large' />} defaultChecked={Qualifying} checkedIcon={<HourglassFullIcon fontSize='large' />} /></Tooltip>}
                <IconButton sx={{ pl: '0', pt: '5px' }} onClick={(event, value) => {
                    if (showMore && Conditions.trak.length < 3 && Conditions.trak[0] !== 'ft') {
                        setConditions({ ...Conditions, trak: ['fs', 'bg', 'ai'] })
                    } else if (!showMore && Conditions.trak.length > 1) {
                        setConditions({ ...Conditions, trak: [Conditions.trak[0]] })
                    }
                    setShowMore(!showMore)
                }}>{showMore ? <RemoveIcon fontSize='large' /> : <AddIcon fontSize='large' />}</IconButton>
            </Grid >

        </ThemeProvider >
    );
}
