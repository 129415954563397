import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import UserName from '../tools/UserName';
import { getName } from '../components/UserName'
import { useUser } from '../hooks/useUser';
function UserPicker({ user_ids, setUsers }) {
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false)
    const { user } = useUser({ userId: user_ids, discord: true }) ?? []
    const users = [user].flat().map(u => ({ discordID: u?.discordID, name: u?.name })) ?? []

    const [value, setValue] = useState([]);
    if ((users.length && !value.length)) {
        setValue(users)

    }
    const [inputValue, setInputValue] = useState('');
    const handleSearchInputChange = (event, value) => {
        let newSearchTerm = value

        if (newSearchTerm) {
            newSearchTerm = newSearchTerm.toLowerCase().trim()
        }
        // Debounce the search query to avoid making too many requests
        const debounceTimeout = setTimeout(async () => {
            setLoading(true)
            // Search the Firebase database for users whose display names match the search term
            if (newSearchTerm && newSearchTerm.length >= 2) {
                const userRef = firebase.database().ref('users');
                let start = newSearchTerm.toUpperCase()
                let end = newSearchTerm.toLowerCase() + '\uf8ff'
                const userquery = userRef.orderByChild('name').startAt(start).endAt(end);

                const newSearchResults = [];
                await userquery.once('value', (snapshot) => {
                    snapshot.forEach((childSnapshot) => {
                        const user = childSnapshot.val();
                        user.key = childSnapshot.key;
                        user.link = `/players/${user.key}`
                        user.type = 'user'
                        if (user.discordID) {
                            newSearchResults.push({ discordID: user.discordID, name: getName(user) });
                        }
                    });
                });
                setLoading(false)
                setSearchResults(newSearchResults);
                console.log(newSearchTerm, start, end, newSearchResults)
            } else {
                setLoading(false)
            }

        }, 300);

        setInputValue(value)
        // Clear the debounce timeout on subsequent input changes
        return () => clearTimeout(debounceTimeout);
    };

    return (
        <Autocomplete sx={{ minWidth: '200px' }}
            multiple
            size="small"
            value={value}
            fullWidth
            onChange={(event, newValue) => {
                setValue(newValue);
                setUsers(newValue)
            }}
            inputValue={inputValue}
            onInputChange={handleSearchInputChange}
            options={[...searchResults, ...users.filter(u => !searchResults.map(s => s?.discordID).includes(u?.discordID))].flat() ?? []}
            getOptionLabel={(option) => option?.name}
            defaultValue={users}
            isOptionEqualToValue={(option, value) => option?.discordID === value?.discordID}
            // renderOption={(props, option) => (
            //     <MenuItem><UserName user_id={option} user_object={option} avatar={true} size={20} /></MenuItem>
            // )}
            renderInput={(params) => (
                <TextField
                    variant="standard"
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && searchResults ? <CircularProgress color="inherit" size={20} /> : null}
                                {null}</>
                        )
                    }}
                />
            )}
        />
    );
}

export default UserPicker;
