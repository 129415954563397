
import { Stack, FormControl, InputLabel, Select, MenuItem, TextField, Button, Paper, Typography, Snackbar, Alert } from "@mui/material"
import { projectDatabase } from "../../../firebase/config"
import UserName from "../../../tools/UserName"
import { useTournaments } from "../../../hooks/useTournaments"
import UserPicker from "../../../controls/UserPicker"
import { useState } from "react"

export default function MatchHeaderEdit({ editMatch, setEditMatch }) {
    const matchref = projectDatabase.ref(`tourney/matches/${editMatch?.id}`)
    const tourneys = useTournaments()
    function convertTime(time) {
        if (!time) {
            return time
        }
        let thisdate = new Date(Number(time))//.toISOString().slice(0, 16)
        let offset = thisdate.getTimezoneOffset() * 60 * 1000;
        thisdate = thisdate - offset
        thisdate = new Date(thisdate).toISOString().slice(0, 16)
        return thisdate
    }

    const setPlayers = (value) => {

        setEditMatch({ ...editMatch, players: value.filter(u => u.discordID).map(u => u.discordID) })
    }
    const setCommentators = (value) => {
        setEditMatch({ ...editMatch, commentators: value.filter(u => u.discordID).map(u => u.discordID) })
    }

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        let matchUpdate = { ...editMatch }
        if (matchUpdate.races) {
            Object.values(matchUpdate.races).forEach(race => {
                if (race.runs) {
                    Object.values(race.runs).forEach(run => {
                        delete run.avg
                        delete run.deathavg
                        delete run.new
                        delete run.record
                        delete run.pb
                    })
                }
            })
        }
        matchref.update(matchUpdate)
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Stack component={Paper} elevation={0} spacing={3} p={2}>
            <Typography>Edit Match</Typography>
            <FormControl >
                <Typography variant='caption' color='text.secondary'>Tournament</Typography>
                <Select
                    labelId="tourneyselect"
                    id="demo-simple-select-helper"
                    value={editMatch?.tourney}
                    variant="standard"
                    label='Tournament'
                    onChange={(e) => {
                        setEditMatch({ ...editMatch, tourney: e.target.value })
                    }}
                >
                    <MenuItem value="">
                        <em>-</em>
                    </MenuItem>
                    {tourneys && Object.keys(tourneys).map((t) => <MenuItem value={t}>{tourneys[t].name}</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl >
                <Typography variant='caption' color='text.secondary'>Bracket</Typography>
                <Select
                    labelId="bracket"
                    id="demo-simple-select-helper"
                    value={editMatch?.bracket}
                    variant="standard"
                    label='Bracket'
                    onChange={(e) => {
                        setEditMatch(
                            {
                                ...editMatch,
                                bracket: e.target.value ?? '',
                                ruleset: tourneys[String(editMatch.tourney)].stages[e.target.value]?.ruleset ?? '',
                                round: tourneys[String(editMatch.tourney)].stages[e.target.value]?.round ?? ''
                            })
                    }}
                >
                    <MenuItem value="">
                        <em>-</em>
                    </MenuItem>
                    {tourneys && editMatch && ![null, undefined, ""].includes(editMatch.tourney) && tourneys[String(editMatch?.tourney)]?.stages && Object.keys(tourneys[String(editMatch?.tourney)]?.stages).map((s) => <MenuItem value={s}>{tourneys[String(editMatch?.tourney)]?.stages[s]?.bracket + " " + tourneys[String(editMatch.tourney)]?.stages[s]?.round}</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl >
                <Typography variant='caption' color='text.secondary'>Players</Typography>
                <UserPicker user_ids={editMatch.players ? Object.values(editMatch.players) : []} setUsers={setPlayers} />
            </FormControl>
            <FormControl >
                <Typography variant='caption' color='text.secondary'>Commentators</Typography>
                <UserPicker user_ids={editMatch.commentators ? Object.values(editMatch.commentators) : []} setUsers={setCommentators} />
            </FormControl>
            <TextField

                id="datetime-local"
                type="datetime-local"
                value={convertTime(editMatch?.datetime)}
                label='Date & Time'
                variant='standard'
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => {
                    setEditMatch({ ...editMatch, datetime: new Date(e.target.value).getTime() })
                }}
            />
            <TextField
                defaultValue={editMatch?.vod}
                variant='standard'
                label='Vod Link'
                InputLabelProps={{
                    shrink: true,
                }}
                onBlur={(e) => {
                    setEditMatch({ ...editMatch, vod: e.target.value })
                }}
            />
            <Button>Set Forfeit</Button>
            <Button variant="contained" onClick={handleClick}>Update Match</Button>
            {false && <FormControl >
                <InputLabel id='playerselect'>Forfeiting Player</InputLabel>
                <Select
                    labelId="playerselect"
                    id="demo-simple-select-helper"
                    value={editMatch?.forfeit?.player ? editMatch?.forfeit?.player : []}
                    variant="standard"
                    label='Players'
                    onChange={(e) => {
                        if (!editMatch?.forfeit) {
                            editMatch.forfeit = {
                                player: "",
                                reason: ""
                            }
                        }
                        editMatch.forfeit.player = e.target.value
                        matchref.update(editMatch)
                    }}
                >
                    <MenuItem value="">
                        <em>-</em>
                    </MenuItem>
                    {editMatch?.players ? Object.values(editMatch.players).map((p) => <MenuItem value={p}><UserName user_id={p} discord={true} /></MenuItem>) : ""}
                </Select>
                <TextField
                    defaultValue={editMatch?.forfeit?.reason}
                    variant='standard'
                    label='Forfeit Reason'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => {
                        if (!editMatch.forfeit) {
                            editMatch.forfeit = {
                                player: "",
                                reason: ""
                            }
                        }
                        editMatch.forfeit.reason = e.target.value
                        matchref.update(editMatch)
                    }}
                /></FormControl>}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Match successfully updated!
                </Alert>
            </Snackbar>
        </Stack>
    )
}