import { React, useState, useEffect, Component } from "react"
import { useFirebase } from "../../hooks/useFirebase"
//import styles from './Home.module.css'
import { Stack, CardActions, Chip, ListSubheader, Box, ListItemSecondaryAction, ListItemAvatar, IconButton, List, ListItem, ListItemIcon, ListItemButton, ListItemText, Collapse, Accordion, AccordionSummary, AccordionDetails, Checkbox, MenuItem, InputLabel, Select, FormControl, TextField, Card, Grid, Button, ToggleButton, ToggleButtonGroup, Container, Paper, styled, Switch, FormGroup, FormControlLabel, Slider, Typography, CardContent } from '@mui/material';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ExpandLess from '@mui/icons-material/ExpandLess';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import RepeatIcon from '@mui/icons-material/Repeat';
import TokenIcon from '@mui/icons-material/Token';
import TuneIcon from '@mui/icons-material/Tune';
import StartIcon from '@mui/icons-material/Start';
import ClearIcon from '@mui/icons-material/Clear';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GavelIcon from '@mui/icons-material/Gavel';
import NavigationIcon from '@mui/icons-material/Navigation';
import { tracks } from "../../data.js";
import InboxIcon from "@mui/icons-material/Inbox";
import EditIcon from "@mui/icons-material/Edit";
import EventList from "./EventList";
import Event from "./Event";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { useFormik } from 'formik';



export default function RulesetMaker({ uid }) {
    const [name, setName] = useState('')
    const [amount, setAmount] = useState('')
    const { addDocument, response } = useFirebase('transactions')



    const handleSubmit = (e) => {
        e.preventDefault()
        addDocument({
            uid,
            name,
            amount
        })
    }

    const onDragEnd = (result) => {
        console.log(result)
    }

    // reset the form fields
    useEffect(() => {
        if (response.success) {
            setName('')
            setAmount('')
        }
    }, [response.success])

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            win_limit: 5,
            upgr: "mu",
            trak: "ft",
            mirr: "um",
            laps: "3l",
            time: "tt",
            gents: true,
            reveal: false,
            ranked: false,
            first_pri: "poe_c",
            first_sec: [],
            first_opt: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            events: [
                {
                    id: "test",
                    event: "Pod Ban",

                }
            ]
        },
        validate: (values) => {
            const errors = {};
            if (!values.email) {
                errors.email = 'Required';
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
                errors.email = 'Invalid email address';
            }
            if (values.first_sec.includes(values.first_pri)) {
                formik.setFieldValue('first_sec', []) //reset secondary options
            }

            return errors;
        },
        onSubmit: values => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    // handler for ToggleButtonGroup
    const handleConditionChange = (event, cond) => {
        // manually update formik
        if (["nu", "mu"].includes(cond)) {
            formik.setFieldValue('upgr', cond);
        } else if (["ft", "sk"].includes(cond)) {
            formik.setFieldValue('trak', cond);
        } else if (["um", "mi"].includes(cond)) {
            formik.setFieldValue('mirr', cond);
        } else if (["1l", "2l", "3l", "4l", "5l"].includes(cond)) {
            formik.setFieldValue('laps', cond);
        } else if (["tt", "fl"].includes(cond)) {
            formik.setFieldValue('time', cond);
        }
    };

    return (
        <Grid container>
            <Grid item xs={1} ></Grid>
            <Grid item xs={10} md={5}>
                <Container maxWidth>


                    <Typography variant="h4" gutterBottom component="div" mt={5}>
                        Ruleset Maker
                    </Typography>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="h6">General</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List dense={true}>
                                <ListItem>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        variant="filled"
                                        required
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        fullWidth
                                    />
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        id="description"
                                        label="Description"
                                        multiline
                                        variant="filled"
                                        rows={2}
                                        required={true}
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                        fullWidth
                                    />
                                </ListItem>
                            </List>

                            <List dense={true}  >
                                <ListItem disablePadding >
                                    <ListItemAvatar sx={{ minWidth: 30 }}>
                                        <EmojiEvents />
                                    </ListItemAvatar>

                                    <Typography component="div" variant="subtitle2">
                                        WIN LIMIT
                                    </Typography>

                                </ListItem>
                                <ListItem >
                                    <TextField fullWidth
                                        variant="filled"
                                        id="win_limit"
                                        label="Wins"
                                        type="number"
                                        onChange={formik.handleChange}
                                        value={formik.values.win_limit}
                                        inputProps={{ min: 1, max: 10 }}
                                    />
                                </ListItem>
                            </List>

                            <List dense={true}  >
                                <ListItem disablePadding >
                                    <ListItemAvatar sx={{ minWidth: 30 }}>
                                        <TuneIcon />
                                    </ListItemAvatar>

                                    <Typography component="div" variant="subtitle2">
                                        DEFAULT CONDITIONS
                                    </Typography>

                                </ListItem>
                                <ListItem >
                                    <ToggleButtonGroup
                                        size="small"
                                        color="primary"
                                        value={formik.values.upgr}
                                        name="default_upg"
                                        id="default_upg"
                                        exclusive
                                        fullWidth
                                        onChange={handleConditionChange}
                                    >
                                        <ToggleButton value="mu" >UPGRADES</ToggleButton>
                                        <ToggleButton value="nu">NO UPGRADES</ToggleButton>
                                    </ToggleButtonGroup>
                                </ListItem>
                                <ListItem>
                                    <ToggleButtonGroup
                                        size="small"
                                        color="primary"
                                        value={formik.values.trak}
                                        exclusive
                                        fullWidth
                                        onChange={handleConditionChange}
                                    >
                                        <ToggleButton value="ft">FULL TRACK</ToggleButton>
                                        <ToggleButton value="sk">SKIPS</ToggleButton>
                                    </ToggleButtonGroup>
                                </ListItem>
                                <ListItem>
                                    <ToggleButtonGroup
                                        size="small"
                                        color="primary"
                                        value={formik.values.mirr}
                                        exclusive
                                        fullWidth
                                        onChange={handleConditionChange}
                                    >
                                        <ToggleButton value="um">UNMIRRORED</ToggleButton>
                                        <ToggleButton value="mi">MIRRORED</ToggleButton>
                                    </ToggleButtonGroup>
                                </ListItem>
                                <ListItem>
                                    <ToggleButtonGroup
                                        size="small"
                                        color="primary"
                                        exclusive
                                        fullWidth
                                        onChange={handleConditionChange}
                                        value={formik.values.laps}
                                    >
                                        <ToggleButton value="1l">1 LAP</ToggleButton>
                                        <ToggleButton value="2l">2 LAPS</ToggleButton>
                                        <ToggleButton value="3l">3 LAPS</ToggleButton>
                                        <ToggleButton value="4l">4 LAPS</ToggleButton>
                                        <ToggleButton value="5l">5 LAPS</ToggleButton>
                                    </ToggleButtonGroup>
                                </ListItem>
                                <ListItem>
                                    <ToggleButtonGroup
                                        size="small"
                                        color="primary"
                                        exclusive
                                        fullWidth
                                        onChange={handleConditionChange}
                                        value={formik.values.time}
                                    >
                                        <ToggleButton value="tt">TOTAL TIME</ToggleButton>
                                        <ToggleButton value="fl">FASTEST LAP</ToggleButton>
                                    </ToggleButtonGroup>
                                </ListItem>
                                <ListItem>
                                    <ListItemText id="switch-list-label-wifi" primary="Gentlemen's Agreement" />
                                    <Switch
                                        edge="end"
                                        id="gents"
                                        name="gents"
                                        defaultChecked
                                        onChange={formik.handleChange}
                                        value={formik.values.gents}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText id="switch-list-label-wifi" primary="Reveal Racer Choice" />
                                    <Switch
                                        edge="end"
                                        id="reveal"
                                        name="reveal"
                                        onChange={formik.handleChange}
                                        value={formik.values.reveal}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText id="switch-list-label-wifi" primary="Affects Elo Rating" />
                                    <Switch
                                        edge="end"
                                        id="ranked"
                                        name="ranked"
                                        onChange={formik.handleChange}
                                        value={formik.values.ranked}
                                    />
                                </ListItem>
                            </List>

                            <List dense={true}  >
                                <ListItem disablePadding >
                                    <ListItemAvatar sx={{ minWidth: 30 }}>
                                        <StartIcon />
                                    </ListItemAvatar>

                                    <Typography component="div" variant="subtitle2">
                                        FIRST TRACK
                                    </Typography>

                                </ListItem>
                                <ListItem>
                                    <FormControl fullWidth variant="filled" >
                                        <InputLabel id="demo-simple-select-label">Primary Select Method</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="first_pri"
                                            name="first_pri"
                                            label="Primary Select Method"
                                            value={formik.values.first_pri}
                                            onChange={formik.handleChange}
                                        >
                                            <MenuItem value="poe_c">Process of Elimination by Circuit</MenuItem>
                                            <MenuItem value="poe_p">Process of Elimination by Planet</MenuItem>
                                            <MenuItem value="rp">Random Player</MenuItem>
                                            <MenuItem value="rn">Random</MenuItem>
                                            <MenuItem value="lw">Last Match Winner</MenuItem>
                                            <MenuItem value="ll">Last Match Loser</MenuItem>
                                        </Select>
                                    </FormControl>
                                </ListItem>
                                <ListItem>
                                    <FormControl fullWidth variant="filled" >
                                        <InputLabel id="demo-simple-select-label2">Secondary Select Methods</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label2"
                                            id="first_sec"
                                            name="first_sec"
                                            label="Secondary Select Method"
                                            value={formik.values.first_sec}
                                            onChange={formik.handleChange}
                                            multiple
                                        >
                                            {[
                                                {
                                                    name: "Process of Elimination by Circuit",
                                                    value: "poe_c"
                                                },
                                                {
                                                    name: "Process of Elimination by Planet",
                                                    value: "poe_p"
                                                },
                                                {
                                                    name: "Random Player",
                                                    value: "rp"
                                                },
                                                {
                                                    name: "Random",
                                                    value: "rn"
                                                },
                                                {
                                                    name: "Last Match Winner",
                                                    value: "lw"
                                                },
                                                {
                                                    name: "Last Match Loser",
                                                    value: "ll"
                                                }
                                            ].map((option) => {
                                                { return (formik.values.first_pri !== option.value && <MenuItem value={option.value}>{option.name}</MenuItem>) }
                                            })}
                                        </Select>
                                    </FormControl>
                                </ListItem>
                                <ListItem>
                                    <FormControl fullWidth variant="filled" sx={{ width: 600 }}>
                                        <InputLabel id="demo-simple-select-label">Options</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="first_opt"
                                            name="first_opt"
                                            label="Options"
                                            value={formik.values.first_opt}
                                            renderValue={(selected) => selected.map(select => { return (tracks[select].name) }).join(', ')}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            multiple
                                        >
                                            {tracks.map(track => {
                                                return (
                                                    <MenuItem key={track.tracknum - 1} value={track.tracknum - 1}>
                                                        <Checkbox checked={formik.values.first_opt.includes(track.tracknum - 1)} />
                                                        <ListItemText primary={track.name} />
                                                    </MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </ListItem>

                            </List>

                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6">Match Options</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List dense={true} disablePadding>
                                <ListItem disablePadding>
                                    <ListItemAvatar sx={{ minWidth: 30 }}>
                                        <TokenIcon />
                                    </ListItemAvatar>

                                    <Typography component="div" variant="subtitle2">
                                        FORCE POINTS
                                    </Typography>

                                </ListItem>
                                <ListItem>
                                    <Grid container spacing={2} >
                                        <Grid item xs={6}>
                                            <TextField fullWidth
                                                variant="filled"
                                                id="outlined-number"
                                                label="Starting Amount"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField fullWidth
                                                variant="filled"
                                                id="outlined-number"
                                                label="Max"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </List>

                            <List dense={true}>
                                <ListItem disablePadding secondaryAction={
                                    <Button startIcon={<PlaylistAddIcon />}>
                                        Add Ban
                                    </Button>
                                }>
                                    <ListItemAvatar sx={{ minWidth: 30 }}>

                                        <DoNotDisturbAltIcon />

                                    </ListItemAvatar>

                                    <Typography component="div" variant="subtitle2">
                                        PERMANENT BAN
                                    </Typography>

                                </ListItem>
                                <ListItem>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                                <Select

                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value="random"
                                                    label="Type"
                                                    fullWidth
                                                >
                                                    <MenuItem value={30}>Track</MenuItem>
                                                    <MenuItem value={30}>Racer</MenuItem>
                                                    <MenuItem value={10}>Condition</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel id="demo-simple-select-label">Choice</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value="random"
                                                    label="Choice"
                                                    fullWidth
                                                >
                                                    <MenuItem value={30}>First Race Winner</MenuItem>
                                                    <MenuItem value={30}>First Race Loser</MenuItem>
                                                    <MenuItem value={10}>Random</MenuItem>
                                                    <MenuItem value={20}>Random Player</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField fullWidth
                                                variant="standard"
                                                id="outlined-number"
                                                label="Cost"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField fullWidth
                                                variant="standard"
                                                id="outlined-number"
                                                label="Limit"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </List>

                            <List dense={true}>
                                <ListItem disablePadding secondaryAction={
                                    <Button startIcon={<PlaylistAddIcon />}>
                                        Add Rule
                                    </Button>
                                } >
                                    <ListItemAvatar sx={{ minWidth: 30 }}>

                                        <RepeatIcon />

                                    </ListItemAvatar>
                                    <Typography component="div" variant="subtitle2">
                                        REPEAT TRACK
                                    </Typography>

                                </ListItem>
                                <ListItem>
                                    <Grid container spacing={2} >
                                        <Grid item xs={6}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value="random"
                                                    label="Condition"
                                                    fullWidth
                                                >
                                                    <MenuItem value={30}>Unsalted</MenuItem>
                                                    <MenuItem value={30}>Salty Runback</MenuItem>
                                                    <MenuItem value={10}>Saltier Runback</MenuItem>
                                                    <MenuItem value={10}>Saltiest Runback</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel id="demo-simple-select-label">Choice</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value="random"
                                                    label="Choice"
                                                    fullWidth
                                                >
                                                    <MenuItem value={30}>First Race Winner</MenuItem>
                                                    <MenuItem value={30}>First Race Loser</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField fullWidth
                                                variant="standard"
                                                id="outlined-number"
                                                label="Cost"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField fullWidth
                                                variant="standard"
                                                id="outlined-number"
                                                label="Limit"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </List>



                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6">Race Options</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List dense={true} disablePadding >
                                <ListItem disablePadding secondaryAction={
                                    <Button startIcon={<PlaylistAddIcon />}>
                                        Add Rule
                                    </Button>
                                }>
                                    <ListItemAvatar sx={{ minWidth: 30 }}>
                                        <TokenIcon />
                                    </ListItemAvatar>

                                    <Typography component="div" variant="subtitle2">
                                        FORCE POINTS
                                    </Typography>

                                </ListItem>
                                <ListItem>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value="earn"
                                                    label="Type"
                                                    fullWidth
                                                >
                                                    <MenuItem value="earn">Earn</MenuItem>
                                                    <MenuItem value={30}>Lose</MenuItem>
                                                    <MenuItem value={30}>Refund</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} >

                                            <TextField fullWidth
                                                id="outlined-number"
                                                variant="standard"
                                                label="Number"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Event</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value="win"
                                                    label="Event"
                                                    fullWidth
                                                >
                                                    <MenuItem value="win">Every Win</MenuItem>
                                                    <MenuItem value={30}>Every Loss</MenuItem>
                                                    <MenuItem value={30}>Every Race</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </List>

                            <List dense={true} disablePadding >
                                <ListItem disablePadding secondaryAction={
                                    <Button startIcon={<PlaylistAddIcon />}>
                                        Add Ban
                                    </Button>
                                }>
                                    <ListItemAvatar sx={{ minWidth: 30 }}>
                                        <ClearIcon />
                                    </ListItemAvatar>

                                    <Typography component="div" variant="subtitle2">
                                        TEMPORARY BAN
                                    </Typography>

                                </ListItem>
                                <ListItem>
                                    <Grid container spacing={2} >
                                        <Grid item xs={6}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value="random"
                                                    label="Type"
                                                    fullWidth
                                                >
                                                    <MenuItem value={30}>Track</MenuItem>
                                                    <MenuItem value={30}>Racer</MenuItem>
                                                    <MenuItem value={10}>Condition</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel id="demo-simple-select-label">Choice</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value="random"
                                                    label="Choice"
                                                    fullWidth
                                                >
                                                    <MenuItem value={30}>Last Race Winner</MenuItem>
                                                    <MenuItem value={30}>Last Race Loser</MenuItem>
                                                    <MenuItem value={10}>Random</MenuItem>
                                                    <MenuItem value={20}>Random Player</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField fullWidth variant="standard"
                                                id="outlined-number"
                                                label="Cost"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField fullWidth variant="standard"
                                                id="outlined-number"
                                                label="Limit"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </List>



                            <List dense={true}  >
                                <ListItem disablePadding >
                                    <ListItemAvatar sx={{ minWidth: 30 }}>
                                        <LocationOnIcon />
                                    </ListItemAvatar>

                                    <Typography component="div" variant="subtitle2">
                                        TRACK SELECTION
                                    </Typography>

                                </ListItem>
                                <ListItem>
                                    <FormControl fullWidth variant="filled"  >
                                        <InputLabel id="demo-simple-select-label">Choice</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value="random"
                                            label="Choice"
                                            fullWidth
                                        >
                                            <MenuItem value={30}>Last Race Winner</MenuItem>
                                            <MenuItem value={30}>Last Race Loser</MenuItem>
                                            <MenuItem value={10}>Random Player</MenuItem>
                                            <MenuItem value={10}>Random</MenuItem>
                                        </Select>
                                    </FormControl>
                                </ListItem>

                            </List>

                            <List dense={true}  >
                                <ListItem disablePadding secondaryAction={
                                    <Button startIcon={<PlaylistAddIcon />}>
                                        Add Override
                                    </Button>
                                }>
                                    <ListItemAvatar sx={{ minWidth: 30 }}>
                                        <GavelIcon />
                                    </ListItemAvatar>

                                    <Typography component="div" variant="subtitle2">
                                        TRACK OVERRIDE
                                    </Typography>

                                </ListItem>
                                <ListItem>
                                    <Grid container spacing={2} >
                                        <Grid item xs={6}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel id="demo-simple-select-label">Choice</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value="random"
                                                    label="Choice"
                                                    fullWidth
                                                >
                                                    <MenuItem value={30}>Last Race Winner</MenuItem>
                                                    <MenuItem value={30}>Last Race Loser</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField fullWidth variant="standard"
                                                id="outlined-number"
                                                label="Cost"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                    </Grid>
                                </ListItem>
                            </List>


                            <List dense={true}  >
                                <ListItem disablePadding secondaryAction={
                                    <Button startIcon={<PlaylistAddIcon />}>
                                        Add Condition
                                    </Button>
                                }>
                                    <ListItemAvatar sx={{ minWidth: 30 }}>
                                        <TuneIcon />
                                    </ListItemAvatar>

                                    <Typography component="div" variant="subtitle2">
                                        CONDITIONS
                                    </Typography>

                                </ListItem>
                                <ListItem>
                                    <Grid container spacing={2}  >
                                        <Grid item xs={6}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel id="demo-simple-select-label">Options</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value="random"
                                                    label="Options"
                                                    fullWidth
                                                >
                                                    <MenuItem value={30}>Track</MenuItem>
                                                    <MenuItem value={30}>Racer</MenuItem>
                                                    <MenuItem value={10}>Condition</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel id="demo-simple-select-label">Choice</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value="random"
                                                    label="Choice"
                                                    fullWidth
                                                >
                                                    <MenuItem value={30}>Last Race Winner</MenuItem>
                                                    <MenuItem value={30}>Last Race Loser</MenuItem>
                                                    <MenuItem value={10}>Random</MenuItem>
                                                    <MenuItem value={20}>Random Player</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField fullWidth variant="standard"
                                                id="outlined-number"
                                                label="Cost"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField fullWidth variant="standard"
                                                id="outlined-number"
                                                label="Limit"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </List>

                            <List dense={true}  >
                                <ListItem disablePadding >
                                    <ListItemAvatar sx={{ minWidth: 30 }}>
                                        <NavigationIcon />
                                    </ListItemAvatar>

                                    <Typography component="div" variant="subtitle2">
                                        RACER SELECTION
                                    </Typography>

                                </ListItem>
                                <ListItem>
                                    <FormControl fullWidth variant="filled"  >
                                        <InputLabel id="demo-simple-select-label">Choice</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value="random"
                                            label="Choice"
                                            fullWidth
                                        >
                                            <MenuItem value={30}>Last Race Winner</MenuItem>
                                            <MenuItem value={30}>Last Race Loser</MenuItem>
                                            <MenuItem value={10}>Random Player</MenuItem>
                                            <MenuItem value={10}>Random</MenuItem>
                                        </Select>
                                    </FormControl>
                                </ListItem>
                            </List>




                            <List dense={true}  >
                                <ListItem disablePadding secondaryAction={
                                    <Button startIcon={<PlaylistAddIcon />}>
                                        Add Override
                                    </Button>
                                }>
                                    <ListItemAvatar sx={{ minWidth: 30 }}>
                                        <GavelIcon />
                                    </ListItemAvatar>

                                    <Typography component="div" variant="subtitle2">
                                        RACER OVERRIDE
                                    </Typography>

                                </ListItem>
                                <ListItem>
                                    <Grid container spacing={2} >
                                        <Grid item xs={6}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel id="demo-simple-select-label">Choice</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value="random"
                                                    label="Choice"
                                                    fullWidth
                                                >
                                                    <MenuItem value={30}>Last Race Winner</MenuItem>
                                                    <MenuItem value={30}>Last Race Loser</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField fullWidth variant="standard"
                                                id="outlined-number"
                                                label="Cost"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                    </Grid>
                                </ListItem>
                            </List>


                        </AccordionDetails>
                    </Accordion>
                </Container >

            </Grid>

            <Grid item xs={10} md={5}>
                <pre>{JSON.stringify(formik.values, null, 2)}</pre>
                <Card sx={{ minWidth: 275 }} variant="outlined">
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            1v1 Ruleset
                        </Typography>
                        <Typography variant="h5" component="div">
                            {formik.values.name}
                        </Typography>
                        <Typography variant="body2">
                            {formik.values.description}
                        </Typography>
                        <Typography component="div" variant="subtitle2" mt={2}>
                            GENERAL
                        </Typography>
                        <Typography variant="body2">
                            {`First to ${formik.values.win_limit} Wins (Best of ${formik.values.win_limit * 2 - 1})`}
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item>
                                {formik.values.upgr == "mu" ? <Chip label="Upgrades Allowed" /> : <Chip label="No Upgrades" />}
                            </Grid>
                            <Grid item>
                                {formik.values.trak == "ft" ? <Chip label="Full Track" /> : <Chip label="Skips" />}
                            </Grid>
                            <Grid item>
                                {formik.values.mirr == "um" ? <Chip label="Unmirrored" /> : <Chip label="Mirrored" />}
                            </Grid>
                            <Grid item>
                                {<Chip label={`${formik.values.laps.replace("l", "")} Laps`} />}
                            </Grid>
                            <Grid item>
                                {formik.values.time == "tt" ? <Chip label="Total Time" /> : <Chip label="Fast Lap" />}
                            </Grid>
                            {formik.values.gents && <Grid item><Chip label="Gentlemen's Agreement" /></Grid>}
                            {formik.values.reveal && <Grid item><Chip label="Reveal Racers" /></Grid>}
                            {formik.values.ranked && <Grid item><Chip label="Ranked" /></Grid>}

                            <Grid item xs={12}>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    
                                </DragDropContext>
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={1} ></Grid>
        </Grid>


    )
}

