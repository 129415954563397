exports.getTrack = function (run, tracks) {
    let track = run?.track ?? run?.src?.level ?? ""
    track = tracks.length ? tracks.find(t => t.name.toLowerCase() == track.toLowerCase())?.tracknum : null
    return track !== null ? track - 1 : null
}

exports.getRacer = function (run, racers) {
    let racer = run?.racer ?? run?.src?.values?.Character ?? ""
    racer = (racers.length && racer !== "" ? racers.find(r => r.name.toLowerCase() == racer.toLowerCase())?.racernum - 1 : null)
    return racer
}

exports.getUser = function (run, users) {
    let user = null
    if (run?.user) {
        user = Object.values(users).find(u => u?.id == run?.user)
    } else if (run?.src?.players?.data?.[0]?.id) {
        user = Object.values(users).find(u => u?.src?.id == run?.src?.players?.data?.[0]?.id)
    } else if (run?.src?.players?.data?.[0]?.name) {
        user = Object.values(users).find(u => u?.src?.name == run?.src?.players?.data?.[0]?.name)
    }
    let dups = {
        '-NI9AHgCCYX6zHnqHw38': '-N8PFNCzw_gnqRnzRdjl', //davey
        '-N8SNulaiT4iyp9aw93V': '-N8PFNNS3L3Q5mrLbKFJ'  //ace
    }
    return dups[user?.id] ? dups[user?.id] : user?.id
}

exports.getTime = function (run) {
    return run?.time ?? run?.src?.times?.primary_t
}

exports.getConditions = function (run) {
    return {
        game: 'fp',//fp, ng, ng+,
        laps: run.src?.category == "First Lap" ? "l1" : run.src?.category == "5 Lap" ? 'l5' : "l3",//l3, l2, l1
        mirr: 'um',//um, mi
        time: run.src?.category == "1 Lap" || run.cat == '1Lap' || run.src?.values?.Laps == '1 Lap' ? 'fl' : 'tt',//tt, fl
        trak: run.src?.values?.Skips == 'MFG Skips' || run.src?.values?.Skips == 'MFG/AI Skips' ? 'bg' : run.src?.values?.Skips == 'AI Skips' ? 'ai' : run.src?.values?.Skips == 'Skips' ? 'fs' : run.skips ? 'fs' : 'ft',//ft, sk
        upgr: run.upgrades ? 'mu' : run.src?.values?.Upgrades == "Upgrades" ? "mu" : 'nu',//mu, nu,,
        dire: run.src?.category == 'Backwards' ? 'bw' : 'fw'
    }
}

exports.compareConditions = function (confilter, con) {
    let filter = true
    con.forEach(c => {
        if (!Object.values(confilter).flat().includes(c) && !['fp'].includes(c)) {
            filter = false
        }
    })
    return filter
}

exports.getVideo = function (run) {
    return run.proof ?? run.src?.videos?.links?.[0]?.uri
}

exports.getPlatform = function (run) {
    return run.platform ?? run.src?.system?.platform ?? ""
}

exports.getVerified = function (run) {
    return run.legitimacy === false ? false : true
}

exports.cleanRun = function ({ run, tracks, racers, users }) {
    let user = module.exports.getUser(run, users)
    let track = module.exports.getTrack(run, tracks)
    let pod = module.exports.getRacer(run, racers)
    let cleanrun = {
        player: user,
        category: module.exports.getCategory(run) ?? "",
        track: track == null || Number.isNaN(track) ? "" : track,
        pod: pod == null || Number.isNaN(pod) ? "" : pod,
        time: module.exports.getTime(run),
        proof: module.exports.getVideo(run),
        notes: run?.src?.comment ?? run.comments?.src ?? run.comments?.cs,
        platform: module.exports.getPlatform(run),
        conditions: Object.values(module.exports.getConditions(run)),
        verified: exports.getVerified(run),
        tas: run.src?.category == 'TAS',
        id: run.id,
        src_id: run.src?.id,
        date: run.date ?? run.src?.date ?? ""
    }
    return cleanrun
}

exports.categories = [
    {
        name: '3 Lap',
        id: 0,
        nick: '3Lap'
    },
    {
        name: '1 Lap',
        id: 1,
        nick: '1Lap'
    },
    {
        name: 'Any%',
        id: 2,
        nick: 'Any%'
    },
    {
        name: '100%',
        id: 3
    },
    {
        name: 'Amateur Circuit',
        id: 4,
        nick: 'AMC'
    },
    {
        name: 'Semi-Pro Circuit',
        id: 5,
        nick: 'SPC'
    },
    {
        name: 'All Tracks NG+',
        id: 6,
        nick: 'NG+'
    },
    {
        name: 'All Tracks NG+, Track Favorites',
        id: 7,
        nick: 'NG+TF'
    },
    {
        name: 'All Tracks NG+, One Character',
        id: 8,
        nick: 'NG+OC'
    },
    {
        name: 'Any% VMU Manipulation',
        id: 9
    },
    {
        name: '2 Player',
        id: 10
    },
    {
        name: '5 Lap',
        id: 11
    },
    {
        name: 'First Lap',
        id: 12
    }
]

exports.getCategory = function (run) {
    let categories = exports.categories
    let cat = categories.find(c => [c.name.toLowerCase()].includes(run.src?.category.toLowerCase())) ?? categories.find(c => [c.name.toLowerCase()].includes(run.src?.values?.Run?.toLowerCase())) ?? categories.find(c => c.nick && c.nick.toLowerCase() == run.cat?.toLowerCase()) ?? (run.src?.category == 'TAS' ? categories.find(c => c.name == run.src?.values?.Laps) : (run.src?.category == 'Backwards' ? categories.find(c => c.name == run.src?.values?.Laps) : null))
    return cat?.id
}