const { avgSpeed, upgradeTopSpeed, upgradeCooling } = require("../../tools")
const { upgradeAcceleration, upgradeTraction, upgradeTurning, upgradeAirBrake, upgradeRepair } = require("../../tools")

exports.gamestats = function ({ DataTab, tracks, racers, Upgrades } = {}) {

    let rows = []
    let headers = []
    if (DataTab == 0) {
        tracks.forEach((track, i) => {
            rows.push({
                type: 'track',
                track: i,
                circuit: track.circuit,
                planet: track.planet,
                racer: track.favorite,
                length: Math.round(track.first_lap.length + 2 * track.lap.length),
                mi_length: Math.round((track.first_lap.length + 2 * track.lap.length) / 2640),
                km_length: Math.round((track.first_lap.length + 2 * track.lap.length) * 1.60934 / 2640),
                diff: ((25 - track.difficulty_rating) / 2.5).toFixed(1),
                ai_diff: track.difficulty,
                ai_spread: track.ai_spread
            })
        })
        headers = [
            { name: "Track", id: 'game', type: 'track' },
            { name: "Circuit", id: 'circuit', type: 'circuit', tip: 'the circuit in which this track is found' },
            { name: "Planet", id: 'planet', type: 'planet', tip: 'the planet where this track is located' },
            { name: "Favorite", id: 'fav', tip: 'The track favorite on this course', color: '#FFFFFF', type: 'racer' },
            [
                { name: "Length", id: 'length', tip: '3-lap race measured in in-game units', color: '#00FFFF', type: 'number' },
                { name: "Length", subtitle: '(miles)', id: 'mi_length', tip: 'where 1 in-game unit = 2 feet', color: '#00FFFF', type: 'number' },
                { name: "Length", subtitle: "(kilometers)", id: 'km_length', tip: 'where 1 in-game unit = 2 feet', color: '#00FFFF', type: 'number' }
            ],

            { name: "Difficulty", id: 'diff', tip: 'avg value from community poll ranking tracks by difficulty', color: '#FF0000', type: 'number' },
        ]
    } else if (DataTab == 1) {
        for (let i = 0; i < 23; i++) {
            let racer = racers[i]
            let row = {
                type: 'racer',
                racer: i,
                max_speed: (racer.max_speed + ((upgradeTopSpeed(racer.max_speed, Upgrades.max_speed.part) - racer.max_speed) * (Upgrades.max_speed.health / 255))).toFixed(0),
                acceleration: (racer.acceleration + ((upgradeAcceleration(racer.acceleration, Upgrades.acceleration.part) - racer.acceleration) * (Upgrades.acceleration.health / 255))).toFixed(2),
                max_turn_rate: racer.max_turn_rate,
                max_turn_rate_nd: (racer.max_turn_rate*1.25*0.68).toFixed(2),
                deceleration_interval: racer.deceleration_interval,
                boost_thrust: racer.boost_thrust,
                boost_ratio: ((100 / racer.heat_rate) / (100 / upgradeCooling(racer.cool_rate, Upgrades.cool_rate.part))).toFixed(2),
                heat_rate: racer.heat_rate.toFixed(1),
                antiskid: (racer.antiskid + ((upgradeTraction(racer.antiskid, Upgrades.antiskid.part) - racer.antiskid) * (Upgrades.antiskid.health / 255))).toFixed(2),
                turn_response: (racer.turn_response + ((upgradeTurning(racer.turn_response, Upgrades.turn_response.part) - racer.turn_response) * (Upgrades.turn_response.health / 255))).toFixed(0),
                air_brake_interval: (racer.air_brake_interval + ((upgradeAirBrake(racer.air_brake_interval, Upgrades.air_brake_interval.part) - racer.air_brake_interval) * (Upgrades.air_brake_interval.health / 255))).toFixed(0),
                cool_rate: (racer.cool_rate + ((upgradeCooling(racer.cool_rate, Upgrades.cool_rate.part) - racer.cool_rate) * (Upgrades.cool_rate.health / 255))).toFixed(0),
                repair_rate: (racer.repair_rate + ((upgradeRepair(racer.repair_rate, Upgrades.repair_rate.part) - racer.repair_rate) * (Upgrades.repair_rate.health / 255))).toFixed(2),
                hover_height: racer.hover_height.toFixed(2),
                bump_mass: racer.bump_mass,
                damage_immunity: racer.damage_immunity,
                isect_radius: racer.isect_radius
            }

            row.avg_speed = Math.round(avgSpeed(Number(row.max_speed), Number(row.boost_thrust), Number(row.heat_rate), Number(row.cool_rate)))
            row.boost_time = (100 / row.heat_rate).toFixed(1)
            row.cool_time = (100 / row.cool_rate).toFixed(1)
            rows.push(row)
        }
        headers = [
            { name: "Racer", id: 'game', type: 'racer' },
            [
                { name: "Traction", id: 'antiskid', tip: "how much a pod's forward vector influences its movement vector (smaller = looser)", color: 'boost', type: 'number', img: 'https://i.imgur.com/ReYPMbv.png' },
                { name: "Turning", id: 'turn_response', tip: 'how fast a pod gains rotational speed, or how fast it turns from 0 to its Max Turn Rate', color: 'boost', type: 'number', img: 'https://i.imgur.com/3gGX45S.png' },
                { name: "Max Turn Rate", subtitle: 'static value (°/s)', id: 'max_turn_rate', tip: "a pod's turning radius or how sharp it can turn", color: 'boost', type: 'number', img: 'https://i.imgur.com/CWOreJQ.png' },
                { name: "Max Turn Rate", subtitle: 'nose down (°/s)', id: 'max_turn_rate_nd', tip: "a pod's turning radius or how sharp it can turn when using nose down", color: 'boost', type: 'number', img: 'https://i.imgur.com/CWOreJQ.png' }
            ],
            [
                { name: "Acceleration", id: 'acceleration', tip: 'how fast a pod approaches its Top Speed when using throttle (smaller = faster accel)', color: 'boost', type: 'number', img: 'https://i.imgur.com/l7LsA2W.png' },
                { name: "Decel. Inv.", subtitle: 'static value', id: 'deceleration_interval', tip: 'how quickly a pod loses speed when not using throttle (smaller = faster decel)', color: 'boost', type: 'number', img: 'https://i.imgur.com/cQNveBV.png' },
                { name: "Air Brake", id: 'air_brake_interval', tip: 'how fast a pod loses speed when using brake (smaller = better brakes)', color: 'boost', type: 'number', img: 'https://i.imgur.com/S2X6GiY.png' }
            ],
            [
                { name: "Top Speed", id: 'max_speed', tip: 'the maximum speed a pod can reach without boosting', color: 'boost', type: 'number', img: 'https://i.imgur.com/egZamxH.png' },
                { name: "Boost Thrust", subtitle: 'static value', id: 'boost_thrust', tip: "the value added to a pod's Top Speed when boosting", color: 'boost', type: 'number', img: 'https://i.imgur.com/nd6PfMt.png' },
                { name: "Average Speed", subtitle: 'calculated value', id: 'avg_speed', tip: 'the maximum average speed achieveable assuming perfect heat', color: 'boost', type: 'number' }
            ],
            [
                { name: "Cooling", id: 'cool_rate', tip: 'how fast the engine temperature decreases (smaller = longer time to cool)', color: 'boost', type: 'number', img: 'https://i.imgur.com/kz3vYF2.png' },
                { name: "Heat Rate", subtitle: 'static value', id: 'heat_rate', tip: 'how fast the engine temperature increases when boosting (smaller = longer boost)', color: 'boost', type: 'number', img: 'https://i.imgur.com/iHxKsKU.png' },
                { name: "Boost Time", subtitle: '(seconds)', id: 'boost_time', tip: 'maximum duration of a boost', color: 'boost', type: 'number' },
                { name: "Cool Time", subtitle: '(seconds)', id: 'cool_time', tip: 'maximum time to completely cool engines', color: 'boost', type: 'number' },
                { name: "Boost Ratio", subtitle: 'boost/cool time', id: 'boost_ratio', tip: 'how long a pod can boost vs how long it takes to fully cool', color: 'boost', type: 'number' }
            ],
            [
                { name: "Repair", id: 'repair_rate', tip: "how fast a pod's engines repair when using the repair function", color: 'boost', type: 'number', img: 'https://i.imgur.com/lzPQm12.png' },
                { name: "Damage Immunity", subtitle: 'static value', id: 'damage_immunity', tip: "how fast a pod's engines are damaged", color: 'boost', type: 'number', img: 'https://i.imgur.com/J1iTz5t.png' }
            ],
            [
                { name: "Hover Height", subtitle: 'static value', id: 'hover_height', tip: 'how high a pod hovers off the ground', color: 'boost', type: 'number', img: 'https://i.imgur.com/F55pejx.png' },
                { name: "Bump Mass", subtitle: 'static value', id: 'bump_mass', tip: 'how much a pod is affected by collisions with AI opponents (higher is less responsive)', color: 'boost', type: 'number', img: 'https://i.imgur.com/YrQi6mk.png' },
                { name: "Isect Radius", subtitle: 'static value', id: 'isect_radius', tip: "the size of a pod's hitbox", color: 'boost', type: 'number', img: 'https://i.imgur.com/zY1DzVd.png' }
            ]
        ]
    } else if (DataTab == 2) {

    }
    return [headers, rows]
}