import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom'
const HeroBanner = ({ title, link, img, height, children }) => {
    return (
        <Link to={link} onClick={() => {
            window.scrollTo({
                top: 0,
            });
        }}>
            <Box
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    width: '100%',
                    height: height,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#000000',
                    transition: 'transform 0.5s ease, border 0.5s ease, box-shadow 0.5s ease',
                    '& img': {
                        transition: 'transform 0.5s ease, opacity 0.5s ease, border 0.5s ease, box-shadow 0.5s ease',
                        position: 'relative',
                        width: '100%',
                        opacity: '0.5'
                    },
                    '&:hover img': {
                        transform: 'scale(1.2)',
                        opacity: '0.2'
                    },
                    '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: 'inset 0 0 0 2px #ffffff'
                    }
                }}
            >
                <img sx={{ objectFit: 'cover', }} src={img} alt="Hero Banner" />
                <Typography variant="h2" style={{
                    whiteSpace: 'nowrap',
                    fontFamily: 'impact',
                    fontStyle: 'italic',
                    fontSize: {xs: '24px', md: '60px'},
                    background: `-webkit-linear-gradient(90deg, ${'#FFFFFF'} 0%, rgba(255, 0, 0, 0) 120%)`,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    pt: 4,
                    pb: 2,
                    zIndex: 3,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    transition: 'transform 0.3s ease-out',
                    '&:hover': {
                        transform: 'scale(0.9)',
                    },
                }}>{title}⠀</Typography>
                {children}
            </Box></Link>
    );
};

export default HeroBanner;