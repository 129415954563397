import { useState, useEffect } from 'react';
import { projectDatabase } from '../firebase/config';



export function useSpeedruns({ track } = {}) {
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    let runRef = projectDatabase.ref('speedruns');
    if (![null, undefined, ''].includes(track)) {
      runRef = projectDatabase.ref('speedruns').orderByChild('track').equalTo(track)
    }
    runRef.once('value', (snapshot) => {
      const runsData = snapshot.val();
      const runsList = [];
      for (let id in runsData) {
        runsList.push({
          id: id,
          ...runsData[id],
        });
      }
      setRuns(runsList);
      setLoading(false)
    });
  }, [track]);

  return { runs, loading };
}