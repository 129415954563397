import TimeDisplay from "../../tools/TimeDisplay";
import UserName from "../../tools/UserName";
import CoolCard from "./CoolCard"
import { useState, useEffect } from "react";
import firebase from 'firebase/app';
import { Stack } from "@mui/material";
import 'firebase/database';
import TrackName from "../../components/TrackName";
export default function RecentActivity() {
    const [activity, setActivity] = useState(null)
    useEffect(() => {
        const userquery = firebase.database().ref('speedruns').limitToLast(5);
        userquery.once('value', (snapshot) => {
            let val = snapshot.val()
            setActivity(val ? Object.values(snapshot.val()) : null)
        });
    }, []);
    return (
        <CoolCard title='RECENT ACTIVITY' color='#FFFF00'>
            {activity && activity.map(run => (
                <Stack direction='row' alignItems = 'center' spacing={2}><UserName user_id={run.player} /><TimeDisplay time={run.time} /><TrackName track = {run.track}/></Stack>
            ))}
        </CoolCard>
    )
}