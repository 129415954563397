import { Typography, Grid, Card, Box, Stack, Avatar, CardContent, CardMedia, CardActionArea, Container, CardHeader, Breadcrumbs, Link } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { useRacers } from '../../hooks/useRacers.js';
import TrackTitle from '../../tools/TrackTitle.js';
import { usePlanets } from '../../hooks/usePlanets';
import { useTracks } from '../../hooks/useTracks';
import Title from '../../tools/Title.js'
export default function Racers() {
    const racers = useRacers()
    const tracks = useTracks();
    const planets = usePlanets()
    return (
        <Container>
            {[racers].includes(null) &&
                <Box sx={{
                    display: 'flex', alignItems: "center", height: 'calc(100vh - 90px)',
                    justifyContent: "center"
                }}>
                    <CircularProgress />
                </Box>
            }
            {racers && <Box sx={{ width: '100%', minHeight: '100vh' }}>
                
                <Title text="RACERS" color="#FFFFFF" />
                <Typography variant='subtitle2'>Much like their Podracers, pilots come in every shape and size. But no matter which corner of the galaxy they hail from, all drivers share one common passion -- a love of danger. The collection of drivers you see revving their engines at the starting line will undoubtedly be the most oclorful bunch of rapscallions and scallywags ever assembled. Which Podracer and pilot will you hitch your chances for fame and fortune to?</Typography>
                <Grid mt={1}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                >
                    {Object.values(racers).map((racer, index) => {
                        return index < 23 && <Grid item lg={6} xs={12}>
                            <Card elevation={0}>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ width: 50, height: 40 }} variant='square' src={index < 23 ? `/flags/${index}.png` : ""} aria-label="recipe" />
                                    }
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}
                                    title={<Typography variant="h6" style={{ fontSize: 20, fontFamily: 'Roboto' }}>
                                        {racer.name}
                                    </Typography>}
                                    subheader={racer.pod}
                                />
                                {false && <CardMedia
                                    component="img"
                                    height="194"
                                    image={`/racer_portraits/${index}.png`}
                                />}
                            </Card>
                        </Grid>
                    })}
                </Grid>



            </Box>}

        </Container>
    )
}
