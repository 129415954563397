import { useEffect, useContext } from "react";
import axios from "axios";
import fetch from 'node-fetch';
import { Box, Typography } from "@mui/material";
import { projectDatabase, projectAuth } from '../firebase/config'
import { useLogin } from "../hooks/useLogin";
import { AuthContext } from "../context/AuthContext";
const CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_DISCORD_CLIENT_SECRET;
const REDIRECT_URI = "https://www.bottosjunkyard.com/login";
const DISCORD_API_BASE_URL = "https://discord.com/api/v10";
const qs = require('querystring')

const DiscordLogin = () => {
    const { login } = useContext(AuthContext);
    useEffect(async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get("code");

        if (code) {
            const data = {
                client_id: String(CLIENT_ID),
                client_secret: String(CLIENT_SECRET),
                grant_type: 'authorization_code',
                code: String(code),
                redirect_uri: String(REDIRECT_URI),
            }
            const response = await fetch("https://discordapp.com/api/oauth2/token", {
                method: 'POST',
                body: new URLSearchParams(
                    {
                        'client_id': String(CLIENT_ID),
                        'client_secret': String(CLIENT_SECRET),
                        'code': code,
                        'grant_type': 'authorization_code',
                        'redirect_uri': REDIRECT_URI
                    }),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).catch((error) => {
                console.error(error);
                window.location.href = "/login";
            });
            const json = await response.json();
            console.log(json)
            const access_token = json.access_token;
            const token_type = json.token_type;
            const expires_in = json.expires_in;
            const userResponse = await axios.get('https://discord.com/api/users/@me', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });

            const userId = userResponse.data;

            login(userId, access_token)

            //https://cdn.discordapp.com/avatars/256236315144749059/fa15c7e3e0219d443ffb1b097d30d7a2.png
            //https://cdn.discordapp.com/banners/256236315144749059/2f5082a6a09acd0905d98f998ed8e6ef.png?size=640
            // accent_color: 1343679
            // avatar: "fa15c7e3e0219d443ffb1b097d30d7a2"
            // avatar_decoration: null
            // banner: "2f5082a6a09acd0905d98f998ed8e6ef"
            // banner_color: "#cd0707"
            // discriminator: "5872"
            // display_name: null
            // flags: 4194304
            // global_name: null
            // id: "256236315144749059"
            // locale: "en-US"
            // mfa_enabled: true
            // premium_type: 2
            // public_flags: 4194304
            // username: "LightningPirate"
            // Store the token in local storage or cookies
            localStorage.setItem("discord_token", JSON.stringify(userId));
            // Redirect the user to the homepage
            //window.location.href = "/";
        } else {
            // If we don't have a code, redirect the user to Discord
            const url = `https://discord.com/api/oauth2/authorize?client_id=545798436105224203&redirect_uri=https%3A%2F%2Fwww.bottosjunkyard.com%2Flogin&response_type=code&scope=identify`;
            window.location.href = url;
        }
    }, []);

    return <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: '100vh' }} height = '100%'>
        <Typography variant='h6'>Logging you in with Discord...</Typography>
    </Box>
};

export default DiscordLogin;