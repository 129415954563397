import { Card, CardContent, Paper, Button, Stack, Typography, CardActionArea } from "@mui/material"

export default function CoolCard(props) {
    return (
        <Card elevation={0} sx={{ borderRadius: '0px', background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, ${props.color} 1000%)` }}>
            <Paper elevation={0} sx={{ padding: '15px', borderRadius: '0px', borderBottom: `1px solid ${props.color}` }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>

                    <Typography variant='h8' sx={{ opacity: '80%' }}>{props.title}</Typography>

                    <Typography fontFamily='Auraboo' sx={{ opacity: '20%' }}>{props.title}</Typography>

                </Stack>
            </Paper>
            <CardContent >
                <Stack spacing={1}>

                    {props.children}
                </Stack>
            </CardContent>
        </Card>
    )
}
