import { Link, useLocation } from 'react-router-dom'
import { useAuthContext } from "../hooks/useAuthContext"
import { AuthContext } from "../context/AuthContext";
import { Box, Toolbar, Button, Typography, Menu, MenuItem, Container, Avatar, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import SearchBar from './SearchBar';
import { useHistory } from 'react-router-dom'
import { useContext } from 'react';

import * as React from 'react';
export default function Navbar() {
  const location = useLocation();
  const { logout } = useContext(AuthContext);
  const { user } = useAuthContext()
  const pages = { 'Databank': '/databank', 'Leaderboards': '/leaderboards', 'Rankings': '/rankings/tournaments', 'Stats': '/stats/game', 'Tournaments': '/tournaments/' }; //, 'Statistics', 'Rulesets'
  const settings = ['Logout']; //'Profile', 'Account', 

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const history = useHistory()
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (e) => {
    if (e.target.innerHTML == 'Logout') {
      logout()
    }
    setAnchorElUser(null);
  };

  const isActiveLink = (path) => {
    return location.pathname.split("/")[1] === path.split("/")[1];
  };

  return (
    <Container maxWidth="lg">
      <Toolbar disableGutters>
        <Typography
          variant="h4"
          noWrap
          component={Link}
          to="/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'impact',
            letterSpacing: '.1rem',
            color: 'inherit',
            fontStyle: 'italic',
            textDecoration: 'none'
          }}
        >
          JUNKYARD⠀
        </Typography>

        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            sx = {{ padding: '8px'}}
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {Object.keys(pages).map((key) => (
              <MenuItem component={Button} color='primary' key={key} onClick={(e, value) => {
                setAnchorElNav(null);
                history.push(pages[key].toLowerCase())
              }}>
                <Typography textAlign="center">{key}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {Object.keys(pages).map((key) => (
            <Button
              key={key}
              onClick={(e, value) => history.push(pages[key].toLowerCase())}
              sx={{
                my: 2,
                color: isActiveLink(pages[key].toLowerCase()) ? 'white' : '#777777',
                display: 'block',
                fontFamily: 'verdana',
                fontWeight: 1000
              }}
            >
              {key}
            </Button>
          ))}
        </Box>
        <SearchBar />
        {user && <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt={user.displayName} src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png?size=640`} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>}
        {!user && (
          <Link to='/login' style={{ textDecoration: 'none' }}><Button>Login</Button></Link>
        )}
      </Toolbar>
    </Container>
  )
}
