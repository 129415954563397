import React from 'react'
import { Paper, IconButton, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener';

export default function RunFilter({ Conditions, setConditions }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);

    const handleLapClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleSkipClick = (event) => {
        setAnchorEl2(anchorEl2 ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const id = open ? 'simple-popper' : undefined;
    const id2 = open2 ? 'simple-popper' : undefined;

    const handleTrackCondition = (event, newValue) => {
        if (newValue) {
            let obj = {...Conditions}
            obj.trak = [newValue].flat()
            setConditions(obj)
        }
    }
    const handleSkipCondition = (event, newValue) => {
        if (newValue) {
            let obj = {...Conditions}
            obj.skip = [newValue].flat()
            setConditions(obj)
        }
    }
    const handleUpgradeCondition = (event, newValue) => {
        if (newValue) {
            let obj = {...Conditions}
            obj.upgr = [newValue].flat()
            setConditions(obj)
        }
    }
    const handleTimeCondition = (event, newValue) => {
        if (newValue) {
            let obj = {...Conditions}
            obj.time = [newValue].flat()
            setConditions(obj)
        }
    }
    const handleDirectionCondition = (event, newValue) => {
        if (newValue) {
            let obj = {...Conditions}
            obj.dire = [newValue].flat()
            setConditions(obj)
        }
    }

    const handleLapCondition = (event, newValue) => {
        if (newValue) {
            let obj = {...Conditions}
            obj.laps = [newValue].flat()
            setConditions(obj)
            handleLapClick()
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
                <ToggleButtonGroup value={Conditions.time} size='small' fullWidth aria-label="outlined primary button group" onChange={handleTimeCondition} exclusive p={0}>
                    {[{ id: 'tt', name: Conditions.laps[0].replace("l", "") + " Lap" + (Conditions.laps[0] == 1 ? "" : "s") }, { id: 'fl', name: 'Fast Lap' }].map(con => {
                        return <ToggleButton sx={{ fontWeight: 1000 }} value={con.id} id={con.id}>
                            {con.name}{con.id == 'tt' && <IconButton onClick={handleLapClick} sx={{ minHeight: 0, minWidth: 0, padding: 0 }}><ArrowDropDownIcon /><Popper id={id} open={open} anchorEl={anchorEl}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleLapClick}>
                                        <ToggleButtonGroup value={Conditions.laps} size='small' fullWidth aria-label="outlined primary button group" onChange={handleLapCondition} exclusive >
                                            {[{ id: '1l', name: '1 Lap' }, { id: '2l', name: '2 Laps' }, { id: '3l', name: '3 Laps' }, { id: '4l', name: '4 Laps' }, { id: '5l', name: '5 Laps' }].map(con => {
                                                return <ToggleButton sx={{ fontWeight: 1000 }} value={con.id} id={con.id}>
                                                    {con.name}
                                                </ToggleButton>
                                            })}
                                        </ToggleButtonGroup>
                                    </ClickAwayListener>
                                </Paper>
                            </Popper></IconButton>}
                        </ToggleButton>
                    })}
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={6}>
                <ToggleButtonGroup value={Conditions.dire} size='small' fullWidth aria-label="outlined primary button group" onChange={handleDirectionCondition} exclusive>
                    {[{ id: 'fw', name: 'Forwards' }, { id: 'bw', name: 'Backwards' }].map(con => {
                        return <ToggleButton sx={{ fontWeight: 1000 }} value={con.id} id={con.id}>
                            {con.name}
                        </ToggleButton>
                    })}
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={6}>
                <ToggleButtonGroup value={Conditions.trak} size='small' fullWidth aria-label="outlined primary button group" onChange={handleTrackCondition} exclusive>
                    {[{ id: 'ft', name: 'Full Track' }, { id: 'sk', name: 'Skips' }].map(con => {
                        return <ToggleButton sx={{ fontWeight: 1000 }} value={con.id} id={con.id}>
                            {con.name}{con.id == 'sk' && <div>
                                <IconButton onClick={handleSkipClick} sx={{ minHeight: 0, minWidth: 0, padding: 0 }}><ArrowDropDownIcon /></IconButton>
                                <Popper id={id2} open={open2} anchorEl={anchorEl2}>

                                    <Paper>
                                        <ClickAwayListener onClickAway={handleSkipClick}>
                                            <ToggleButtonGroup value={Conditions.skip} size='small' fullWidth aria-label="outlined primary button group" onChange={handleSkipCondition} >
                                                {[{ id: 'fs', name: 'Freestyle' }, { id: 'ai', name: 'AI' }, { id: 'bg', name: 'Bounce Glitch' }].map(con => {
                                                    return <ToggleButton sx={{ fontWeight: 1000 }} value={con.id} id={con.id}>
                                                        {con.name}
                                                    </ToggleButton>
                                                })}
                                            </ToggleButtonGroup>
                                        </ClickAwayListener>
                                    </Paper>

                                </Popper>
                            </div>}
                        </ToggleButton>
                    })}
                </ToggleButtonGroup>
            </Grid>

            <Grid item xs={12} md={6}>
                <ToggleButtonGroup value={Conditions.upgr} size='small' fullWidth aria-label="outlined primary button group" onChange={handleUpgradeCondition} exclusive>
                    {[{ id: 'mu', name: 'Upgrades' }, { id: 'nu', name: 'No Upgrades' }].map(con => {
                        return <ToggleButton sx={{ fontWeight: 1000 }} value={con.id} id={con.id}>
                            {con.name}
                        </ToggleButton>
                    })}
                </ToggleButtonGroup>
            </Grid>


        </Grid>
    )
}
