const { timefix } = require("../tools")


export function getConditions (ruleset, race, racenum) {
    let conditions = {}
    if (ruleset !== 'practice' && ruleset?.general?.type == '1v1') {
        conditions = { ...ruleset.general.default }
        Object.values(race.events).filter(e => e.event == 'override' && e.type == 'condition').forEach(event => {
            if (event.selection == 'nu') {
                conditions.upgr = 'nu'
            }
            if (event.selection == 'fl') {
                conditions.time = 'fl'
            }
            if (event.selection == 'sk') {
                conditions.trak = 'fs'
            }
        })
    } else {
        conditions = { ...ruleset?.playlist?.[racenum]?.conditions }
    }
    return conditions
}

export function getTrack(race) {
    if (race?.track) {
        return race.track
    } else if (race?.events) {
        return Object.values(race.events).find(event => event.event == 'selection' && event.type == 'track')?.selection ?? ""
    } else {
        return null
    }
}

export function getWinner(race) {
    let winner = { time: null, player: null }
    Object.values(race.runs).forEach(run => {
        if (winner.time && run.time !== 'DNF' && run.time - winner.time < 0) {
            winner = run
        } else if (!winner.time && !['DNF', ''].includes(run.time)) {
            winner = run
        }
    })
    return winner
}

export function getPlayers(match) {
    return match?.players ? Object.values(match.players) : match.races ? Object.values(match.races[0].runs).map(run => run.player) : []
}

export function getScore(match, byTime) {
    let scores = {}
    let players = getPlayers(match)
    players.map(p => {
        scores[p] = 0
    })
    if (match.races) {
        Object.values(match.races).forEach(race => {
            if (!byTime) {
                let winner = getWinner(race)
                scores[winner.player]++
            } else {
                Object.values(race.runs).forEach(run => {
                    if (![null, undefined, 'DNF', ''].includes(run.time) && scores[run.player] !== undefined) {
                        scores[run.player] += Number(run.time)
                    }
                })
            }

        })
    }
    if (match.forfeit) {
        scores[match.forfeit.player] = -1
    }
    return scores
}

export function getMatchWinner(match) {
    let score = getScore(match)
    return Object.keys(score).sort((a, b) => score[b] - score[a])[0]
}


export function getSummary(match) {
    let summary = {}
    let players = getPlayers(match)
    players.map(p => {
        summary[p] = { matches: 1, races: 0, time: 0, deaths: 0, fp: 0, rb: 0, score: getScore(match)[p] }
    })
    if (match.races) {
        Object.values(match.races).forEach(race => {
            if (race.events) {
                Object.values(race.events).forEach(event => {
                    if (summary[event.player]) {
                        if (event.cost) {
                            summary[event.player].fp += Number(event.cost)
                        }
                        if (event.runback) {
                            summary[event.player].rb += 1
                        }
                    }
                })
            }
            Object.values(race.runs).forEach(run => {
                if (summary[run.player]) {
                    summary[run.player].races += 1
                    if (![undefined, null, '', 'DNF'].includes(run.time)) {
                        summary[run.player].time += run.time ? Number(run.time) : 0
                    }
                    if (![undefined, null, ''].includes(run.deaths)) {
                        summary[run.player].deaths += run.deaths ? Number(run.deaths) : 0
                    }
                }
            })
        })
    }
    return summary
}

export function getMatchDesc({ match, tournament, short }) {
    let replace = {
        'Winners': 'W',
        'Losers': 'L',
        'Quarters': 'Q',
        'Semis': 'S',
        'Round 1': 'R1',
        'Round 2': 'R2',
        'Round 3': 'R3',
        'Grand Finals': 'GF',
        'Qualifiers': 'Quals',
        'Single Elim': 'SE',
        'Double Elim': 'DE'
    }
    function replaceFunction(string) {
        return string
        if (['', null, undefined].includes(string)) {
            return string
        }
        Object.keys(replace).forEach(r => {
            console.log(string, r, string.includes(r))
            string = string.replaceAll(r, replace[r])
        })
        console.log('return', string)
        return string
    }
    if (short) {
        return [tournament?.nickname, replaceFunction(tournament?.stages?.[match.bracket]?.bracket), replaceFunction(tournament?.stages?.[match.bracket]?.round)].join(" ")
    } else {
        return [tournament?.name, tournament?.stages?.[match.bracket]?.bracket, tournament?.stages?.[match.bracket]?.round].join(" ")
    }

}

export function tourneyboard({ matches, rulesets, track, tournaments } = {}) {
    let runs = []
    if (matches && rulesets?.length) {
        matches.sort((a, b) => a.datetime - b.datetime).forEach(match => {
            let ruleset = rulesets.find((ruleset) => ruleset.id == match.ruleset) ?? 'practice'
            match.key = match.id
            if (match.races) {
                Object.values(match.races).forEach((race, racenum) => {
                    let thistrack = getTrack(race)
                    Object.values(race.runs).forEach(run => {
                        let runconditions = { ...getConditions(ruleset, race, racenum) }
                        if (run.retroskip) {
                            runconditions.trak = 'fs'
                        }
                        run.num = racenum + 1
                        run.vod = match.vod
                        run.tourney = tournaments.find(t => t.id == match.tourney)
                        run.bracket = match.bracket
                        run.round = match.round
                        run.qual = ruleset.general.type == 'Qualifier'
                        run.key = match.id + run.num + run.player
                        run.match = match.id
                        run.date = match.datetime
                        if (race.events) {
                            run.podbans = Object.values(race.events).filter(event => event.event == 'tempban' && event.type == 'racer').map(event => event.selection)
                        } else {
                            run.podbans = []
                        }
                        run.opponents = Object.values(match.races[0].runs).map(r => r.player).filter(op => op !== run.player)
                        run.conditions = Object.values(runconditions)
                        if (thistrack == track) {
                            runs.push(run)

                        }
                    })
                })
            }

        })
        runs = runs.sort((a, b) => {
            if (a.time == "DNF" && b.time == "DNF") {
                return 0
            } else if (a.time == "DNF") {
                return 1
            } else if (b.time == "DNF") {
                return -1
            } else {
                return Number(a.time) - Number(b.time);
            }
        })
    }
    return runs
}

export function tourneybest({ matches, rulesets, tournaments, FilterTourneys, TrackCondition, UpgradeCondition, TimeCondition } = {}) {
    let besttimes = {}
    let besttwo = {}
    for (let i = 0; i < 25; i++) {
        besttimes[i] = []
        besttwo[i] = {}
    }
    if (matches && rulesets?.length) {
        matches.sort((a, b) => a.datetime - b.datetime).forEach(match => {
            let ruleset = rulesets.find((ruleset) => ruleset.id == match.ruleset)
            match.key = match.id
            if (match.races) {
                Object.values(match.races).forEach((race, racenum) => {
                    let thistrack = null
                    let conditions = {}
                    if (match.ruleset !== 'practice' && ruleset.general.type == '1v1') {
                        conditions = { ...ruleset.general.default }
                        Object.values(race.events).forEach(event => {
                            if (event.event == 'selection' && event.type == 'track') {
                                thistrack = event.selection
                            }
                            if (event.event == 'override' && event.type == 'condition') {
                                if (event.selection == 'nu') {
                                    conditions.upgr = 'nu'
                                }
                                if (event.selection == 'fl') {
                                    conditions.time = 'fl'
                                }
                                if (event.selection == 'sk') {
                                    conditions.trak = 'sk'
                                }
                            }
                        })
                    } else {
                        thistrack = ruleset.playlist[racenum].track
                        conditions = { ...ruleset.playlist[racenum].conditions }
                    }
                    Object.values(race.runs).forEach(run => {
                        run.user = run.player
                        let runconditions = { ...conditions }
                        if (run.retroskip) {
                            runconditions.trak = 'sk'
                        }

                        run.num = racenum + 1
                        run.vod = match.vod
                        run.tourney = tournaments.find(t => t.id == match.tourney)
                        run.bracket = match.bracket
                        run.round = match.round
                        run.qual = ruleset.general.type == 'Qualifier'
                        run.key = match.id + run.num + run.player
                        run.match = match.id
                        if (race.events) {
                            run.podbans = Object.values(race.events).filter(event => event.event == 'tempban' && event.type == 'racer').map(event => event.selection)
                        } else {
                            run.podbans = []
                        }
                        run.opponents = Object.values(match.races[0].runs).map(r => r.player).filter(op => op !== run.player)
                        run.conditions = Object.values(runconditions)
                        if (run.conditions.includes(TrackCondition) && run.conditions.includes(UpgradeCondition) && run.conditions.includes(TimeCondition)) {
                            besttimes[thistrack].push(run)
                        }

                    })
                })
            }

        })


        for (let i = 0; i < 25; i++) {
            besttimes[i] = besttimes[i].sort((a, b) => {
                if (a.time == "DNF" && b.time == "DNF") {
                    return 0
                } else if (a.time == "DNF") {
                    return 1
                } else if (b.time == "DNF") {
                    return -1
                } else {
                    return Number(a.time) - Number(b.time);
                }
            }).filter(run => run.qual == false && (FilterTourneys.includes(String(run.tourney)) || FilterTourneys.length == 0))
        }

        for (let i = 0; i < 25; i++) {
            if (besttimes[i].length > 0) {
                besttwo[i].first = besttimes[i][0]
            }
            if (besttimes[i].filter(r => r.pod !== besttwo[i].first).length > 0) {
                besttwo[i].second = besttimes[i].filter(r => String(r.pod) !== String(besttwo[i].first.pod))[0]
            }
        }
    }
    return besttwo
}

export function tourneystats({ matches, rulesets, FilterTourneys, FilterPlayers, FilterOpponents, DataTab, Conditions, DeathMode } = {}) {
    //step 0: initialize stat structure
    let headers = ["Track", "Play Rate", "Pick Rate", "Ban Rate", "Win Rate", "Death Rate"]
    let rows = []
    let users = []
    //initialize stats
    let stats = {
        races: 0,
        runs: 0,
        track: {},
        racer: {},
        players: {},
        commentators: {}
    }
    let times = []
    for (let i = 0; i < 25; i++) {
        stats.track[i] = { plays: 0, picks: [], bans: [], wins: [], deaths: [], race_time: 0 }
        stats.racer[i] = { plays: 0, picks: [], bans: [], wins: [], deaths: [], race_time: 0 }
        times.push({ type: 'track', track: i })
    }
    let best_times = {}

    //step 1: iterate through all matches and get stats
    Object.values(matches).filter(m => (FilterTourneys.includes(String(m.tourney)) || FilterTourneys.length == 0)).sort(function (a, b) { return a.datetime - b.datetime }).forEach(match => {
        let ruleset = rulesets.find(r => r.id == match.ruleset)
        let already_played = []
        let runback = {}
        let players = getPlayers(match)
        let participants = match.commentators ? players.concat(Object.values(match.commentators)) : players
        //initialize participant
        participants.forEach(p => {
            if (!users.includes(p)) {
                users.push(p)
            }
            if (!stats.players[p]) {
                stats.players[p] = {
                    race_time: 0,
                    deaths: [],
                    matches: { total: 0, won: 0, lost: 0, qual: 0, winners: 0, losers: 0 },
                    races: { total: 0, won: 0, lost: 0, runbacks: 0, dnf: 0 },
                    track: {},
                    racer: {},
                    overrides: { nu: 0, sk: 0, fl: 0, podban: 0 },
                    co_comm: {},
                }
                for (let i = 0; i < 25; i++) {
                    stats.players[p].track[i] = { plays: 0, picks: [], bans: [], wins: [], deaths: [], runbacks: 0, nu: 0, skips: 0 }
                    stats.players[p].racer[i] = { plays: 0, picks: [], bans: [], wins: [], deaths: [], nu: 0, skips: 0 }
                }
            }
        })
        //commentator stats
        if (match.commentators) {
            Object.values(match.commentators).forEach(commentator => {
                if (stats.commentators[commentator] == undefined) {
                    stats.commentators[commentator] = { count: 1, cocomm: {}, comfor: {} }
                } else {
                    stats.commentators[commentator].count++
                }
                Object.values(match.commentators).filter(c => c !== commentator).forEach(cocomm => {
                    if (stats.commentators[commentator].cocomm[cocomm] == undefined) {
                        stats.commentators[commentator].cocomm[cocomm] = 1
                    } else {
                        stats.commentators[commentator].cocomm[cocomm]++
                    }
                })
                players.forEach(player => {
                    if (stats.commentators[commentator].comfor[player] == undefined) {
                        stats.commentators[commentator].comfor[player] = 1
                    } else {
                        stats.commentators[commentator].comfor[player]++
                    }

                })
            })
        }

        let already_banned = []
        //for each race
        if (match.races) {

            match.races.forEach((race, num) => {
                if (FilterPlayers.length == 0 || players.includes(FilterPlayers[0]) && (FilterOpponents.length == 0 || players.includes(FilterOpponents[0]))) {
                    stats.races++
                }
                let temppod = [], temptrack = []
                let conditions = getConditions(ruleset, race, num)
                let thistrack = getTrack(race)

                let filtercondition = Conditions.trak.includes(conditions.trak) && Conditions.upgr.includes(conditions.upgr) && Conditions.time.includes(conditions.time) && (FilterTourneys.includes(String(match.tourney)) || FilterTourneys.length == 0)
                if (race.events) {
                    Object.values(race.events).forEach(event => {
                        let opponents = players.filter(o => o !== event.player)
                        if (
                            ((FilterPlayers.length + FilterOpponents.length) == 0) ||
                            ((![null, undefined, ""].includes(event.player) &&
                                (FilterPlayers.length == 0 || FilterPlayers.includes(event.player)) &&
                                (FilterOpponents.length == 0 || opponents.includes(FilterOpponents[0]))))) { //FilterPlayers.length == 0 || DataTab == 2 ||
                            if (event.event == 'tempban') {
                                if (event.type == 'racer') {
                                    if (event.cost == 0 || (event.cost > 0 && filtercondition)) {
                                        if (event.cost > 0) {
                                            stats.players[event.player].overrides['podban']++
                                        }
                                        if (!Array.isArray(event.selection)) {
                                            event.selection = [event.selection]
                                        }
                                        event.selection.forEach(selection => {
                                            stats.racer[selection].bans.push(1)
                                            temppod.push(Number(selection))
                                            for (let i = 0; i < 25; i++) {
                                                if (!temppod.includes(i)) {
                                                    stats.racer[i].bans.push(0)
                                                }
                                            }
                                        })
                                    }
                                } else if (event.type == 'track') {
                                    stats.track[event.selection].bans.push(1)
                                    temptrack.push(Number(event.selection))
                                    for (let i = 0; i < 25; i++) {
                                        if (!temptrack.includes(i) && !already_banned.includes(i) && (!already_played.includes(i) || (already_played.includes(i) && runback[players.find(p => p !== event.player)] == undefined))) {
                                            stats.track[i].bans.push(0)
                                        }
                                    }
                                }
                            } else if (event.event == 'selection' && event.type == 'track') {
                                if (![null, undefined, ""].includes(event.player) && filtercondition) {
                                    if (event.repeat) {
                                        runback[event.player] = true
                                    }
                                    stats.track[event.selection].picks.push(1)
                                    for (let i = 0; i < 25; i++) {
                                        if (!temptrack.includes(i) && !already_banned.includes(i) && (!already_played.includes(i) || (already_played.includes(i) && (runback[event.player] !== undefined)))) {
                                            stats.track[i].picks.push(0)
                                        }
                                    }
                                }
                                already_played.push(Number(event.selection))
                            } else if (event.event == 'permaban') {
                                if (event.type == "track") {
                                    stats.track[event.selection].bans.push(1)
                                    stats.players[event.player].track[event.selection].bans.push(1)
                                    already_banned.push(Number(event.selection))
                                    for (let i = 0; i < 25; i++) {
                                        if (!already_banned.includes(i) && !already_played.includes(i)) {
                                            stats.track[i].bans.push(0)
                                            stats.players[event.player].track[i].bans.push(0)
                                        }
                                    }
                                }
                            }
                        }
                    })
                }

                let winner = getWinner(race)
                //for each run
                if (filtercondition && thistrack !== null) {
                    //get run stats
                    Object.values(race.runs).forEach(run => {
                        if (FilterPlayers.length == 0 || players.includes(FilterPlayers[0]) && (FilterOpponents.length == 0 || players.includes(FilterOpponents[0]))) {
                            stats.runs++
                        }
                        let opponents = players.filter(o => o !== run.player)
                        if (run.time !== "DNF") {
                            stats.race_time += Number(run.time)
                            stats.players[run.player].race_time += Number(run.time)
                            stats.track[thistrack].race_time += Number(run.time)
                            if (![null, undefined, ""].includes(run.pod)) {
                                stats.racer[run.pod].race_time += Number(run.time)
                            }
                            if (winner.time == null || Number(run.time) - Number(winner.time) < 0) {
                                winner = { player: run.player, time: run.time, pod: run.pod }
                            }
                        } else {
                            stats.players[run.player].races.dnf++
                        }
                        stats.players[run.player].races.total++
                        if (FilterOpponents.length == 0 || opponents.includes(FilterOpponents[0])) {
                            if (![null, undefined, ""].includes(run.deaths) && (FilterPlayers.length == 0 || DataTab == 2 || FilterPlayers.includes(run.player))) {
                                if (DeathMode == 'race' || DeathMode == 'deathless' || (DeathMode == 'min' && run.time !== 'DNF')) {
                                    stats.track[thistrack].deaths.push(run.deaths)
                                    stats.players[run.player].deaths.push(run.deaths)
                                }
                            }
                            if (FilterPlayers.length == 0 || DataTab == 2 || (![null, undefined, ""].includes(run.player) && FilterPlayers.includes(run.player))) {
                                stats.track[thistrack].plays++
                                stats.players[run.player].track[thistrack].plays++
                                if (![null, undefined, ""].includes(run.pod)) {
                                    stats.racer[run.pod].plays++
                                    stats.racer[run.pod].picks.push(1)
                                    if (![null, undefined, ""].includes(run.deaths)) {
                                        stats.racer[run.pod].deaths.push(run.deaths)
                                    }
                                    for (let i = 0; i < 25; i++) {
                                        if (!temppod.includes(i) && i !== run.pod) {
                                            stats.racer[i].picks.push(0)
                                            stats.players[run.player].racer[i].picks.push(0)
                                        }
                                    }
                                }
                            }
                        }
                        let conarray = Object.values(conditions)
                        if (ruleset.general.type !== "Qualifier" && ((conarray.includes('ft') && conarray.includes('sk')) || (conarray.includes('ft') && !conarray.includes('sk') && !run.retroskip) || !conarray.includes('ft'))) {
                            let constring = conarray.filter(c => !['3l', 'fp', 'ng', 'um'].includes(c)).join("_")
                            if (!best_times[thistrack + "_" + constring] && run.time !== 'DNF') {
                                best_times[thistrack + "_" + constring] = { time: Number(run.time), player: run.player }
                            } else if (![null, undefined, ''].includes(best_times?.[thistrack + "_" + constring]?.time) && ![null, undefined, ''].includes(run.time) && Number(run.time) - Number(best_times[thistrack + "_" + constring].time) < 0) {
                                best_times[thistrack + "_" + constring] = { time: Number(run.time), player: run.player }
                            }

                            if (![null, undefined, '', 'DNF'].includes(run.time)) {
                                if (!times[thistrack][run.player] || run.time - times[thistrack][run.player].time < 0) {
                                    times[thistrack][run.player] = { ...run, constring: constring }
                                }
                            }
                        }
                    })
                    //get win stats
                    if (!["Qualifier", "1vAll"].includes(ruleset.type) && winner.player !== null) {
                        let opponents = players.filter(o => o !== winner.player)
                        if (((FilterPlayers.length == 0 || DataTab == 2 || FilterPlayers.includes(winner.player))) && (FilterOpponents.length == 0 || opponents.includes(FilterOpponents[0]))) {
                            stats.players[winner.player].races.won++
                            stats.racer[winner.pod]?.wins.push(1)
                            if (FilterPlayers.length == 0) {
                                if ((race.events && (winner.player == Object.values(race.events).filter(e => e.event == 'selection' && e.type == 'track')[0].player))) {
                                    stats.track[thistrack].wins.push(1)
                                } else {
                                    stats.track[thistrack].wins.push(0)
                                }
                            } else if (FilterPlayers.includes(winner.player)) {
                                stats.track[thistrack].wins.push(1)
                            }
                        }
                        Object.values(race.runs).forEach(loser => {
                            if (loser.player !== winner.player) {
                                let opponents = players.filter(o => o !== loser.player)
                                if (FilterOpponents.length == 0 || opponents.includes(FilterOpponents[0])) {
                                    if (FilterPlayers.includes(loser.player)) {
                                        stats.track[thistrack].wins.push(0)
                                    }
                                    stats.players[loser.player].races.lost++
                                    if (![null, undefined, ""].includes(loser.pod) && (FilterPlayers.length == 0 || DataTab == 2 || FilterPlayers.includes(loser.player))) {
                                        stats.racer[loser.pod].wins.push(0)
                                    }
                                }
                            }
                        })
                    }
                }
            })
        }
    })

    //sort player select
    headers = [
        { name: "Track", id: 'game', type: 'track' },
        [{ name: "Play Rate", id: 'playrate', tip: 'Percentage of total runs that feature this track', color: '#FFFF00', type: 'percent' },
        { name: "Plays", id: 'plays', tip: 'Number of total runs that feature this track', color: '#FFFF00', type: 'number' }],
        { name: "Pick Rate", id: 'picks', tip: 'Percentage of times a track was selected (when available)', color: '#00FFFF', type: 'percent' },
        { name: "Ban Rate", id: 'bans', tip: 'Percentage of times a track was banned (when available)', color: '#FF0000', type: 'percent' },
        { name: "Win Rate", id: 'wins', tip: 'Percentage of times the player who selected the track won the race', color: '#00FF00', type: 'percent' },
        { name: (DeathMode == 'race' ? 'Deaths / Race' : DeathMode == 'min' ? 'Deaths / Minute' : "Deathless Rate"), id: 'deaths', tip: DeathMode == 'race' ? 'Average deaths per race' : DeathMode == 'min' ? 'Average deaths per minute' : 'Percentage of runs on this track with no deaths', color: '#FF00FF', type: 'number' }
    ]
    if (DataTab == 0) {
        for (let i = 0; i < 25; i++) {
            let track = {
                type: 'track',
                track: i,
                plays: stats.track[i].plays,
                playrate: ((stats.track[i].plays / stats.runs) * 100).toFixed(2),
                picks: stats.track[i].picks.length == 0 ? 0 : ((stats.track[i].picks.reduce((a, b) => { return a + b }) / stats.track[i].picks.length) * 100).toFixed(2),
                bans: stats.track[i].bans.length == 0 ? 0 : ((stats.track[i].bans.reduce((a, b) => { return a + b }) / stats.track[i].bans.length) * 100).toFixed(2),
                wins: stats.track[i].wins.length == 0 ? 0 : ((stats.track[i].wins.reduce((a, b) => { return Number(a) + Number(b) }) / stats.track[i].wins.length) * 100).toFixed(2),
            }
            if (DeathMode == 'deathless') {
                if (stats.track[i].deaths.length) {
                    track.deaths = ((stats.track[i].deaths.filter(d => d == 0).length / stats.track[i].deaths.length) * 100).toFixed(2)
                }
            } else {
                track.deaths = stats.track[i].deaths.length == 0 ? 0 : (stats.track[i].deaths.reduce((a, b) => { return Number(a) + Number(b) }) / (DeathMode == 'race' ? stats.track[i].deaths.length : (stats.track[i].race_time / 60))).toFixed(2)
            }
            rows.push(track)
        }
        headers[0] = { name: "Track", id: 'game', type: 'track' }
    } else if (DataTab == 1) {
        for (let i = 0; i < 25; i++) {
            let racer = {
                type: 'racer',
                racer: i,
                plays: stats.racer[i].plays,
                playrate: ((stats.racer[i].plays / stats.runs) * 100).toFixed(2),
                picks: stats.racer[i].picks.length == 0 ? 0 : ((stats.racer[i].picks.reduce((a, b) => { return a + b }) / stats.racer[i].picks.length) * 100).toFixed(2),
                bans: stats.racer[i].bans.length == 0 ? 0 : ((stats.racer[i].bans.reduce((a, b) => { return a + b }) / stats.racer[i].bans.length) * 100).toFixed(2),
                wins: stats.racer[i].wins.length == 0 ? 0 : ((stats.racer[i].wins.reduce((a, b) => { return Number(a) + Number(b) }) / stats.racer[i].wins.length) * 100).toFixed(2),
            }
            if (DeathMode == 'deathless') {
                if (stats.racer[i].deaths.length) {
                    racer.deaths = ((stats.racer[i].deaths.filter(d => d == 0).length / stats.racer[i].deaths.length) * 100).toFixed(2)
                }
            } else {
                racer.deaths = stats.racer[i].deaths.length == 0 ? 0 : (stats.racer[i].deaths.reduce((a, b) => { return Number(a) + Number(b) }) / (DeathMode == 'race' ? stats.racer[i].deaths.length : (stats.racer[i].race_time / 60))).toFixed(2)
            }
            rows.push(racer)
        }
        headers[0] = { name: "Racer", id: 'game', type: 'racer' }
    } else if (DataTab == 2) {
        users.forEach(p => {
            let player = {
                type: 'user',
                user: p,
                id: p,
                playtime: [null, undefined, "", 0].includes(stats.players[p].race_time) ? null : Math.round(stats.players[p].race_time),
                records: Object.values(best_times).filter(t => t.player == p).length == 0 ? null : Object.values(best_times).filter(t => t.player == p).length,
                wins: [null, undefined, "", 0].includes(stats.players[p].race_time) ? null : ((stats.players[p].races.won / (stats.players[p].races.won + stats.players[p].races.lost)) * 100).toFixed(2),
                deaths: stats.players[p].deaths.length == 0 ? null : (stats.players[p].deaths.reduce((a, b) => Number(a) + Number(b)) / (DeathMode == 'race' ? stats.players[p].deaths.length : (stats.players[p].race_time / 60))).toFixed(2),
                comms: (stats.commentators[p] ? stats.commentators[p].count : null)
            }
            if (DeathMode == 'deathless') {
                player.deaths = stats.players[p].deaths.length == 0 ? 0 : ((stats.players[p].deaths.filter(d => d == 0).length / stats.players[p].deaths.length) * 100).toFixed(2)
            }
            rows.push(player)
        })
        headers[0] = { name: "Player", id: 'game', type: 'user' }
        headers[1] = { name: "Play Time", id: 'playtime', color: '#FFFF00', id: 'playtime', type: 'time' }
        headers[2] = { name: "Commentary", id: 'comms', color: '#00FFFF', id: 'comms', type: 'number' }
        headers[3] = { name: "Records", id: 'records', color: '#FF0000', id: 'records', type: 'number' }
    } else if (DataTab == 3) {
        let userlist = FilterPlayers.length ? FilterPlayers.map(u => u.discordID) : users.slice(0, 5)
        times.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                if (best_times[index + "_" + row[key].constring]?.time == row[key].time) {
                    row[key].record = true
                }
                let leader = Math.min(...Object.values(row).filter(run => userlist.includes(run.player)).map(run => Number(run.time)).filter(t => !isNaN(t)))
                if (Number(row[key].time) == Number(leader)) {
                    row[key].leader = true
                }
            })
        })
        headers = [{ name: "Track", id: 'game', type: 'track' }, ...userlist.slice(0, 5).map(u => ({ name: u, id: u, type: 'run', headertype: 'user' }))]
        rows = times
    }

    return [headers, rows]

}