import { Card, Stack, CardContent, CardActionArea } from "@mui/material";
import firebase from 'firebase/app';
import 'firebase/database';
import { useState, useEffect } from "react";
import CoolCard from "./CoolCard";
import MatchCard from '../tournaments/tourneys/MatchCard'
export default function UpcomingMatches() {

    const [matches, setMatches] = useState(null)
    useEffect(() => {
        const userquery = firebase.database().ref('tourney/scheduled').orderByChild('current').equalTo(true).limitToLast(5);
        userquery.once('value', (snapshot) => {
            let val = snapshot.val()
            setMatches(val ? Object.values(snapshot.val()) : null)
        });
    }, []);
    return (
        <CoolCard color={'#00FF00'} title='UPCOMING MATCHES' action='VIEW SCHEDULE'>
            <Stack direction='row' spacing = {2}>
                {matches && matches.map(match => (
                    <MatchCard match={match} />
                ))}</Stack>
        </CoolCard>
    )
}