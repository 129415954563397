
import { styled } from '@mui/material/styles';
import { Typography, Avatar, Stack, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import UnformattedLink from '../components/Link';
function getName(profile) {
        let name = 'no name'
        if (!profile) {
                return name
        }
        if (profile.name) {
                return profile.name
        } else if (profile.src?.names?.international) {
                return profile.src.names.international
        } else if (profile.src?.name) {
                return profile.src.name
        } else if (profile.cs?.name) {
                return profile.cs.name
        } else if (profile.cs?.nickname) {
                return profile.cs.nickname
        } else if (profile.alt?.name) {
                return profile.alt.name
        } else if (profile.alt?.nickname) {
                return profile.alt.nickname
        } else {
                return name
        }
}

export default function UserName({ props, user_id, avatar, link, size, discord, sub, user_object } = {}) {
        let { user } = useUser({ userId: user_id, discord })
        if (user_object || !user_id) {
                user = user_object
        }
        const CustomColor = styled(Typography)(({ theme }) => ({
                fontWeight: 1000,
                fontSize: `${size}`,
                background: user?.src?.['name-style'] ? "-webkit-linear-gradient(0deg," + user?.src?.['name-style']?.['color-from']?.dark + ", " + user?.src?.['name-style']?.['color-to']?.dark + ")" : "",
                WebkitBackgroundClip: user?.src?.['name-style'] ? "text" : "",
                WebkitTextFillColor: user?.src?.['name-style'] ? "transparent" : "",
                flexShrink: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
        }));

        const av = (<Stack props justifyContent='flex-start' alignItems='center' direction='row' spacing={1}>
                {avatar && user?.avatar &&
                        <Avatar src={user?.avatar?.replace('.webp', '.png')}
                                sx={{
                                        width: size,
                                        height: size
                                }}
                                style={{
                                        borderRadius: '20%',
                                        width: size,
                                        height: size
                                }} />}
                <Stack justifyContent='center' alignItems='flex-start'>
                        <CustomColor>{getName(user)}</CustomColor>
                        {sub && sub}
                </Stack>

        </Stack>)
        if (!user) {
                return (<div></div>)
        }
        if (link) {
                return (<UnformattedLink props to={'/players/' + user?.id} style={{ textDecoration: 'none', color: 'inherit' }}>{av}</UnformattedLink>)
        } else {
                return (av)
        }
}