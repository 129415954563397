import React from "react";
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';


export default function StatControls({ Conditions, setConditions, laps, backwards, mirror }) {
    const handleCondition = (event, value) => {
        if (value.length > 0) {
            let newConditions = { ...Conditions }
            newConditions[event] = value
            setConditions(newConditions)
        }
    }
    return (
        <Grid container direction="row" spacing={1}>
            <Grid item xs={12} >
                <ToggleButtonGroup fullWidth size='small' value={Conditions.trak} onChange={(event, value) => handleCondition('trak', value)}>
                    <ToggleButton value='ft' >Full Track</ToggleButton>
                    <ToggleButton value='fs' >Skips</ToggleButton>
                </ToggleButtonGroup></Grid>
            <Grid item xs={12}>
                <ToggleButtonGroup fullWidth size='small' value={Conditions.upgr} onChange={(event, value) => handleCondition('upgr', value)}>
                    <ToggleButton value='mu'>Upgrades</ToggleButton>
                    <ToggleButton value='nu'>No Upgrades</ToggleButton>
                </ToggleButtonGroup></Grid>
            {backwards && <Grid item xs={12} >
                <ToggleButtonGroup fullWidth size='small' value={Conditions.dire} onChange={(event, value) => handleCondition('dire', value)}>
                    <ToggleButton value='fw'>Forwards</ToggleButton>
                    <ToggleButton value='bw'>Backwards</ToggleButton>
                </ToggleButtonGroup></Grid>}
            <Grid item xs={12}>
                <ToggleButtonGroup fullWidth size='small' value={laps ? Conditions.laps : Conditions.time} onChange={(event, value) => handleCondition(laps ? 'laps' : 'time', value)}>
                    {!laps && <ToggleButton value='tt'>Total Time</ToggleButton>}
                    {!laps && <ToggleButton value='fl'>Fast Lap</ToggleButton>}
                    {laps && <ToggleButton value='l1'>1 Lap</ToggleButton>}
                    {laps && <ToggleButton value='l2'>2 Laps</ToggleButton>}
                    {laps && <ToggleButton value='l3'>3 Laps</ToggleButton>}
                    {laps && <ToggleButton value='l4'>4 Laps</ToggleButton>}
                    {laps && <ToggleButton value='l5'>5 Laps</ToggleButton>}
                </ToggleButtonGroup></Grid>

            {mirror && <Grid item xs={12}>
                <ToggleButtonGroup fullWidth size='small' value={Conditions.mirr} onChange={(event, value) => handleCondition('mirr', value)}>
                    <ToggleButton value='um'>Unmirrored</ToggleButton>
                    <ToggleButton value='mi'>Mirrored</ToggleButton>
                </ToggleButtonGroup></Grid>}
        </Grid>
    );
}
