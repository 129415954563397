import React from 'react'
import { Typography, Stack } from '@mui/material';
import UnformattedLink from './Link.js';
import { tracks, planets } from '../data.js'
import { ReactSVG } from "react-svg";
export default function TrackName({ track, link, thumb }) {
    const CustomIcon = ({ track }) => (
        <ReactSVG src={`/track_previews/${track}.svg`}
            beforeInjection={(svg) => {
                svg.setAttribute('fill', '#00FF00'); // Set the desired color
            }}
            style={{ width: '20px', height: '20px' }}
        />
    );
    const Track = (
        ![null, undefined, ''].includes(track) && <Stack color="inherit" direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
            {thumb && <CustomIcon track={track} />}
            {!thumb && <img src={planets[tracks[track]?.planet]?.img} width={20} height={20} />}
            <Typography color="inherit" variant="body2" sx={{ fontWeight: 500, display: { xs: 'none', md: 'block' } }}>
                {tracks[track].name}
            </Typography>
            <Typography color="inherit" variant="body2" sx={{ fontWeight: 500, display: { xs: 'block', md: 'none' } }}>
                {tracks[track]?.nickname[0]?.toUpperCase()}
            </Typography>
        </Stack>
    );

    return (
        link ? (
            <UnformattedLink to={`/tracks/${track}`} >
                {Track}
            </UnformattedLink >
        ) : (
            <div>
                {Track}
            </div>
        )
    )
}
