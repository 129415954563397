import { useState, useEffect } from 'react';
import { projectDatabase } from '../firebase/config';

export function useTracks() {
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    const tracksRef = projectDatabase.ref('tracks');
    tracksRef.once('value', (snapshot) => {
      const tracksData = snapshot.val();
      const tracksList = [];
      for (let id in tracksData) {
        tracksList.push({
          id: id,
          ...tracksData[id],
        });
      }
      setTracks(tracksList);
    });
  }, []);

  return tracks;
}