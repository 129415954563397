import { useEffect, useState } from "react"
import { projectDatabase } from "../firebase/config"

export const useCollection = (collection) => {
    const [documents, setDocuments] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        let ref = projectDatabase.ref(collection)

        const unsubscribe = (stuff) => {
            let data = stuff.val()
            let results = []
            if (data) {
                let values = Object.values(data)
                let keys = Object.keys(data)
                values.forEach((value, index) => {
                    results.push({ ...value, id: keys[index] })
                })
                setDocuments(data)
            }

            //setDocuments(results)
            setError(null)
        }

        function errData(err) {
            console.log('Error!');
            console.log(err)
        }

        ref.on('value', unsubscribe, errData)
        // unsubscribe on unmount
        return () => unsubscribe()

    }, [collection])

    return { documents, error }
}