import { Chip, Container, Typography, Grid, Stack } from "@mui/material";


import { useSpeedrun } from "../../hooks/useSpeedrun";
import { useParams } from 'react-router-dom'
import UserName from "../../tools/UserName";
import RacerName from "../../components/RacerName";
import TrackName from "../../components/TrackName";
import TimeDisplay from "../../tools/TimeDisplay";
import VideoEmbed from "../../components/VideoEmbed";
export default function Run() {
    const { id } = useParams()
    const { run } = useSpeedrun({ id })
    const thiscategory = run.category
    return (
        <Container sx={{ minHeight: '100vh' }}>
            <Grid container spacing={2}>
                {run.proof && <Grid item md={8} xs={12}>
                    <VideoEmbed url={run.proof} />
                    <a href={run.proof}>{run.proof}</a>
                </Grid>}
                <Grid item md={4} xs={12}>
                    <Stack spacing={2}>
                        <Typography><UserName user_id={run.player} avatar={true} link={true} /></Typography>
                        <Typography>Category: {thiscategory?.name} {run?.track ? ": " : ""} </Typography>{run?.track && <TrackName track={run?.track} />}
                        <RacerName racer={run?.pod} />
                        <Stack direction='row' spacing={1}>{run?.conditions?.map(c => { return <Chip label={c} /> })}</Stack>
                        <TimeDisplay time={run?.time} />

                        <Typography>Place</Typography>
                        {run?.notes && <Typography variant='caption'>{run?.notes}</Typography>}

                        <Stack>
                            <Typography>{run?.date}</Typography>
                            <Typography>{run?.submitted}</Typography>
                            <Typography>{run?.veriffied}</Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    )
}