import { useEffect, useState } from "react";
import { projectDatabase } from "../firebase/config";

export function useUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getUsers = async () => {
      // Check if the data is already cached
      const cacheKey = 'users'; // Unique cache key for the "users" node
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData && false) {
        // If cached data exists, use it
        setUsers(JSON.parse(cachedData));
        setLoading(false);
      } else {

        // If not cached, fetch the data from Firebase
        try {
          const snapshot = await projectDatabase.ref('users').once('value');
          const fetchedUsers = snapshot.val() || [];

          // Add id/key as a child property in each user object
          const usersWithId = Object.entries(fetchedUsers).map(([id, user]) => ({
            id,
            ...user,
          }));

          // Cache the fetched data
          localStorage.setItem(cacheKey, JSON.stringify(usersWithId));
          setUsers(usersWithId);
          setLoading(false);
          // Listen for database changes
          const usersRef = projectDatabase.ref('users');
          usersRef.on('value', (snapshot) => {
            const updatedUsers = snapshot.val() || [];

            const updatedusersWithId = Object.entries(updatedUsers).map(([id, user]) => ({
              id,
              ...user,
            }));

            // Update cache with the new data
            localStorage.setItem(cacheKey, JSON.stringify(updatedusersWithId));

            setUsers(updatedusersWithId);
          });

          return () => {
            usersRef.off();
          };
        } catch (error) {
          // Handle error
          console.error('Error fetching users:', error);
          setLoading(false);
        }
      }
    };

    getUsers();
  }, []);


  return { users, loading, error, user_ref: projectDatabase.ref("users") };
}
