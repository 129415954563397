import React from 'react'
import { Stack, Autocomplete, TextField, Tabs, Tab, Container, Alert, ToggleButton, ToggleButtonGroup, Paper, Grid, Button, Modal, Box, Typography, Drawer, IconButton, Divider } from '@mui/material';
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useMatches } from '../../hooks/useMatches.js'
import { useRulesets } from '../../hooks/useRulesets.js'
import { useTournaments } from '../../hooks/useTournaments.js'
import { useUsers } from '../../hooks/useUsers.js'
import StatControls from './StatControls';
import MultiUpgradeSelect from '../../controls/MultiUpgradeSelect.js';
import SettingsIcon from '@mui/icons-material/Settings';
import ScienceIcon from '@mui/icons-material/Science';
import PersonIcon from '@mui/icons-material/Person';
import FlagIcon from '@mui/icons-material/Flag';
import TimerIcon from '@mui/icons-material/Timer';
import TuneIcon from '@mui/icons-material/Tune';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import StatTable from './StatTable.js';
import { tourneystats } from '../../hooks/useTourneyRuns.js';
import { useRandomChallengeTimes } from '../../hooks/useRandomChallengeTimes.js';
import { challengestats } from './challengestats.js';
import { useRandomChallenges } from '../../hooks/useRandomChallenges.js';
import { useRandomChallengeFeedback } from '../../hooks/useRandomChallengeFeedback.js';
import { useParams, Redirect, Link, useHistory, useLocation } from 'react-router-dom'
import { gamestats } from './gamestats.js';
import { useTracks } from '../../hooks/useTracks.js';
import { useRacers } from '../../hooks/useRacers.js';
import { useParts } from '../../hooks/useParts.js';
export default function Stats() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const location = useLocation();
    const parts = useParts()
    const { id } = useParams()
    const history = useHistory()
    const matches = useMatches()
    const [DataTab, setTab] = useState(0)
    const [DataSheet, setSheet] = useState(3)
    const [upgrades, setUpgrades] = useState({
        acceleration: { part: 5, health: 255 },
        air_brake_interval: { part: 5, health: 255 },
        antiskid: { part: 5, health: 255 },
        cool_rate: { part: 5, health: 255 },
        max_speed: { part: 5, health: 255 },
        repair_rate: { part: 5, health: 255 },
        turn_response: { part: 5, health: 255 }
    })
    const rulesets = useRulesets()
    const tourneys = useTournaments()
    const { users } = useUsers()
    const [DeathMode, setDeathMode] = useState('race')
    const [Conditions, setConditions] = useState({ trak: ['ft', 'fs'], upgr: ['mu', 'nu'], time: ['tt', 'fl'], dire: ['fw', 'bw'], laps: ['l1', 'l2', 'l3', 'l4', 'l5', 'fl'], mirr: ['mi', 'um'] })

    const [FilterTourneys, setFilterTourneys] = useState([])
    const [FilterPlayers, setFilterPlayers] = useState([])
    const [FilterOpponents, setFilterOpponents] = useState([])
    const [ChallengeTypes, setChallengeTypes] = useState([])
    const tracks = useTracks()
    const racers = useRacers()
    const randomchallengetimes = useRandomChallengeTimes()
    const randomchallenges = useRandomChallenges()
    const randomchallengefeedback = useRandomChallengeFeedback()

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const tabChange = (event, newValue) => {
        if (newValue == 2) {
            setFilterPlayers([])
        }
        setTab(newValue);
    };

    const changeTourney = (event, value) => {
        if (value == null) {
            setFilterTourneys([])
        } else {
            setFilterTourneys([String(value.id)])
        }
    }
    const userFilter = (event, value) => {
        if (value == null) {
            setFilterPlayers([])
        } else {
            setFilterPlayers([...FilterPlayers, String(value.discordID)])
        }
    }
    let [headers, rows] = [[], []]
    if (id == 'tournaments') {
        [headers, rows] = tourneystats({ matches, rulesets, FilterTourneys, FilterPlayers, FilterOpponents, DataTab, Conditions, DeathMode, users })
    } else if (id == 'challenges') {
        [headers, rows] = challengestats({ randomchallenges, randomchallengetimes, randomchallengefeedback, DataTab, Conditions, users, ChallengeTypes, FilterPlayers })
    } else if (id == 'game') {
        [headers, rows] = gamestats({ DataTab, tracks, racers, Upgrades: upgrades })
    }
    headers.forEach(h => {
        if (Array.isArray(h)) {
            h.forEach(_h => {
                _h.max = Math.max(...rows.map(r => r[_h.id]))
                if (parts[_h.id]?.upgrades) {
                    _h.subtitle = parts?.[_h.id]?.upgrades?.[upgrades[_h.id]?.part]?.name
                }
            })
        } else {
            h.max = Math.max(...rows.map(r => r[h.id]))
        }
        if (parts[h.id]) {
            h.subtitle = parts[h.id].upgrades[upgrades[h.id].part]?.name
        }
    })
    if (DataTab > 1 && location.pathname.includes('game')) {
        setTab(0)
    }

    let id_map = {
        tournaments: 'Tournament',
        challenges: 'Challenge',
        speedruns: 'Speedrun',
        game: 'Game'
    }

    const drawerContent = (<Box sx={{ width: { md: 325, sx: '100%' }, padding: 2, flexShrink: 0 }}>
        <Stack direction='row' justifyContent='space-between'>
            <IconButton onClick={handleDrawerClose}>
                <ChevronRightIcon />
            </IconButton>
            <Button >Reset</Button>
        </Stack>
        <Divider />
        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            mb={1}
        >
            {tourneys && location.pathname.includes('tournaments') && <Grid item xs={12}><Autocomplete
                disablePortal
                onChange={changeTourney}
                id="combo-box-demo"
                options={
                    Object.keys(tourneys).sort((a, b) => tourneys[a].name.localeCompare(tourneys[b].name)).map(key => { return { label: tourneys[key].name, id: key } })}
                renderInput={(params) => <TextField {...params} label="Tournament" />}
            /></Grid>}
            {users && DataTab !== 2 && !location.pathname.includes('game') && <Grid item xs={12} ><Autocomplete
                disablePortal
                multiple
                max={5}
                disabled={DataTab == 2}
                onChange={(e, value) => {
                    if (value == null) {
                        setFilterPlayers([])
                    } else {
                        setFilterPlayers(value)
                    }
                }}
                value={FilterPlayers}
                id="combo-box-demo"
                options={
                    Object.values(users).filter(u => u.discordID && u.name).sort((a, b) => a?.name?.localeCompare(b?.name))}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Player" />}

            // renderOption={(props, option) => (
            //     <MenuItem >
            //         <UserName user={option} avatar={true} size={20} />
            //     </MenuItem>
            // )}
            /></Grid>}
            {users && DataTab !== 2 && location.pathname.includes('tournaments') && <Grid item xs={12}><Autocomplete
                disablePortal
                disabled={DataTab == 2}
                onChange={(e, value) => {
                    if (value == null) {
                        setFilterOpponents([])
                    } else {
                        setFilterOpponents([String(value.discordID)])
                    }
                }}
                value={FilterPlayers[0]}
                id="combo-box-demo"
                options={
                    Object.values(users).filter(u => u.discordID && u.name).sort((a, b) => a?.name?.localeCompare(b?.name))}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Opponent" />}
            /></Grid>}
            {users && location.pathname.includes('challenges') && <Grid item xs={12} ><Autocomplete
                disablePortal
                disableListWrap
                multiple
                onChange={(e, value) => {
                    if (value == null) {
                        setChallengeTypes([])
                    } else {
                        setChallengeTypes(value)
                    }
                }}
                value={ChallengeTypes}
                id="combo-box-demo"
                options={['Private (random)', 'Daily (random)', 'Bounty (random)', 'Multiplayer (random)', 'Custom']}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField {...params} label="Challenge Type" />}
            /></Grid>}
            {!location.pathname.includes('game') &&
                <Grid item xs={12} >
                    <Typography mb={1} variant='h5' fontWeight={500} color='#FFFFFF'>Conditions</Typography>
                    <StatControls Conditions={Conditions} setConditions={setConditions} laps={location.pathname.includes('challenges')} backwards={location.pathname.includes('challenges')} mirror={location.pathname.includes('challenges')} />
                </Grid>}
        </Grid>
        <Divider />
        {location.pathname.includes('game') && DataTab == 1 && <Box sx={{
            justifyContent: 'center',
            alignContent: 'center',
            padding: 2
        }}>
            <Typography mb={1} variant='h5' fontWeight={500} color='#FFFFFF'>Upgrades</Typography>
            <MultiUpgradeSelect Upgrades={upgrades} setUpgrades={setUpgrades} options={['antiskid', 'turn_response', 'acceleration', 'max_speed', 'air_brake_interval', 'cool_rate', 'repair_rate',]} onChange={(key, field, value) => {
                if (value < 0) {
                    return
                }
                let Upgrades = { ...upgrades }
                console.log(key, field, value)
                if (key == 'all') {
                    Object.keys(Upgrades).forEach(k => {
                        if (field == 'part') {
                            Upgrades[k].part = value
                        } else if (field == 'health') {
                            Upgrades[k].health = value
                        }
                    })
                } else {
                    Upgrades[key][field] = value
                }
                setUpgrades(Upgrades)
            }} />
        </Box>}
        {location.pathname.includes('game') && DataTab == 1 && <Button size='small' fullWidth variant='outlined' onClick={() => history.push('/simulators')} startIcon={<ScienceIcon />}>Simulator</Button>}
    </Box>)

    return (
        <Container sx={{ minHeight: '100vh', marginBottom: 5, }}>
            {!['tournaments', 'game', 'speedruns', 'challenges'].includes(id) && <Redirect to='/stats' />}
            <Helmet>
                <title>{`${id_map[id]} ${(DataTab == 0 ? 'Track' : DataTab == 1 ? 'Racer' : 'Player')} Stats | Junkyard`}</title>
                <meta name="description" content="Explore tournament statistics" />
            </Helmet>

            <Paper elevation={0} sx={{ marginBottom: 1 }}>
                <Tabs variant="scrollable" scrollButtons="auto" value={location.pathname} onChange={(event, value) => history.push(value)}>
                    <Tab label="Game" value="/stats/game" />
                    <Tab label="Speedruns" value="/stats/speedruns" />
                    <Tab label="Tournaments" value="/stats/tournaments" />
                    <Tab label="Challenges" value="/stats/challenges" />
                </Tabs>
            </Paper>

            <Grid direction='row' spacing={1} container alignItems='flex-start' justifyContent='flex-start' >
                <Grid item xs={10} ><ToggleButtonGroup fullWidth exclusive size='small' value={DataTab} onChange={tabChange} >
                    <ToggleButton value={0}>Tracks</ToggleButton>
                    <ToggleButton value={1}>Racers</ToggleButton>
                    {!location.pathname.includes('game') && <ToggleButton value={2}>Players</ToggleButton>}
                    {!location.pathname.includes('game') && <ToggleButton value={3}>Times</ToggleButton>}
                </ToggleButtonGroup></Grid>
                <Grid item xs={2}><Button sx={{ display: { xs: 'none', md: 'flex' } }} onClick={() => setIsDrawerOpen(!isDrawerOpen)} startIcon={<TuneIcon />} fullWidth variant='outlined'>{location.pathname.includes('game') ? 'Upgrades' : 'Filters'}</Button><IconButton sx={{ display: { xs: 'flex', md: 'none' } }} color='primary' onClick={() => setIsDrawerOpen(!isDrawerOpen)}><TuneIcon /></IconButton></Grid>
            </Grid>
            <Stack>

                <StatTable headers={headers} rows={rows} />
                {location.pathname.includes('speedruns') && <Alert severity='info'>Nothing to see here yet.</Alert>}
            </Stack>

            <Drawer variant="persistent" anchor='right' open={isDrawerOpen} onClose={handleDrawerClose} sx={{ minWidth: { md: '325px', sx: '100vw' } }}  >
                {drawerContent}
            </Drawer>
        </Container >

    )
}
