
exports.getName = function (profile) {
    let name = 'no name'
    if(!profile){
        return name
    }
    if (profile.name) {
        return profile.name
    } else if (profile.src?.names?.international) {
        return profile.src.names.international
    } else if (profile.src?.name) {
        return profile.src.name
    } else if (profile.cs?.name) {
        return profile.cs.name
    } else if (profile.cs?.nickname) {
        return profile.cs.nickname
    } else if (profile.alt?.name) {
        return profile.alt.name
    } else if (profile.alt?.nickname) {
        return profile.alt.nickname
    } else {
        return name
    }
}

