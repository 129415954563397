import React from 'react'
import { Typography, Stack } from '@mui/material';
import UnformattedLink from './Link.js';
import { planets } from '../data.js'
export default function PlanetName({ planet, link }) {
    const Track = (
        ![null, undefined, ''].includes(planet) && <Stack color="inherit" direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
            <img src={planets[planet]?.img} width={20} height={20} />
            <Typography color="inherit" variant="body2" sx={{ fontWeight: 500, display: { xs: 'none', md: 'block' } }}>
                {planets[planet].name}
            </Typography>
        </Stack>
    );

    return (
        link ? (
            <UnformattedLink to={`/planets/${planet}`} >
                {Track}
            </UnformattedLink >
        ) : (
            <div>
                {Track}
            </div>
        )
    )
}
