import { Tabs, Tab, Stack, Typography, Paper } from "@mui/material"
import TimerIcon from '@mui/icons-material/Timer';
import FlagIcon from '@mui/icons-material/Flag';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

export default function SourceTabs({ Sheet, setSheet, buttons }) {
    if (buttons) {
        return (
            <Paper elevation = {0}>
                <Tabs variant="scrollable"  fullWidth value={Sheet} onChange={(e, value) => setSheet(value)}>
                    <Tab label={<Stack direction='row' spacing={1} justifyItems='center'><Typography variant='button'>Game</Typography></Stack>} />
                    <Tab label={<Stack direction='row' spacing={1} justifyItems='center'><TimerIcon /><Typography variant='button'>Speedruns</Typography></Stack>} />
                    <Tab label={<Stack direction='row' spacing={1} justifyItems='center'><EmojiEventsIcon /><Typography variant='button'>Tournaments</Typography></Stack>} />
                    <Tab label={<Stack direction='row' spacing={1} justifyItems='center'><FlagIcon /><Typography variant='button'>Challenges</Typography></Stack>} />
                </Tabs>
            </Paper>
        )
    } else {
        return (
            <Paper elevation = {0}>
                <Tabs variant="scrollable"  value={Sheet} onChange={(e, value) => setSheet(value)}>
                    <Tab label={<Stack direction='row' spacing={1} justifyItems='center'><TimerIcon /><Typography variant='button'>Speedruns</Typography></Stack>} />
                    <Tab label={<Stack direction='row' spacing={1} justifyItems='center'><EmojiEventsIcon /><Typography variant='button'>Tournaments</Typography></Stack>} />
                    <Tab label={<Stack direction='row' spacing={1} justifyItems='center'><FlagIcon /><Typography variant='button'>Challenges</Typography></Stack>} />
                </Tabs>
            </Paper>
        )
    }

}