import { Stack, Tooltip, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, IconButton, TableContainer, Paper, Typography } from '@mui/material';
import { useState } from 'react'
import { numberWithCommas, timefix, timestring } from '../../tools.js'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CustomProgress from './CustomProgress.js';
import RacerName from '../../components/RacerName.js';
import TrackName from '../../components/TrackName.js';
import PlanetName from '../../components/PlanetName.js';
import UserName from '../../tools/UserName.js';
import TimeDisplay from '../../tools/TimeDisplay.js';
import CircuitName from '../../components/CircuitName.js';
export default function StatTable({ headers, rows }) {
    const [ColumnSwitcher, setColumnSwitcher] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(0);
    const handleRequestSort = (index) => {
        setOrder(index == orderBy ? order == 'asc' ? 'desc' : 'asc' : 'desc');
        setOrderBy(index);
    };

    rows = rows.sort(function (a, b) {
        let header = headers[orderBy] ?? headers[0]
        if (Array.isArray(headers[orderBy])) {
            header = headers[orderBy][ColumnSwitcher[orderBy]]
        }
        if (header.type == 'player') {
            return a?.name?.localeCompare(b?.name) * (order == 'asc' ? -1 : 1)
        } else if (header.type == 'racer') {
            return (a.racer - b.racer) * (order == 'asc' ? -1 : 1)
        } else if (header.type == 'track') {
            return (a.track - b.track) * (order == 'asc' ? -1 : 1)
        } else if (header.type == 'run') {
            return (a[header.id]?.time - b[header.id]?.time) * (order == 'asc' ? -1 : 1)
        }
        if (a[header.id] == null) {
            return 1
        } else if (b[header.id] == null) {
            return -1
        } else {
            return (b[header.id] - a[header.id]) * (order == 'asc' ? -1 : 1) * (orderBy == 0 ? -1 : 1)
        }
    })

    return (<TableContainer component={Paper} style={{ overflowX: 'auto' }}><Table size='small'>
        <TableHead>
            <TableRow>
                {headers.map((c, index) => {
                    let header = c
                    if (Array.isArray(c)) {
                        header = c[ColumnSwitcher[index]] ?? c[0]
                    }
                    return <TableCell key = {index} align='left' sx={{ padding: '5px 8px', width: (index == 0 ? { xs: "30px", md: '200px' } : "150px"), minWidth: (index == 0 ? { xs: '30px', md: '200px' } : '170px') }} style={index == 0 ? { position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#222' } : null}>
                        <Stack direction='row' alignItems='center' justifyContent='space-between'>
                            <Tooltip title={header?.tip}>
                                <Stack direction='row' spacing={1} alignItems = 'center'>
                                    {header?.img && <img width={30} height={30} src={header?.img} />}
                                    <Stack>
                                        <TableSortLabel
                                            style={{
                                                display: 'block',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}
                                            id={index}
                                            active={index == orderBy}
                                            direction={index == orderBy ? order : 'desc'}
                                            onClick={() => handleRequestSort(index)}>
                                            {header?.headertype == 'user' ? <UserName user_id={header?.name} discord={true} avatar={true} size={25} /> : header?.name}
                                        </TableSortLabel>
                                        {header?.subtitle && <Typography variant='caption' color='text.secondary'>{header?.subtitle}</Typography>}
                                    </Stack>

                                </Stack>
                            </Tooltip>
                            {Array.isArray(c) && <IconButton onClick={() => {
                                let columnswitcher = [...ColumnSwitcher]
                                columnswitcher[index] = columnswitcher[index] + 1 > c.length - 1 ? 0 : columnswitcher[index] + 1
                                setColumnSwitcher(columnswitcher)
                            }}>
                                <SwapHorizIcon />
                            </IconButton>}
                        </Stack>
                    </TableCell>
                })}
            </TableRow>
        </TableHead>
        <TableBody>
            {rows.map((row, index) => (
                <TableRow
                    key={index}
                >
                    {headers.map((h, hi) => {
                        let header = h
                        if (Array.isArray(h)) {
                            header = h[ColumnSwitcher[hi]] ?? h[0]
                        }
                        return <TableCell key = {index + "_" + hi} align="left" sx={{ padding: '5px 8px', width: (hi == 0 ? { xs: "30px", md: '200px' } : "150px") }} style={hi == 0 ? { position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#222' } : null} >{(
                            header?.type == 'user' ? <UserName user_id={row.user} discord={true} avatar={true} size={25} link = {true} /> :
                                header?.type == 'track' ? <TrackName track={row.track} thumb={true} /> :
                                    header?.type == 'planet' ? <PlanetName planet={row.planet} /> :
                                        header?.type == 'circuit' ? <CircuitName circuit={row.circuit} /> :
                                            header?.type == 'racer' ? <RacerName racer={row.racer} /> :
                                                header?.type == 'run' ? <Stack color={row[header?.id]?.record ? '#00FF00' : row[header?.id]?.leader ? '#FFFFFF' : '#888888'} direction='row' spacing={1} alignItems='center'><img src={`/flags/${row[header?.id]?.pod}.png`} width={20} height={15} /><TimeDisplay time={row[header?.id]?.leader ? row[header?.id]?.time : row[header?.id]?.time - Object.values(row).find(run => run.leader)?.time} TimeDiff={row[header?.id]?.leader ? false : true} /></Stack> :
                                                    <CustomProgress value={100 * row[header?.id] / header?.max} color={header?.color} label={[null, undefined, "", 0].includes(row[header?.id]) ? '--' : (header?.type == 'time' ? timestring(timefix(row[header?.id])) : numberWithCommas(row[header?.id])) + (header?.type == 'percent' ? "%" : "")} />
                        )}</TableCell>
                    }
                    )}

                </TableRow>
            ))}
        </TableBody>
    </Table></TableContainer>)
}