import { useState, useEffect } from 'react';
import { projectDatabase } from '../firebase/config';

export function useRoles(id) {
    const [roles, setRoles] = useState([]);
    const rolesMap = {
        '862810190072381471': 'TOURNEY_ADMIN',
        '449379109215404033': 'SRC_ADMIN',
        '442316823950786575': 'ADMIN',
        '441841455221178368': 'ADMIN'
    }

    useEffect(() => {
        const rolesRef = projectDatabase.ref('users').orderByChild('discordID').equalTo(id ?? "").limitToFirst(1);
        if (id) {
            rolesRef.once('value', (snapshot) => {
                if (snapshot.exists()) {
                    const rolesData = snapshot.val();
                    const userRoles = Object.values(rolesData)[0]?.discord?.roles
                    if (userRoles) {
                        const mapped = userRoles.map(v => rolesMap[v]).filter(v => ![null, undefined, ''].includes(v))
                        setRoles(mapped)
                    }
                } else {
                    console.log('no user found')
                }
            });
        }

    }, []);

    return roles;
}