import { Container, Table, TableBody, TableCell, TableHead, TableRow, Stack, Slider, FormControl, InputLabel, Grid, Select, MenuItem, Typography, Input } from '@mui/material';
import React, { useState } from "react";
import TrackSelect from '../../controls/TrackSelect';
import MultiUpgradeSelect from '../../controls/MultiUpgradeSelect';
import { useRacers } from '../../hooks/useRacers';
import { upgradeAcceleration, upgradeAirBrake, upgradeCooling, upgradeRepair, upgradeTopSpeed, upgradeTraction, upgradeTurning, decelerator } from '../../tools';
import { useTracks } from '../../hooks/useTracks';
import Title from '../../tools/Title';
import RacerName from '../../components/RacerName';
import TimeDisplay from '../../tools/TimeDisplay';

const lapopts = ["Fast Lap", 1, 2, 3, 4, 5];

export default function Sim() {
    const racers = useRacers()
    const tracks = useTracks()
    const [track, setTrack] = useState(0);
    const [lap, setLap] = useState(3);
    const [fps, setFPS] = useState(24);
    const [upgrades, setUpgrades] = useState(
        {
            acceleration: { part: 5, health: 255 },
            air_brake_interval: { part: 5, health: 255 },
            antiskid: { part: 5, health: 255 },
            cool_rate: { part: 5, health: 255 },
            max_speed: { part: 5, health: 255 },
            repair_rate: { part: 5, health: 255 },
            turn_response: { part: 5, health: 255 }
        }
    )

    function getStats(racer, upgrades) {
        let pod = { ...racers[racer] }
        pod.antiskid += ((upgradeTraction(pod.antiskid, upgrades.antiskid.part) - pod.antiskid) * (upgrades.antiskid.health / 255))
        pod.turn_response += ((upgradeTurning(pod.turn_response, upgrades.turn_response.part) - pod.turn_response) * (upgrades.turn_response.health / 255))
        pod.acceleration += ((upgradeAcceleration(pod.acceleration, upgrades.acceleration.part) - pod.acceleration) * (upgrades.acceleration.health / 255))
        pod.max_speed += ((upgradeTopSpeed(pod.max_speed, upgrades.max_speed.part) - pod.max_speed) * (upgrades.max_speed.health / 255))
        pod.air_brake_interval += ((upgradeAirBrake(pod.air_brake_interval, upgrades.air_brake_interval.part) - pod.air_brake_interval) * (upgrades.air_brake_interval.health / 255))
        pod.cool_rate += ((upgradeCooling(pod.cool_rate, upgrades.cool_rate.part) - pod.cool_rate) * (upgrades.cool_rate.health / 255))
        pod.repair_rate += ((upgradeRepair(pod.repair_rate, upgrades.repair_rate.part) - pod.repair_rate) * (upgrades.repair_rate.health / 255))
        return pod
    }

    function simulate(track, pod, FPS, fLap = false, State = {}) {

        if (Object.keys(State).length === 0) {
            State.distance = 0
            State.timer = 0
            State.frameTime = 1 / FPS


            State.speedValue = (lap == 0) ? 10000 : 0
            State.boostValue = 0
            State.thrustValue = 1.0 // max thrust
            State.pitch = 0.8 // max ndown

            State.heat = 0
            State.boostCharge = (lap == 0) ? 1 : 0
            State.startBoost = true
            State.boosting = (lap == 0) ? true : false
            State.boostDelay = -1

            State.isVirtual = false
        }

        let endingDistance = (pod.max_speed + pod.boost_thrust) * (100 / pod.heat_rate) + pod.max_speed
        let trackLength
        if (lap == 0) trackLength = track.lap.length
        else trackLength = track.lap.length * lap

        while (State.distance < trackLength) {

            if (State.heat + (pod.heat_rate * State.frameTime) > 100) {
                State.boosting = false
                State.thrustValue = 0.1
            }
            else if (State.boostDelay < 0) {
                if ((State.heat - (pod.cool_rate * State.frameTime) < 0) && (State.boostCharge >= 1.0)) {
                    State.boosting = true
                }
            }
            else if (State.boostDelay >= 0) {
                if (State.boostCharge >= 1.0) {
                    if (State.boostDelay == 0) {
                        State.boosting = true
                    }
                    State.boostDelay--
                }
            }

            let thrust = State.thrustValue + (0.4 * State.pitch)
            let speedMod = (State.boosting || State.startBoost) ? 4 : 1.5

            State.speedValue = State.speedValue + (State.frameTime * speedMod * thrust)
            if ((thrust < 0.99 && (thrust / (1 - thrust)) > State.speedValue) || (thrust > 1 && State.speedValue > 10000)) {
                State.speedValue = State.speedValue * decelerator(State.frameTime, pod.deceleration_interval)
            }

            let speed = (State.speedValue * pod.max_speed) / (pod.acceleration + State.speedValue)

            if (State.boosting) {
                State.boostValue = State.boostValue + (State.frameTime * 1.5)
                State.heat = Math.min(State.heat + pod.heat_rate * State.frameTime, 100)
            }
            else {
                if (State.boostValue > 0) {
                    State.boostValue *= decelerator(State.frameTime, 5.0)
                }
                if (State.boostValue < 0.001) {
                    State.boostValue = 0
                }
                State.heat -= pod.cool_rate * State.frameTime
                if (State.heat < 0) {
                    State.heat = 0
                }
            }

            let boost = (State.boostValue > 0) ? (State.boostValue * pod.boost_thrust / (State.boostValue + 0.33)) : 0
            let speedometer = speed + boost

            if (!State.boosting && speedometer >= (pod.max_speed * 0.75) && (State.thrustValue >= 1.0)) {
                if (State.boostCharge < 1) {
                    State.boostCharge += State.frameTime
                }
            } else {
                State.boostCharge = 0
            }

            if (State.startBoost && speedometer >= 290) {
                State.startBoost = false
            }

            State.timer += State.frameTime
            State.distance += speedometer * State.frameTime

            State.thrustValue = 1.0

            if (((trackLength - State.distance) < endingDistance) && !State.isVirtual) {

                let itt = 0
                let best = 999999
                while (itt < 500) {
                    let endState = Object.assign({}, State)
                    if (endState.boosting) {
                        endState.boosting = false
                        endState.thrustValue = 0.1
                    }
                    endState.boostDelay = itt
                    endState.isVirtual = true

                    let s = simulate(track, pod, FPS, fLap, endState)
                    if (s < best) best = s

                    itt++
                }
                return best
            }

        }

        return State.timer

    }

    let simulation = []

    if (tracks.length && racers.length) {
        for (let i = 0; i < 23; i++) {
            let stats = getStats(i, upgrades)
            simulation.push({ racer: i, time: simulate(tracks[track], stats, fps) })
        }
    }

    simulation.sort((a, b) => a.time - b.time)


    return (
        <Container sx={{ minHeight: '100vh' }}>
            <Grid container spacing={3} direction='row-reverse' >
                <Grid item xs={12} md={4}>
                    <Stack spacing={1}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Track
                            </InputLabel>
                            <TrackSelect track={track} onChange={(event, newValue) => {
                                setTrack(event.target.value)
                            }} />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Laps
                            </InputLabel>
                            <Select
                                value={lap}
                                onChange={(event) => {
                                    setLap(event.target.value);
                                }}
                                variant="standard"
                            >
                                {lapopts.map((l, i) => <MenuItem elevation={0} value={i}>{l}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <Typography variant='caption' color='text.secondary' gutterBottom>
                                FPS
                            </Typography>
                            <Stack direction='row' spacing={2}>
                                <Slider
                                    valueLabelDisplay="auto"
                                    defaultValue={24}
                                    step={1}
                                    min={24}
                                    max={144}
                                    sx={{ color: '#00ffff' }}
                                    onChangeCommitted={(event, value) => {
                                        setFPS(value)
                                    }}
                                />
                                <Input
                                    value={fps}
                                    size="small"
                                    onChange={(event, value) => {
                                        setFPS(event.target.value)
                                    }}
                                    inputProps={{
                                        step: 1,
                                        min: 24,
                                        max: 144,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                            </Stack>
                        </FormControl>
                        <MultiUpgradeSelect Upgrades={upgrades} options={['acceleration', 'max_speed', 'cool_rate']} onChange={(key, field, value) => {
                            if (value < 0) {
                                return
                            }
                            let Upgrades = { ...upgrades }
                            if (key == 'all') {
                                Object.keys(Upgrades).forEach(k => {
                                    if (field == 'part') {
                                        Upgrades[k].part = value
                                    } else if (field == 'health') {
                                        Upgrades[k].health = value
                                    }
                                })
                            } else {
                                Upgrades[key][field] = value
                            }
                            setUpgrades(Upgrades)
                        }} />

                    </Stack>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Racer
                                </TableCell>
                                <TableCell>
                                    Time
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {simulation.map(s => (
                                <TableRow>
                                    <TableCell>
                                        <RacerName racer={s.racer} />
                                    </TableCell>
                                    <TableCell>
                                        <TimeDisplay time={s.time} />
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </Grid>

            </Grid>
        </Container>


    )

}