import { Stack, Slider, FormControl, Typography, Box } from "@mui/material"
import { useParts } from "../hooks/useParts"
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import { useState } from "react";
import { ReactSVG } from "react-svg";
const UpgradeNames = {
    acceleration: 'Accel.',
    air_brake_interval: 'Brake',
    antiskid: 'Traction',
    cool_rate: 'Cooling',
    max_speed: 'Top Speed',
    repair_rate: 'Repair',
    turn_response: 'Turning'
}

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#00ffff',
    },
    '& .MuiRating-iconHover': {
        color: '#00ffff',
    }
});

const StyledSlider = styled(Slider)`
.MuiSlider-rail {
    width: 16px; /* Adjust the height as desired */
    opacity: 0.5; /* Adjust the opacity as desired */
  }
  .MuiSlider-track {
    width: 16px; /* Adjust the height as desired */
  }
  .MuiSlider-thumb {
    display: none;
  }
`;


export default function UpgradeSelect({ Upgrades, onChange, keyid }) {
    const CustomIcon = ({ keyid, level }) => (
        <ReactSVG src={`/parts/_${keyid}${level}.svg`}
            beforeInjection={(svg) => {
                svg.setAttribute('fill', hover > -1 ? (level <= hover - 1 ? '#00FFFF' : '#555555') : Upgrades?.[keyid]?.part <= level - 1 ? '#555555' : '#00FFFF'); // Set the desired color
            }}
            style={{ width: '42px', height: '42px' }}
        />
    );

    const customIcons = {
        1: {
            icon: <CustomIcon keyid={keyid} level={0} />,
        },
        2: {
            icon: <CustomIcon keyid={keyid} level={1} />,
        },
        3: {
            icon: <CustomIcon keyid={keyid} level={2} />,
        },
        4: {
            icon: <CustomIcon keyid={keyid} level={3} />,
        },
        5: {
            icon: <CustomIcon keyid={keyid} level={4} />,
        },
        6: {
            icon: <CustomIcon keyid={keyid} level={5} />
        }
    };

    function IconContainer(props) {
        const { value, ...other } = props;
        return <span {...other}>{customIcons[value]?.icon}</span>;
    }

    IconContainer.propTypes = {
        value: PropTypes.number.isRequired,
    };
    const parts = useParts()
    const [hover, setHover] = useState(-1);
    return (
        <Stack width='100%' direction='row' spacing={1} alignItems='center'>
            <Stack>
                <FormControl fullWidth>
                    <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
                        <Stack direction='row' spacing={1} alignItems='center'>
                            <img width={20} height={20} src={parts?.[keyid]?.img}></img>
                            <Stack direction='row' spacing={0.5} alignItems='center'>
                                <Typography variant='subtitle2' sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: 'text.primary'
                                }}>{parts?.[keyid]?.upgrades[hover !== -1 ? hover - 1 : Upgrades[keyid].part]?.name}</Typography>
                                <Typography variant='caption' color='text.secondary'>
                                    {`• ${UpgradeNames[keyid]}`}
                                </Typography>
                            </Stack>
                        </Stack>
                        {<Typography variant='caption' color='text.secondary'>
                            {Upgrades[keyid].health < 255 ? `${Upgrades[keyid].health}/255` : 'MAX'}
                        </Typography>}
                    </Stack>
                    <StyledRating
                        name="customized-color"
                        defaultValue={Upgrades?.[keyid]?.part + 1}
                        value={Upgrades?.[keyid]?.part + 1}
                        getLabelText={(value) => `${parts?.[keyid]?.[value - 1]?.name}`}
                        onChange={(event, newValue) => {
                            onChange(keyid, 'part', newValue - 1)
                        }}
                        onChangeActive={(event, newHover) => {
                            //setHover(newHover);
                        }}
                        precision={1}
                        size='large'
                        max={6}
                        IconContainerComponent={IconContainer}
                    />
                </FormControl>
            </Stack>
            <Box height={'60px'}>
                <StyledSlider
                    size='small'
                    aria-label="Temperature"
                    valueLabelDisplay="on"
                    value={Upgrades?.[keyid]?.health}
                    orientation='vertical'
                    step={1}
                    key={'slider'}
                    min={0}
                    max={255}
                    sx={{ color: `rgba(${255 - Upgrades[keyid]?.health}, ${Upgrades[keyid]?.health}, 0, 255)` }}
                    onChangeCommitted={(event, value) => {
                        onChange(keyid, 'health', value)
                    }}
                />
            </Box>
        </Stack>)
}