import { Typography, LinearProgress, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function CustomProgress({ color, label, value }) {
    function interpolateHexColor(startColor, middleColor, endColor, percentage) {
        const startRGB = startColor.match(/\w\w/g).map((c) => parseInt(c, 16));
        const middleRGB = middleColor.match(/\w\w/g).map((c) => parseInt(c, 16));
        const endRGB = endColor.match(/\w\w/g).map((c) => parseInt(c, 16));

        const interpolatedRGB = startRGB.map((startValue, index) => {
            const middleValue = middleRGB[index];
            const endValue = endRGB[index];
            let value;

            if (percentage <= 50) {
                value = Math.round(startValue + ((middleValue - startValue) * (percentage / 50)));
            } else {
                value = Math.round(middleValue + ((endValue - middleValue) * ((percentage - 50) / 50)));
            }

            return value;
        });

        const interpolatedHex = interpolatedRGB
            .map((value) => value.toString(16).padStart(2, '0'))
            .join('');

        return `#${interpolatedHex}`;
    }

    const gradientStart = '#5b54f5';
    const gradientMiddle = '#a059b7';
    const gradientEnd = '#e85c57';

    const interpolatedColor = interpolateHexColor(gradientStart, gradientMiddle, gradientEnd, value);
    const theme = createTheme({
        components: {
            MuiLinearProgress: {
                styleOverrides: {
                    barColorPrimary: {
                        backgroundColor: color,
                    },
                    root: {
                        backgroundColor: '#555555',
                    },
                    bar: {
                        backgroundImage: color == 'boost' ? `linear-gradient(to right, #5b54f5 ${100 - value}%, #a059b7 ${150 - value}%, #e85c57 ${200 - value}%)` : null,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'left'
                    },
                },
            },
            Box: {
                styleOverrides: {
                    root: {
                        opacity: '75%',
                        '&:hover': {
                            opacity: '100%'
                        }
                    }
                },
            }
        },
    });
    return (<ThemeProvider theme={theme}>
        <Box sx={{
            opacity: 0.75,
            '&:hover': {
                opacity: 1
            }
        }}><Typography sx={{ fontFamily: 'monospace', textDecoration: 'none', color: color == 'boost' ? interpolatedColor : color }}>
                {label}
            </Typography>
            <LinearProgress
                color='primary'
                variant="determinate"
                value={value}
            />
        </Box>
    </ThemeProvider>)
}