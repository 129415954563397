import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getRanks } from './getRanks';
import { Typography, Container, Alert, Stack, Badge, Tabs, Tab, Paper, Box } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { timefix } from '../../../tools.js'
import { Helmet } from 'react-helmet'
import { useLocation, useHistory } from 'react-router-dom'
import Title from '../../../tools/Title';
import UserName from '../../../tools/UserName';
import { useMatches } from '../../../hooks/useMatches';
import { useRulesets } from '../../../hooks/useRulesets';
import { useUsers } from '../../../hooks/useUsers';
import { ordinalSuffix } from '../../../tools/OrdinalSuffix';
import { placementcolors } from '../leaderboards/PlacementColors';
import { getWinner, getScore } from '../../../hooks/useTourneyRuns';
export default function Ranks() {
  const history = useHistory()
  const location = useLocation();
  const matches = useMatches()
  const rulesets = useRulesets()
  const { users, loading, error } = useUsers()
  function timestring(time) {
    if (time.includes(":")) {
      if (time.replace(":", "").includes(":")) {
        return time.replace(":", "h ").replace(":", "m ").replace(".000", "s")
      } else {
        return time.replace(":", "m ").replace(".000", "s")
      }
    }
  }
  function getRankings(matches, rulesets) {
    let ranks = null
    if (matches.length && users.length) {
      let stats = {}
      ranks = getRanks(Object.values(matches), Date.now(), rulesets)
      let pastmatches = Object.values(matches).sort((a, b) => b.datetime - a.datetime)
      let comparedate = pastmatches[15].datetime
      let compareranks = getRanks(Object.values(matches), comparedate, rulesets)
      compareranks = Object.keys(compareranks).map(key => {
        return { player: key, rank: compareranks[key].rank, change: compareranks[key].change, matches: compareranks[key].matches }
      }).filter(r => r.matches >= 4).sort(function (a, b) {
        return b.rank - a.rank
      })

      Object.values(matches).forEach(match => {
        let players = []
        if (match.players) {
          players = Object.values(match.players)
        } else if (match.races) {
          players = Object.values(match.races[0].runs).map(run => run.player)
        }
        let score = {}
        players.forEach(player => {
          if (!stats[player]) {
            stats[player] = {
              matches: {
                total: 0,
                won: 0,
                lost: 0,
                forfeit: 0
              },
              races: {
                total: 0,
                won: 0,
                lost: 0,
                dnf: 0
              },
              fp: {
                total: 0,
                sk: 0,
                nu: 0,
                fl: 0,
                podban: 0
              },
              deaths: [],
              playtime: 0
            }
          }
          stats[player].matches.total++
          score[player] = { player: player, score: 0 }
        })
        if (match.forfeit?.player) {
          score[match.forfeit.player] = -1
          //stats[match.forfeit.player].matches.lost ++ //true losses are already counted separately
          stats[match.forfeit.player].matches.forfeit++
          players.filter(p => p !== match.forfeit.player).forEach(p => {
            stats[p].matches.won++
          })
        }
        if (match.races) {
          Object.values(match.races).forEach(race => {
            if (race.events) {
              Object.values(race.events).forEach(event => {
                if (event.cost && event.cost > 0) {
                  stats[event.player].fp.total++
                  if (event.event == 'override') {
                    stats[event.player].fp[event.selection]++
                  }
                  if (event.event == 'tempban' && event.type == 'racer') {
                    stats[event.player].fp.podban++
                  }
                }
              })
            }
            let winner = { time: null, player: null }
            if (race.runs) {
              Object.values(race.runs).forEach(run => {
                stats[run.player].races.total++
                if (![null, undefined, ""].includes(run.deaths)) {
                  stats[run.player].deaths.push(Number(run.deaths))
                }
                if (![null, undefined, "", "DNF"].includes(run.time)) {
                  stats[run.player].playtime += Number(run.time)
                }
                if (winner.time && run.time !== 'DNF' && run.time - winner.time < 0) {
                  winner.time = run.time
                  winner.player = run.player
                } else if (!winner.time && run.time !== 'DNF') {
                  winner.time = run.time
                  winner.player = run.player
                }
                if (run.time == 'DNF') {
                  stats[run.player].races.dnf++
                }
              })
              if (!['Qualifier', '1vAll'].includes(rulesets[match.ruleset]?.general.type) && winner.player !== null && score[winner.player]) {
                score[winner.player].score++
                stats[winner.player].races.won++
                players.filter(p => p !== winner.player).forEach(p => {
                  stats[p].races.lost++
                })
              }
            }
          })
          if (!['Qualifier', '1vAll'].includes(rulesets[match.ruleset]?.general.type)) {
            let matchwinner = Object.values(score).sort((a, b) => b.score - a.score)[0].player
            if (match.rta) {
              matchwinner = Object.values(match.rta).sort((a, b) => a.total - b.total)[0].player
            }
            stats[matchwinner].matches.won++
            Object.values(score).map(s => s.player).filter(p => p !== matchwinner).forEach(p => {
              if (p) {
                stats[p].matches.lost++
              }
            })
          }
        }

      })

      ranks = Object.keys(ranks).map(key => {
        return { player: key, user: key, rank: ranks[key].rank, change: ranks[key].change, matches: ranks[key].matches, stats: stats[key] }
      }).filter(r => r.matches >= 4).sort(function (a, b) {
        return b.rank - a.rank
      })
      ranks.forEach((rank, i) => {
        compareranks.forEach((crank, j) => {
          if (crank.player == rank.player) {
            rank.poschange = j - i
          }
        })
      })
    }
    return ranks
  }

  const rankings = useMemo(() => getRankings(matches, rulesets))
  return (
    <Container sx={{ minHeight: '100vh' }}>
      <Helmet>
        <title>Tournament Rankings</title>
      </Helmet>
      <Stack spacing={0}>
        <Paper elevation={0}>
          <Tabs variant="scrollable" scrollButtons="auto" value={location.pathname} onChange={(event, value) => history.push(value)}>
            <Tab label="Speedruns" value="/rankings/speedruns" />
            <Tab label="Tournaments" value="/rankings/tournaments" />
            <Tab label="Challenges" value="/rankings/challenges" />
          </Tabs>
        </Paper>
        <TableContainer component={Paper} sx={{ backgroundColor: '000000' }}>

          {location.pathname.includes('tournaments') &&
            <Table aria-label="simple table" size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Rank</TableCell>
                  <TableCell align="left"><Typography variant="subtitle1">Player</Typography></TableCell>
                  <TableCell align="right">Playtime</TableCell>
                  <TableCell align="center"><Stack justiyContent='center' alignItems='center'>
                    <Typography>Matches</Typography>
                    <Typography variant='caption'>{"W / L / FF"}</Typography>
                  </Stack></TableCell>
                  <TableCell align="center"><Stack justiyContent='center' alignItems='center'>
                    <Typography>Races</Typography>
                    <Typography variant='caption'>{"W / L / DNF"}</Typography>
                  </Stack></TableCell>
                  <TableCell align="center">Deaths/Race</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rankings && rankings.map((row, index) => (
                  <TableRow
                    key={row.player}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'text-decoration': "none", "border-bottom": index < 3 ? '2px ridge ' + placementcolors[index].fg : '' }}
                  >
                    <TableCell sx={{ width: '10%' }} color={index == 0 ? "#BBBBFF" : ""} align='center'>
                      <Badge
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        badgeContent={
                          <Typography variant='caption' color={row.poschange > 0 ? "#00FF00" : "#FF0000"}>
                            {[null, undefined, "", 0].includes(row.poschange) ? "" : row.poschange > 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Typography>
                        }>
                        {index < 3 ? <img src={`/placement flags/fill_${index}.png`} width={33} height={45} /> : <Typography variant="h7" color='text.secondary'>{index + 1}<sup>{ordinalSuffix(index)}</sup></Typography>}
                      </Badge>
                    </TableCell>
                    <TableCell align="left">
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}>
                        <UserName user_id={row.user} discord={true} avatar={true} link={true}
                          sub={
                            <Typography variant='caption' sx={{ textDecoration: 'none' }}>{row.rank.toFixed(0)}</Typography>
                          } />
                      </Stack>
                    </TableCell>
                    <TableCell align="right"><Typography variant='button'>{timestring(timefix(row.stats.playtime.toFixed(0)))}</Typography></TableCell>
                    <TableCell align="center">
                      <Stack justiyContent='center' alignItems='center'>
                        <Typography sx={{ textDecoration: 'none' }}>{row.stats.matches.total}</Typography>
                        <Typography color='text.secondary' variant='caption'>{row.stats.matches.won + " / " + row.stats.matches.lost + " / " + row.stats.matches.forfeit}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="center"><Stack justiyContent='center' alignItems='center'>
                      <Typography sx={{ textDecoration: 'none' }}>{row.stats.races.total}</Typography>
                      <Typography color='text.secondary' variant='caption'>{row.stats.races.won + " / " + row.stats.races.lost + " / " + row.stats.races.dnf}</Typography>
                    </Stack></TableCell>
                    <TableCell align="center"><Typography sx={{ textDecoration: 'none' }}>{(row.stats.deaths.reduce((a, b) => a + b) / row.stats.deaths.length).toFixed(2)}</Typography></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        </TableContainer>
        {location.pathname.includes('tournaments') && <Alert sx={{ padding: 2, margin: 3 }} severity="info">The above ranks are calculated using an Elo rating system. Each player starts with a rank of 1000 and each win or loss raises or lowers their rank depending on the number of matches played and the opponent's rank. Forfeiting or failing to show up for a match does not affect ranking. Racers must play at least 4 ranked matches to be listed.</Alert>}
        {(location.pathname.includes('speedruns') || location.pathname.includes('challenges')) && <Alert sx={{ padding: 2, margin: 3 }} severity="info">Nothing to see here yet.</Alert>}
      </Stack>
    </Container>
  );
}
