import { useEffect, useState } from "react";
import { projectDatabase } from "../firebase/config";

export function useUser({ userId, discord }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getUser = async (id) => {
      if (!['string', 'number'].includes(typeof id)) {
        return {}
      }
      const user_id = id.trim()
      // Check if the "users" data is already cached
      const usersCacheKey = 'users';
      const cachedUsers = localStorage.getItem(usersCacheKey);
      if (cachedUsers) {

        // If "users" data is cached, check if the specific user is present
        const parsedUsers = JSON.parse(cachedUsers);
        let cachedUser = parsedUsers.find(u => u.id == user_id) ?? parsedUsers.find(u => u.discordID == user_id) ?? null
        if (cachedUser) {
          // If the specific user is found in the cache, use it
          //console.log('using cachedUser', cachedUser)
          return cachedUser
        }
      }
      // If the specific user is not cached, fetch it from Firebase
      try {
        let dsnapshot = await projectDatabase.ref(`users`).orderByChild('discordID').equalTo(user_id).limitToFirst(1).once('value');
        let snapshot = await projectDatabase.ref(`users/${id}`).once('value');
        let fetchedUser = snapshot.val();
        let ds = dsnapshot.val()
        if (ds) {
          fetchedUser = Object.values(ds)[0]
        }
        const fetchedUserwithID = { ...fetchedUser, id: snapshot.key }
        const updatedUsers = cachedUsers ? JSON.parse(cachedUsers) : [];
        const updatedUsersWithNewUser = [...updatedUsers, fetchedUserwithID];
        localStorage.setItem(usersCacheKey, JSON.stringify(updatedUsersWithNewUser));
        return fetchedUserwithID
      } catch (error) {
        // Handle error
        console.error('Error fetching user:', error);
      }
    };


    setLoading(false);
    Promise.all([userId].flat().map(id => getUser(id))).then(u => {
      setUsers(u);
    })
  }, [userId]);
  return { user: (typeof userId == 'object' ? users : users[0]), user_ref: projectDatabase.ref("users/" + (['string', 'number'].includes(typeof userId) ? userId : '')) };
}
