import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import UserName from '../../../tools/UserName';
const CustomColor = styled(Typography)(({ theme }) => ({
    link: {
        color: '#333333',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: '#ffffff'
        },
        fontSize: '12px'
    },
    fontSize: '12px',
    color: '#777777',
    '&:hover': {
        color: '#ffffff'
    },
}));

export default function RunTourneyDesc(run) {

    if (run.tourney) {
        return <Link
            to={`/tournaments/${run.tourney.id}/matches/${run.match}`}
            style={{ textDecoration: 'none', color: 'inherit', fontSize: '12px' }}>
            <CustomColor>
                {run.tourney.nickname + " " + run.tourney.stages[run.bracket]?.bracket + " " + run.tourney.stages[run.bracket]?.round}
                <br />
                {"Race " + run.num + " vs " + (run.opponents.length > 2 ? run.opponents.length + " Players" : run.opponents.join(", "))}
            </CustomColor>
        </Link>
    } else {
        return 'Practice'
    }
}