import { Typography, Grid, Card, Stack, CardContent, CardActionArea, Container, Box, Button } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useMatches } from '../../../hooks/useMatches';
import { useUsers } from '../../../hooks/useUsers';
import { useTournaments } from '../../../hooks/useTournaments';
import { useRulesets } from '../../../hooks/useRulesets';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Title from '../../../tools/Title';
import { Link } from 'react-router-dom'
import HeroBanner from '../../stats/HeroButton';
import { useAuthContext } from '../../../hooks/useAuthContext.js';
export default function Match() {
  const matches = useMatches()
  const { users, loading, error } = useUsers()
  const rulesets = useRulesets()
  const tourneys = useTournaments()
  const { user } = useAuthContext()
  const conmap = {
    l3: "3 Laps",
    l2: "2 Laps",
    l1: "1 Lap",
    l4: "4 Laps",
    l5: "5 Laps",
    um: "Unmirrored",
    mi: "Mirrored",
    tt: "Total Time",
    fl: "Fast Lap",
    ft: "Full Track",
    sk: "Skips",
    mu: "Upgrades Allowed",
    nu: "No Upgrades",
    ng: "New Game"
  }

  function convertDate(unix) {
    let date = new Date(unix);
    let year = date.getUTCFullYear();
    let month = date.getUTCMonth() + 1;
    let day = date.getUTCDate();
    let dateString = year + "-" + month + "-" + day;
    return dateString
  }

  if (tourneys.length && matches) {
    Object.keys(matches).forEach(key => {
      let match = matches[key]
      let tourney = tourneys.find(t => t.id == match.tourney)
      if (![undefined, null, "", 'practice'].includes(match.tourney)) {
        if (!tourney.matches) {
          tourney.matches = 0
        }
        tourney.matches++
      }

    })

    tourneys.forEach(tourney => {
      tourney.oldest = tourney.startdate
      tourney.newest = null
      tourney.players = []
      tourney.matches = 0
      tourney.races = 0
      tourney.deaths = 0
    })



    Object.values(matches).forEach(match => {
      let tourney = tourneys.find(t => t.id == match.tourney)
      if (![undefined, null, "", 'practice'].includes(match.tourney)) {
        tourney.matches++

        if (tourney.oldest == null || match.datetime < tourney.oldest) {
          tourney.oldest = match.datetime
        }
        if (tourney.newest == null || match.datetime > tourney.newest) {
          tourney.newest = match.datetime
        }
        if (match.races) {
          Object.values(match.races).forEach(race => {
            tourney.races++
            if (race.runs) {
              Object.values(race.runs).forEach(run => {
                if (!tourney.players.includes(run.player)) {
                  tourney.players.push(run.player)
                }
                if (![undefined, null, ""].includes(run.deaths)) {
                  tourney.deaths += Number(run.deaths)
                }
              })
            }
          })
        }
      }
    })
  }


  return (
    <Container sx={{ minHeight: '100vh' }}>
      <Box mb={2}><HeroBanner title='GALACTIC TOURNAMENT 2023' link='/tournaments/2023' height={300} img='https://cdn.discordapp.com/attachments/441842584592056320/749704685690028042/Episode_I_Racer_8_30_2020_2_42_20_PM.png' /></Box>
      {tourneys && matches && rulesets && users &&
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          {tourneys.sort((a, b) => b.oldest - a.oldest).map((tourney, index) => {
            return <Grid item lg={4} xs={12}>
              <Card sx={{ minWidth: 275 }} maxHeight elevation={0}>
                <CardActionArea component={Link} to={'/tournaments/' + tourney.nickname.toLowerCase().replaceAll(" ", "_")}>
                  <CardContent>
                    <Stack alignItems='center'>
                      <Typography variant='h6' >
                        {tourney.name}
                      </Typography>
                      <Typography variant='caption' color='text.secondary'>{convertDate(tourney.oldest) + " - " + (tourney.newest ? convertDate(tourney.newest) : "present")}</Typography>
                      <Stack direction='row' spacing={1} justifyContent="space-around">
                        <Stack direction='row' alignItems='center'>
                          <SupervisorAccountIcon sx={{ color: 'text.secondary' }} /><Typography variant='caption' >{tourney.players.length}</Typography>
                        </Stack>
                        <Stack direction='row' alignItems='center'>
                          < EventIcon sx={{ color: 'text.secondary' }} /><Typography variant='caption' >{tourney.matches}</Typography>
                        </Stack>
                        <Stack direction='row' alignItems='center'>
                          <EmojiFlagsIcon sx={{ color: 'text.secondary' }} /><Typography variant='caption' >{tourney.races}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          })}
          {user &&
            <Grid item lg={4} xs={12}>
              <Button sx={{ width: '100%', height: '100%' }} variant='outlined' >
                New Tournament
              </Button>
            </Grid>}
        </Grid>
      }
    </Container>
  )
}
