import { useRulesets } from "../../../hooks/useRulesets"
import { useTournaments } from "../../../hooks/useTournaments"
import { useMatches } from "../../../hooks/useMatches"

import { getMatchDesc, getPlayers } from "../../../hooks/useTourneyRuns"
import { Stack, Typography, Button, Box, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material"
import { useAuthContext } from '../../../hooks/useAuthContext.js';
import UserName from "../../../tools/UserName"
import VideoEmbed from '../../../components/VideoEmbed.js';

import StarsIcon from '@mui/icons-material/Stars';
import EventIcon from '@mui/icons-material/Event';
import GavelIcon from '@mui/icons-material/Gavel';
import MicIcon from '@mui/icons-material/Mic';
import EditIcon from '@mui/icons-material/Edit';
import { getRanks } from "../ranking/getRanks"
import UnformattedLink from "../../../components/Link"
import { useRoles } from "../../../hooks/useRoles"

export default function MatchHeader({ match, setEdit }) {
    const matches = useMatches()
    const rulesets = useRulesets()
    const tourneys = useTournaments()
    const ruleset = rulesets.find(r => r.id == match.ruleset)
    const tourney = tourneys.find(t => t.id == match.tourney)
    const players = getPlayers(match)
    const previousranks = getRanks(matches, match.datetime - 1, rulesets)
    const { user } = useAuthContext()
    const roles = useRoles(user?.id)
    return (
        <Box>
            <Stack direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}>
                <UnformattedLink to={`/tournaments/${tourney?.nickname?.toLowerCase().replaceAll(" ", "_").replaceAll(".", "")}`}><Typography variant='h6'>{getMatchDesc({ match, tournament: tourney })}<br /></Typography></UnformattedLink>
                {user && roles.includes('TOURNEY_ADMIN') && <Button><EditIcon onClick={() => setEdit(true)} /></Button>}
            </Stack>
            <Stack>
                <Typography color="text.secondary"><EventIcon sx={{ fontSize: 16 }} /> {new Date(match?.datetime).toLocaleString()}</Typography>
                <Typography color="text.secondary"><GavelIcon sx={{ fontSize: 16, }} /> {ruleset?.general?.name}</Typography>
                {match?.commentators && <Stack direction='row' alignItems='center' spacing={1}><MicIcon color="text.secondary" sx={{ fontSize: 16 }} />{Object.values(match?.commentators).map(com => <UserName user_id={com} discord={true} />)}</Stack>}
            </Stack>
            {ruleset?.general.type !== '1v1' &&
                <Stack height='100px' p={2} direction='row' justifyContent={'center'} alignItems='center' spacing={2}>{(players.length > 3 ? <Typography>{players.length + " Players"}</Typography> : players.map(p => (<UserName user_id={p} discord={true} avatar={true} />)))}</Stack>
            }
            {ruleset?.general.type == '1v1' &&
                <Stack
                    direction='row'
                    height='100px'
                    container
                    justifyContent="space-around"
                    alignItems="center"
                    p={2}
                >
                    {players.map((p, i) => (
                        <>
                            <Stack alignItems='center'>
                                <UserName
                                    user_id={p}
                                    discord={true}
                                    avatar={true}
                                    link={true}
                                    sub={ruleset?.general.ranked && previousranks[p] && <Stack alignItems="center" direction='row'>
                                        <StarsIcon sx={{ fontSize: 12 }} /><Typography variant='caption'>{previousranks[p].rank.toFixed(0)}</Typography>
                                    </Stack>} />
                            </Stack>
                            {i == 0 && <Typography justify='center' variant='h5' sx={{ textDecoration: 'none', fontWeight: 700 }}>VS</Typography>}
                        </>
                    ))}
                </Stack>}
            <Box>{match?.vod &&
                <VideoEmbed url={match.vod} />
            }</Box>
        </Box>
    )
}