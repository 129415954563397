import { Card, Typography, Button, Stack } from "@mui/material";
import firebase from 'firebase/app';
import 'firebase/database';
import { useState, useEffect } from "react";
import TrackName from "../../components/TrackName";
import RacerName from "../../components/RacerName";
import CoolCard from "./CoolCard";
import { Link } from 'react-router-dom'
export default function ChallengeOfTheDay() {

    const [challenge, setChallenge] = useState(null)
    useEffect(() => {
        const userquery = firebase.database().ref('challenge/challenges').orderByChild('type').equalTo('cotd').limitToLast(1);
        userquery.once('value', (snapshot) => {
            setChallenge(Object.values(snapshot.val())[0])
        });
    }, []);
    return (
        <CoolCard title='RANDOM CHALLENGE OF THE DAY' color='#FF0000' action='JOIN CHALLENGE' action_link='https://discord.gg/star-wars-episode-i-racer-441839750555369474'>
            {challenge &&
                <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography>Race as </Typography>
                    <RacerName racer={challenge?.racer} />
                    {challenge?.conditions?.nu && <Typography> with NO UPGRADES </Typography>}
                    <Typography> on </Typography>
                    <TrackName track={challenge?.track} />
                    {challenge?.conditions?.laps !== 3 && <Typography>{` for ${challenge.conditions.laps} Laps `}</Typography>}
                    {challenge?.conditions?.skips && <Typography>{` with SKIPS `}</Typography>}
                    {challenge?.conditions?.mirrored || challenge?.conditions?.backwards && <Typography>{challenge?.conditions?.mirrored & challenge?.conditions?.backwards ? ', MIRRORED and BACKWARDS!' : challenge?.conditions?.mirrored ? ', MIRRORED!' : challenge?.conditions?.backwards ? ', BACKWARDS!' : ''}</Typography>}
                </Stack>
            }
        </CoolCard>
    )
}