import { Grid, Button, Stack, List, Box, ListItemButton, TextField, IconButton, Typography, ListItem } from "@mui/material"
import UpgradeSelect from "./UpgradeSelect";
import { useAuthContext } from "../hooks/useAuthContext";
import { projectDatabase } from "../firebase/config";
import { useState, useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { ReactSVG } from "react-svg";
export default function MultiUpgradeSelect({ Upgrades, onChange, options, setUpgrades }) {
    const [presets, setPresets] = useState([])
    const [saveName, setSaveName] = useState('')
    const [userID, setuserID] = useState(null)
    const { user } = useAuthContext()
    const userRef = user ? projectDatabase.ref(`users`).orderByChild('discordID').equalTo(user.id).limitToFirst(1) : null
    const keyorder = ['antiskid',  'turn_response', 'acceleration', 'max_speed', 'air_brake_interval', 'cool_rate', 'repair_rate',]
    useEffect(() => {
        const fetchData = async () => {
            userRef.on('value', (snapshot) => {
                const data = snapshot.val();
                let user = Object.values(data)[0]
                if (user) {
                    setuserID(Object.keys(data)[0])
                }
                if (data && user?.presets) {
                    setPresets(user?.presets);
                } else {
                    setPresets([]);
                }
            });
        }
        if (user) {
            fetchData()
        }
    }, [user])


    const CustomIcon = ({ keyid, level, health }) => (
        <ReactSVG src={`/parts/_${keyid}${level}.svg`}
            beforeInjection={(svg) => {
                svg.setAttribute('fill', `rgba(${255 - health}, ${health}, 0, 255)`); // Set the desired color
            }}
            style={{ width: '32px', height: '32px' }}
        />
    );

    return (
        <Stack >
            <Stack direction='row'>
                <TextField error={saveName === null} defaultValue={saveName} size='small' fullWidth label='Preset Name' variant='standard' onChange={(e) => setSaveName(e.target.value)} />
                <IconButton color='primary' onClick={() => {
                    if (saveName == '' || Object.values(presets).map(p => p.name).includes(saveName)) {
                        setSaveName(null)
                        return
                    }
                    if (userID) {
                        let push = { name: saveName, upgrades: { ...Upgrades } }
                        projectDatabase.ref('users').child(userID).child('presets').push(push)
                        setSaveName('')
                    }
                }}><AddIcon /></IconButton>
            </Stack>

            <Box maxHeight='250px' sx={{ overflowY: 'auto' }} mb={2}>
                <List>
                    {Object.keys(presets).reverse().map(k => (
                        <ListItem sx={{ padding: 0 }} key={k} secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => {
                                projectDatabase.ref('users').child(userID).child('presets').child(k).remove()
                            }}>
                                <DeleteIcon sx={{ color: 'text.secondary' }} />
                            </IconButton>
                        }>
                            <ListItemButton onClick={() => {
                                setUpgrades(JSON.parse(JSON.stringify(presets[k].upgrades)))
                            }} sx={{ padding: 0 }}>
                                <Stack>
                                    <Typography>{presets[k].name}</Typography>
                                    <Stack direction='row'>
                                        {Object.keys(presets[k].upgrades).sort((a, b) => keyorder.indexOf(a) - keyorder.indexOf(b)).map(key => (
                                            <CustomIcon keyid={key} level={presets[k]?.upgrades[key].part} health={presets[k]?.upgrades[key].health} />
                                        ))}
                                    </Stack>
                                </Stack>
                            </ListItemButton>

                        </ListItem>
                    ))}
                </List>
            </Box>
            <Stack spacing={1} direction='row' mb={1} justifyContent='space-between'>
                <Button size='small' onClick={() => {
                    onChange('all', 'part', 0)
                }} >Stock</Button>
                <Button size='small' onClick={() => {
                    onChange('all', 'part', 5)
                }} >Max</Button>
                <Button size='small' onClick={() => {
                    onChange('all', 'health', 255)
                }}>Reset Health</Button>
            </Stack>
            <Grid container justifyContent='center' alignItems='center' >
                {options.map(key => (
                    <Grid item xs={12} mb={3}>
                        <UpgradeSelect Upgrades={Upgrades} onChange={onChange} keyid={key} />
                    </Grid>
                ))}

            </Grid>
        </Stack>
    )
}