import { Button, Container, Grid, Paper, Typography, Stack, Divider } from '@mui/material';
import Link from 'react-router-dom'
import * as React from 'react';
export default function Footer() {

    return (
        <Paper mt={2} elevation={0}>
            <Stack direction='row' justifyContent='center' p={4} alignItems='space-between' divider={<Divider orientation="vertical" flexItem />} spacing = {3}>
                <Stack>
                    <Typography sx={{ my: 1, color: '#777777', fontFamily: 'verdana', fontWeight: 1000 }}>NAVIGATION</Typography>
                    <Typography>Databank</Typography>
                    <Typography>Leaderboards</Typography>
                    <Typography>Rankings</Typography>
                    <Typography>Stats</Typography>
                    <Typography>Tournaments</Typography>
                </Stack>
                <Stack>
                    <Typography sx={{ my: 1, color: '#777777', fontFamily: 'verdana', fontWeight: 1000 }}>SOCIAL</Typography>
                    <Typography>Discord</Typography>
                    <Typography>YouTube</Typography>
                    <Typography>Twitch</Typography>
                </Stack>
            </Stack>
        </Paper>
    )
}
