import { Typography } from "@mui/material"

export default function Title({ text, color }) {
    return (<Typography variant="h2" sx={{
        fontFamily: 'impact',
        fontStyle: 'italic',
        fontSize: {
            xs: '40px',
            sm: '20px',
            md: '30px',
            lg: '40px',
        },
        background: `-webkit-linear-gradient(90deg, ${color} 0%, rgba(255, 0, 0, 0) 120%)`,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        pt: 4,
        pb: 2
    }}>
        {text}
    </Typography>)
}