import { TableRow } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'

export default function CustomTableRow({ dynamicColor, link, children, height }) {
    const useStyles = makeStyles((theme) => ({
        separatorRow: {
            position: 'relative',
            '&::after': {
                content: "''",
                display: 'block',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: '-1px',
                height: height,
                background: `linear-gradient(to left, transparent, ${dynamicColor}, transparent)`,
            },
        },
    }));
    const classes = useStyles({ dynamicColor });
    console.log(dynamicColor)
    return (
        <TableRow
            className={classes.separatorRow}
            sx={{ border: '2px solid transparent', textDecoration: 'none' }}
            hover
            component={Link}
            to={link}>
            {children}
        </TableRow>
    );
}