import { Box, Stack, Typography, Button, Select, MenuItem, FormControl, InputLabel, IconButton } from "@mui/material"
import UserName from "../../../tools/UserName"

import BlockIcon from '@mui/icons-material/Block';
import CloseIcon from '@mui/icons-material/Close';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import RuleIcon from '@mui/icons-material/Rule';
import TokenIcon from '@mui/icons-material/Token';
import RepeatIcon from '@mui/icons-material/Repeat';
import RepeatOnOutlinedIcon from '@mui/icons-material/RepeatOnOutlined';
import AddIcon from '@mui/icons-material/Add';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import TrackName from "../../../components/TrackName";
import RacerName from "../../../components/RacerName";
import RacerSelect from "../../../controls/RacerSelect";
import TrackSelect from "../../../controls/TrackSelect"

export default function RaceEvents({ race, edit, reff }) {

    const conmap = {
        l3: "3 Laps",
        l2: "2 Laps",
        l1: "1 Lap",
        l4: "4 Laps",
        l5: "5 Laps",
        um: "Unmirrored",
        mi: "Mirrored",
        tt: "Total Time",
        fl: "Fast Lap",
        ft: "Full Track",
        sk: "Skips",
        mu: "Upgrades",
        nu: "No Upgrades",
        ng: "New Game"
    }

    const eventmap = {
        selection: "selected a",
        tempban: "temp-banned a",
        permaban: "perma-banned a",
        override: "overrode a"
    }

    return (
        <Box pl={2}>
            <Stack spacing={edit ? 2 : 0}>
                {edit && race.events && Object.keys(race.events).map(key => {
                    let event = race.events[key]
                    let eventref = reff.child(key)
                    return (
                        <Stack direction='row' spacing={1} alignItems='center'>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Player</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={event.player}
                                    variant="standard"
                                    size='large'
                                    defaultValue={event.player}
                                    label='player'
                                    sx={{ minWidth: '150px' }}
                                    onChange={(e, v) => {
                                        console.log(e.target.value, v)
                                        eventref.child('player').set(e.target.value)
                                    }}
                                >
                                    <MenuItem value="">
                                        -
                                    </MenuItem>
                                    {Object.values(race.runs).map((r, i) => <MenuItem elevation={0} value={r.player}><UserName user_id={r.player} /></MenuItem>)}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-label">Event</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={event.event}
                                    variant="standard"
                                    onChange={(e) => {
                                        eventref.child('event').set(e.target.value)
                                    }}
                                >
                                    <MenuItem value="selection">
                                        Selected
                                    </MenuItem>
                                    <MenuItem value="tempban">
                                        Temp-banned
                                    </MenuItem>
                                    <MenuItem value="permaban">
                                        Perma-banned
                                    </MenuItem>
                                    <MenuItem value="override">
                                        Overrode
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={event.type}
                                    variant="standard"
                                    onChange={(e) => {
                                        eventref.child('type').set(e.target.value)
                                    }}
                                >
                                    <MenuItem value="racer">
                                        Racer
                                    </MenuItem>
                                    <MenuItem value="track">
                                        Track
                                    </MenuItem>
                                    <MenuItem value="condition">
                                        Condition
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {![null, undefined, ""].includes(event.type) && <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-label">Selection</InputLabel>

                                {event.type == 'racer' && <RacerSelect racer={[event.selection ? event.selection : []].flat()} mult={true} onChange={(e, v) => eventref.child('selection').set([e.target.value].flat())} />}
                                {event.type == 'track' && <TrackSelect track={event.selection} onChange={(e, v) => eventref.child('selection').set(e.target.value)} />}
                                {event.type == 'condition' && <Select
                                    value={event.selection}
                                    variant="standard"
                                    onChange={(e) => {
                                        eventref.update({ cost: 1, selection: e.target.value })
                                    }}
                                >
                                    <MenuItem value="">
                                        -
                                    </MenuItem>
                                    {Object.keys(conmap).map(c => <MenuItem value={c}>{conmap[c]}</MenuItem>)}
                                </Select>}

                            </FormControl>
                            }
                            <IconButton onClick={(e) => eventref.remove()}>
                                <ClearOutlinedIcon />
                            </IconButton>
                        </Stack>)
                })
                }
                {!edit && race?.events && Object.values(race.events).filter(e => ![null, undefined, ""].includes(e.player)).map(event => {
                    return <Stack direction="row" spacing={1} alignItems='center'>
                        <UserName user_id={event.player} discord={true} />
                        <Typography display={{ xs: 'none', md: 'block' }} color="text.secondary">{eventmap[event.event]} {event.type} </Typography>

                        {event.event == 'selection' ? event.repeat ? <RepeatIcon /> : <PanToolAltIcon /> : event.event == 'tempban' ? <CloseIcon /> : event.event == 'permaban' ? <BlockIcon /> : event.event == 'override' ? <RuleIcon /> : ""}
                        <Typography style={{ textDecoration: 'none', fontWeight: 700 }}>
                            {event.type == 'track' && <TrackName track={event.selection} />}
                            {event.type == 'racer' && <Stack direction='row' spacing={1} alignItems='center'>{[event.selection].flat().map(sel =>
                                (<RacerName racer={sel} />))}</Stack>}
                            {event.type == 'condition' && conmap[event.selection]}
                        </Typography>
                        {event.cost && event.cost > 0 ? <Stack direction="row"><Typography color='text.secondary'>{'for ' + event.cost}</Typography><TokenIcon sx={{ color: 'text.secondary' }} /></Stack> : ""}

                    </Stack>
                })}
                {edit &&
                    <Button mt={2} size='small' fullWidth variant='outlined' onClick={() => {
                        reff.push({ event: '', type: '', selection: '', player: '' })
                    }}><AddIcon />Add Event</Button>
                }
            </Stack>
        </Box>
    )
}