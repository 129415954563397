import { Chip, Stack, Tooltip } from "@mui/material"
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import { getTrack, getConditions } from "../../../hooks/useTourneyRuns.js";
import TrackName from "../../../components/TrackName.js";
import RacerName from "../../../components/RacerName.js";
import StarIcon from '@mui/icons-material/Star';
import RepeatIcon from '@mui/icons-material/Repeat';
export default function RaceHeader({ race }) {
    const track = getTrack(race)
    const conditions = getConditions(race)
    const overrides = race.events ? Object.values(race.events).filter(e => e.event == 'override' && e.type == 'condition').map(e => e.selection) : []
    const conmap = {
        l3: "3 Laps",
        l2: "2 Laps",
        l1: "1 Lap",
        l4: "4 Laps",
        l5: "5 Laps",
        um: "Unmirrored",
        mi: "Mirrored",
        tt: "Total Time",
        fl: "Fast Lap",
        ft: "Full Track",
        sk: "Skips",
        mu: "Upgrades",
        nu: "No Upgrades",
        ng: "New Game"
    }

    return (
        <Stack direction='row' spacing={1} justifyContent="flex-start" alignItems="center">
            <TrackName mr={1} ml={1} track={track} />
            {race.events && Object.values(race.events).map(e => e.repeat).includes(true) && <RepeatIcon />}
            {race.gents?.terms && race.gents?.agreed && <Tooltip enterTouchDelay={0} title={race.gents.terms} placement='top'><Chip color='primary' variant="outlined" size="small" sx={{ fontSize: 12, }} label="Gentleman's Agreement" /></Tooltip>}
            {overrides.map(c => <Chip color={'primary'} variant="outlined" size="small" sx={{ fontSize: 12, }} label={conmap[c]} />)}
            {race?.pod && <Chip color='primary' variant="outlined" size="small" sx={{ fontSize: 12, }} ><RacerName racer={race.pod} /></Chip>}
            {race.highlight && <Tooltip placement='top' enterTouchDelay={0} title='This race marked as Highlight-Worthy'>
                <MovieCreationIcon sx={{ fontSize: 16 }} ml={2} />
            </Tooltip>}
            {Object.values(race.runs).map(r => r.record).includes(true) && <StarIcon sx={{ fontSize: 18 }} />}
        </Stack>
    )
}