import React from 'react'
import RacerName from '../../components/RacerName'
import TrackName from '../../components/TrackName'
import { Typography, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Stack } from '@mui/material';
import { timefix } from '../../tools.js'
import { catcolors } from '../../data';
import RunFilter from './RunFilter';

export default function TimeSheetTab({ timesheet, Conditions, setConditions }) {
    return (
        <div>
            <RunFilter Conditions={Conditions} setConditions={setConditions} />
            <TableContainer component={Paper} elevation={1}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell >Track</TableCell>
                            <TableCell align='left'>Racer</TableCell>
                            <TableCell align='right'>Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(timesheet).map((run, trackindex) => {
                            return <TableRow key={trackindex} sx={{ alignItems: "center", justifyContent: "center", borderRight: run.time ? 1 : "", borderColor: catcolors[run.source] }}>
                                <TableCell >
                                    <TrackName track={trackindex} color={run.time ? '' : 'text.secondary'} />
                                </TableCell>
                                <TableCell align='left' >
                                    <RacerName racer={run.racer} />
                                </TableCell >
                                <TableCell align='right'>
                                    <Stack>
                                        <Typography style={{ fontFamily: 'verdana', fontSize: 24, fontWeight: 1000 }}>{run.time ? timefix(run.time) : ""}</Typography>
                                    </Stack>

                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    )
}
