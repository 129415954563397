import { Accordion, AccordionSummary, AccordionDetails, Stack, Typography, Box, Checkbox } from "@mui/material"

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RaceEvents from './RaceEvents.js';
import RaceTable from './RaceTable.js';
import RaceHeader from "./RaceHeader.js";
import { planets, tracks } from "../../../data.js";
import { getTrack, getWinner } from "../../../hooks/useTourneyRuns.js";
import UserName from "../../../tools/UserName.js";
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import MovieIcon from '@mui/icons-material/Movie';
import TrackSelect from "../../../controls/TrackSelect.js";
export default function RaceAccordion({ race, index, TimeDiff, setTimeDiff, edit, reff }) {
    const trackcolor = planets[tracks[getTrack(race)]?.planet]?.color
    return (
        <Accordion sx={{
            background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, ${trackcolor} 800%)`,
            borderBottom: `solid 1px ${trackcolor}`
        }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{ width: '100%' }}>
                    <Stack direction='row' justifyContent='space-between' alignItems='space-between'>
                        <Stack direction='row' alignItems='center' justifyContent='center' spacing={2}>
                            <Typography variant="button" sx={{ fontSize: 14, opacity: '50%' }}>RACE {index + 1}</Typography>
                            <RaceHeader race={race} />
                        </Stack>
                        <UserName user_id={getWinner(race).player} discord={true} />
                    </Stack>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {edit && <Checkbox icon={<MovieCreationOutlinedIcon />} checkedIcon={<MovieIcon />} checked={race.highlight} onChange={(event) => {
                    reff.child('highlight').set(event.target.checked)
                }} >Highlight Worthy</Checkbox>}
                {edit && <TrackSelect track={race.track} onChange={(e, v) => reff.child('track').set(e.target.value)} />}
                <RaceEvents race={race} edit={edit} reff={reff?.child('events')} />
                <RaceTable race={race} TimeDiff={TimeDiff} setTimeDiff={setTimeDiff} edit={edit} reff={reff?.child('runs')} />
            </AccordionDetails>
        </Accordion>
    )
}