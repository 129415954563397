import React from 'react'
import { Typography, Stack } from '@mui/material';
import UnformattedLink from './Link.js';
import { circuits } from '../data.js'
import { ReactSVG } from "react-svg";
export default function CircuitName({ circuit, link }) {
    const CustomIcon = ({ color }) => (
        <ReactSVG src={`/circuit.svg`}
            beforeInjection={(svg) => {
                svg.setAttribute('fill', color); // Set the desired color
            }}
            style={{ width: '20px', height: '20px' }}
        />
    );
    const Track = (
        ![null, undefined, ''].includes(circuit) && <Stack color="inherit" direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
            {<CustomIcon color={circuits[circuit].color} />}
            <Typography color="inherit" variant="body2" sx={{ fontWeight: 500, display: { xs: 'none', md: 'block' } }}>
                {circuits[circuit].name}
            </Typography>
        </Stack>
    );

    return (
        link ? (
            <UnformattedLink to={`/circuits/${circuit}`} >
                {Track}
            </UnformattedLink >
        ) : (
            <div>
                {Track}
            </div>
        )
    )
}
