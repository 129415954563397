import { useState, useEffect } from 'react';
import { projectDatabase } from '../firebase/config';

export function useMatches() {
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    const matchesRef = projectDatabase.ref('tourney/matches');

    // Subscribe to changes and set the initial matches data
    const handleMatchesSnapshot = (snapshot) => {
      const matchesData = snapshot.val();
      const matchesList = [];
      for (let matchesId in matchesData) {
        matchesList.push({
          id: matchesId,
          ...matchesData[matchesId],
        });
      }
      setMatches(matchesList);
    };

    matchesRef.on('value', handleMatchesSnapshot);

    // Detach the listener when the component unmounts
    return () => {
      matchesRef.off('value', handleMatchesSnapshot);
    };
  }, []);

  return matches;
}