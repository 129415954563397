import { Typography, Stack, Tooltip, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, IconButton, Skeleton, Badge, Checkbox } from '@mui/material';
import { placementcolors } from '../tournaments/leaderboards/PlacementColors.js';
import RunTourneyDesc from '../tournaments/leaderboards/RunTourneyDesc.js';
import UserName from '../../tools/UserName.js'
import RestoreIcon from '@mui/icons-material/Restore';
import moment from 'moment'
import NotesIcon from '@mui/icons-material/Notes';
import { ordinalSuffix } from '../../tools/OrdinalSuffix.js';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import TimeDisplay from '../../tools/TimeDisplay.js';
import { useState } from 'react';

import ScienceIcon from '@mui/icons-material/Science';
import CustomTableRow from './CustomTableRow.js';


export default function Leaderboard({ runs, color, Sheet, loading }) {
    const [TimeDiff, setTimeDiff] = useState(false)
    const [BestCondition, setBestCondition] = useState(true)
    const filteredruns = runs.filter(r => (!BestCondition | r.tas) | (r?.rank > -1 && !r.tas))



    if (loading) {
        return (<Stack spacing={2}>
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
            <Skeleton variant="rectangular" animation="wave" height={50} />
        </Stack>)
    }
    return (
        <TableContainer >
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>Rank</TableCell>
                        <TableCell align='left' sx={{ pl: 0 }}>Player<Checkbox defaultChecked onClick={() => { setBestCondition(!BestCondition) }} icon={<FilterAltOffIcon />} checkedIcon={<FilterAltIcon />} /></TableCell>
                        <TableCell align="right" sx={{ pr: 4 }}>Time<IconButton onClick={() => { setTimeDiff(!TimeDiff) }}>
                            {TimeDiff ? <AccessTimeIcon sx={{ fontSize: '18px' }} /> : <MoreTimeIcon sx={{ fontSize: '18px' }} />}
                        </IconButton></TableCell>
                        <TableCell align='center' sx={{ pl: 0 }}>Pod</TableCell>
                        <TableCell align='center' >Platform</TableCell>
                        <TableCell align='right' sx={{ pr: 2 }}>{Sheet == 0 ? 'Date' : Sheet == 1 ? 'Match' : Sheet == 2 ? 'Challenge ' : ''}</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>

                    {filteredruns.map((run, runindex) => {
                        return <CustomTableRow
                            key={runindex}
                            link={run.match ? `/tournaments/${run.tourney?.id}/matches/${run.match}` : `/runs/${run?.id}`}
                            dynamicColor={run.tas ? '#00FFFF' : run?.rank < 3 && run?.rank > -1 ? placementcolors[run?.rank].fg : color}
                            height={run?.rank < 3 ? '3px' : '0.5px'}
                        >
                            <TableCell color={run.rank == 0 ? "#BBBBFF" : ""} align='center'>
                                {run.tas ? <ScienceIcon sx={{ color: '#00FFFF' }} /> : run.rank < 3 && run.rank > -1 ? <img src={`/placement flags/fill_${run.rank}.png`} width={33} height={45} /> : <Typography variant="h7" color='text.secondary'>{run?.rank > -1 ? run?.rank + 1 : '-'}<sup>{run?.rank > -1 ? ordinalSuffix(run?.rank) : ""}</sup></Typography>}
                            </TableCell>
                            <TableCell sx={{ padding: 0 }}>
                                <Stack direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}>
                                    <UserName user_id={run.player} avatar={true} link={true} size={30} />
                                </Stack>
                            </TableCell>
                            <TableCell align="right" sx={{ padding: 1, pr: 4 }}>
                                <Stack>
                                    <Stack direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        spacing={1} sx={{ fontFamily: 'monospace', textDecoration: 'none', fontWeight: 700 }}
                                    >
                                        {![null, undefined, "", 0].includes(run.deaths) && <Badge badgeContent={run.deaths > 1 ? run.deaths : ""}><img src={'/deaths.png'} width={20} height={20} /></Badge>}
                                        {run.retroskip && <Tooltip enterTouchDelay={0} placement="top" title={"Retroactively marked as a skip run"}><RestoreIcon sx={{ fontSize: 18 }} /></Tooltip>}
                                        {![undefined, null, ""].includes(run.notes) && <Tooltip enterTouchDelay={0} placement="top" title={run.notes} ><NotesIcon color='text.secondary' sx={{ fontSize: 15, color: 'text.secondary' }} /></Tooltip>}
                                        <TimeDisplay TimeDiff={TimeDiff} first={runindex == 0} time={TimeDiff && runindex > 0 ? run.time - filteredruns[0].time : run.time} />
                                    </Stack>
                                </Stack>
                            </TableCell>
                            <TableCell sx={{ padding: 0 }} align='center'>
                                <Stack justifyContent="center" >
                                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>{![null, undefined, ""].includes(run.pod) && <img src={`/flags/${run.pod}.png`} width={25} height={18} />}
                                    </Stack>
                                    {run?.podbans?.length > 0 && <Stack display={{ xs: 'none', md: 'block' }} direction='row' justifyContent="flex-start" alignItems="center" spacing={1}>
                                        <Typography variant="caption" color='text.secondary'>Bans: </Typography>
                                        {run.podbans.map(pod => { return <img sx={{ opacity: "0.5" }} src={`/flags/${pod}.png`} width={15} height={11} /> })}
                                    </Stack>}
                                </Stack>
                            </TableCell>
                            <TableCell align='center'>
                                {![null, undefined, ""].includes(run.platform) && <img sx={{ colorFill: '#555555' }} src={`/platforms/${run.platform}.svg`} width={20} />}
                            </TableCell>
                            <TableCell sx={{ padding: 0, paddingRight: 2 }} align='right' >
                                {Sheet == 0 ? moment(run.date).fromNow() :
                                    Sheet == 1 ? RunTourneyDesc(run) :
                                        Sheet == 2 ? "" : ""}
                            </TableCell>
                        </CustomTableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer >
    )
}