import { Card, CardActionArea, Stack, Typography, Paper } from '@mui/material';
import UserName from '../../../tools/UserName';
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getMatchDesc, getMatchWinner, getPlayers, getScore } from '../../../hooks/useTourneyRuns';
import { useTournaments } from '../../../hooks/useTournaments';
import { useRulesets } from '../../../hooks/useRulesets';
import { timefix } from '../../../tools';
const MatchCard = ({ match, verbose, hidescore }) => {
    const players = getPlayers(match)

    const winner = getMatchWinner(match)
    const rulesets = useRulesets()
    const tourneys = useTournaments()
    const qual = rulesets.find(r => r.id == match.ruleset)?.general?.type == 'Qualifier'
    const score = getScore(match, qual)
    const thistourney = tourneys.find(t => t.id == match.tourney)
    return (
        <Card elevation={2} sx={{ width: 200 }}>

            <CardActionArea component={Link} to={`/tournaments/${match.tourney}/matches/${match.id}`}>
                <Paper elevation={0} sx={{ borderRadius: 0, display: 'grid', paddingLeft: 1, paddingRight: 1 }} >
                    <Stack direction='row' justifyContent={verbose ? 'space-between' : 'center'}>
                        {verbose && <Typography variant='caption' justifySelf={'center'} color='text.secondary'>{getMatchDesc({ match, tournament: thistourney, short: true })}</Typography>}
                        <Typography variant='caption' justifySelf={'center'} color='text.secondary'>{moment(match.datetime).format('D MMM yyyy')}</Typography>
                    </Stack>
                </Paper>
                <Stack spacing={0} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                    {players.length <= 4 && Object.values(players).sort((a, b) =>
                        (qual ? -1 : 1) * (score[b] - score[a])
                    ).map((p, i) =>
                        <Stack
                            direction="row"
                            spacing = {1}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <div style={{ flexShrink: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                <UserName user_id={p} link={false} avatar={true} discord={true} size={20} style={{ flexShrink: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} />
                            </div>
                            <div style={{ flexShrink: 0 }}>
                                {match.datetime < Date.now() && <Typography style={{ flexShrink: 0 }} textAlign='right' color={(p == winner ? "#FFFFFF" : "text.secondary")} sx={{ fontWeight: 700 }}>{qual ? timefix(score[p]) : score[p]}</Typography>}
                            </div>

                        </Stack>
                    )}

                    {players.length > 4 && <Typography>{players.length} Players</Typography>}
                </Stack>
            </CardActionArea>
        </Card>
    );
};

export default MatchCard