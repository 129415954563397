import { Typography, Container, Box, Stack, Skeleton, Breadcrumbs, ToggleButtonGroup, ToggleButton } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { Redirect, Link, useLocation, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import 'chartjs-adapter-moment'
import { useTournaments } from '../../hooks/useTournaments.js';
import { useRulesets } from '../../hooks/useRulesets.js';
import { useRacers } from '../../hooks/useRacers.js';
import { tourneyboard } from '../../hooks/useTourneyRuns.js';
import LeaderboardControls from './LeaderboardControls.js'
import Leaderboard from './Leaderboard.js'
import HomeIcon from '@mui/icons-material/Home';
import LeaderboardAll from './LeaderboardAll.js';
import { usePlanets } from '../../hooks/usePlanets.js';
import { useTracks } from '../../hooks/useTracks.js';
import { compareConditions } from './src_functions.js';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { projectDatabase } from '../../firebase/config.js';
import LoadingBar from 'react-top-loading-bar';

import Dashboard from './Dashboard.js';
import TrackSelect from '../../controls/TrackSelect.js';
import SourceTabs from '../../controls/SourceTabs.js';
export default function Leaderboards(props) {
    const location = useLocation();
    const history = useHistory();

    const [runs, setRuns] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const tournaments = useTournaments()
    const rulesets = useRulesets()
    const tracks = useTracks();
    const racers = useRacers()
    const planets = usePlanets()


    const [Conditions, setConditions] = useState(
        {
            trak: ['ft'], //fs, bg, ai
            upgr: ['mu'], //nu
            time: ['tt'], //fl
            laps: ['l3'], //l1, l2, l4, l5
            mirr: ['um'], //mi
            dire: ['fw'], //bw
            play: ['1p'] //2p
        }
    )
    const [FilterTourneys, setFilterTourneys] = useState([])
    const [Tas, setTas] = useState(false)
    const [Qualifying, setQualifying] = useState(false)
    const [Board, setBoard] = useState('leaderboard')
    const urltrack = location.pathname.split("/")[2].toLowerCase()

    const searchParams = new URLSearchParams(location.search);
    const [track, setTrack] = useState((tracks.find(t => t.name.replaceAll(" ", "_").replaceAll("'", "").toLowerCase() == urltrack)?.tracknum) - 1)
    const [Sheet, setSheet] = useState(Number(searchParams.get('source') ?? 0))
    const planetcolor = planets[tracks[track]?.planet]?.color ?? '#FFFFFF'

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true); // Set isLoading to true when fetching data
            setProgress(30); // Update the progress bar to a certain value

            let trackParam = track
            let sourceParam = Sheet
            if ([null, undefined, ''].includes(trackParam) || [null, undefined, ''].includes(sourceParam)) {
                trackParam = 0
                sourceParam = 0
            }

            let leaderboardRef;
            if (sourceParam === 0) {
                // Fetch data for speedruns source
                leaderboardRef = projectDatabase.ref(`speedruns`).orderByChild('track').equalTo(track);
            } else if (sourceParam === 1) {
                // Fetch data for tournaments source
                leaderboardRef = projectDatabase.ref(`tourney/matches`);
            } else if (sourceParam === 2) {
                // Fetch data for challenges source
                leaderboardRef = projectDatabase.ref(`challenge/times`).orderByChild('track').equalTo(track);
            } else {
                // Invalid source, clear leaderboard data
                setRuns([]);
                return;
            }

            leaderboardRef.on('value', (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const leaderboardData = Object.values(data);
                    let _runs = []
                    if (sourceParam == 0) {
                        _runs = leaderboardData.filter(r => r.category == 0).sort((a, b) => a.time - b.time)
                    } else if (sourceParam == 1) {
                        _runs = tourneyboard({ matches: leaderboardData, rulesets, track: track, tournaments })
                        //bestruns = tourneybest({ leaderboardData, rulesets, tournaments, FilterTourneys })
                    } else if (sourceParam == 2) {
                        _runs = leaderboardData.filter(r => r.track == track).map(r => {
                            return {
                                ...r,
                                pod: r.racer,
                                player: r.user,
                                conditions: [(r.conditions.backwards ? 'bw' : 'fw'), ("l" + r.conditions.laps), (r.conditions.mirror ? 'mi' : 'um'), (r.conditions.nu ? 'nu' : 'mu'), (r.conditions.skips ? 'fs' : 'ft')]
                            }
                        }).sort((a, b) => a.time - b.time)
                    }
                    setRuns(_runs);
                } else {
                    setRuns([]);
                }
                setIsLoading(false); // Set isLoading to false after data is fetched
                setProgress(100); // Update the progress bar to 100% after data is fetched
            });

            const newSearchParams = new URLSearchParams();
            newSearchParams.set('source', sourceParam);
            history.push(`${tracks[trackParam].name.replaceAll(" ", "_").replaceAll("'", "")}?${newSearchParams.toString()}`);
        };

        if (![null, undefined, ''].includes(Sheet) && ![null, undefined, ''].includes(track)) {
            setProgress(0); // Reset the progress bar to 0 when a new source and track are selected
            fetchData();
        }
    }, [Sheet, track]);

    useEffect(() => {
        setTrack((tracks.find(t => t.name.replaceAll(" ", "_").replaceAll("'", "").toLowerCase() == urltrack)?.tracknum) - 1)
    }, [tracks])

    let boardruns = [...runs]
    let bestruns = []

    let cons = ['ft', 'fs', 'bg', 'ai', 'mu', 'nu', 'tt', 'fl', 'l1', 'l2', 'l3', 'l4', 'l5', 'um', 'mi', 'fw', 'bw', '1p', '2p', 'qu', 'ts']
    let con_options = {}
    let con_disabled = {}
    cons.forEach(c => {
        con_options[c] = track == 'all' ? false : true
        con_disabled[c] = true
    })


    boardruns.forEach(run => {
        Object.keys(con_options).forEach(con => {
            if (Object.values(run.conditions).includes(con) || (con == 'ts' && run.tas) || (con == 'qu' && run.qual)) {
                con_options[con] = false
            }
            let confilter = {
                upgr: ['mu', 'nu'].includes(con) ? [con] : Conditions.upgr,
                trak: ['ft', 'fs', 'bg', 'ai'].includes(con) ? [con] : Conditions.trak,
                time: ['tt', 'fl'].includes(con) ? [con] : Conditions.time, //fl
                laps: ['l1', 'l2', 'l3', 'l4', 'l5'].includes(con) ? [con] : Conditions.laps, //l1, l2, l4, l5
                mirr: ['um', 'mi'].includes(con) ? [con] : Conditions.mirr, //mi
                dire: ['fw', 'bw'].includes(con) ? [con] : Conditions.dire, //bw
                play: ['1p', '2p'].includes(con) ? [con] : Conditions.play //2p
            }

            if (compareConditions(confilter, run.conditions)) {
                con_disabled[con] = false
            }
        })
    })


    let newcon = { ...Conditions }
    Object.keys(Conditions).forEach(key => {
        Conditions[key].forEach(con => {
            if (con_options[con]) {
                newcon[key].splice(newcon[key].indexOf(con), 1)
            }
        })
        if (newcon[key].length == 0) {
            if (key == 'dire') {
                newcon[key].push('fw')
            } else if (key == 'laps') {
                newcon[key].push('l3')
            } else if (key == 'mirr') {
                newcon[key].push('um')
            } else if (key == 'play') {
                newcon[key].push('1p')
            } else if (key == 'time') {
                newcon[key].push('tt')
            } else if (key == 'trak') {
                newcon[key].push('ft')
            } else if (key == 'upgr') {
                newcon[key].push('mu')
            }
        }
    })

    const filteredruns = boardruns.filter(run => compareConditions(Conditions, run.conditions) && (!run.qual || Qualifying) && (!run.tas || Tas) && (Sheet > 0 || run.verified))
    let already = []
    let rank = 0
    const rankedruns = filteredruns.map(r => {
        if (!already.includes(r.player) && !r.tas) {
            r.rank = rank
            rank++
        } else {
            r.rank = -1
        }
        if (!r.tas) {
            already.push(r.player)
        }
        return r
    })

    return (
        <Box sx={{ background: 'linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, ' + (track == null ? "#FFFFFF" : planetcolor) + "  500%)" }}>
            <LoadingBar progress={progress} color={planetcolor} />
            <Container sx={{ minHeight: '100vh' }}>
                <Helmet>
                    <title>{track == null ? 'All Records' : tracks[track]?.name}</title>
                </Helmet>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                            style={{ textDecoration: 'none', color: "#666666" }}
                            color="inherit"
                            to="/"
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Home
                        </Link>
                        <TrackSelect track={track} onChange={(e) => {
                            //history.push('/leaderboards/' + (e.target.value == 'all' ? 'all' : tracks[e.target.value].name.replaceAll(" ", "_").replaceAll("'", "")))
                            setTrack(e.target.value)
                        }} />
                    </Breadcrumbs>
                    <ToggleButtonGroup value={Board} sx={{ maxHeight: '30px' }}>
                        <ToggleButton onClick={(e, value) => setBoard(value)} value='leaderboard'><TableRowsIcon /></ToggleButton>
                        <ToggleButton onClick={(e, value) => setBoard(value)} value='dashboard'><AnalyticsIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
                {track == null && <Redirect to='/tournaments/leaderboards/all' />}
                {tournaments && <Box sx={{ width: '100%' }}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Stack pt={2} direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                            {track !== null && !isLoading && tracks.length > 0 &&  <img src={planets[tracks[track]?.planet]?.img} width={50} height={50} />}
                            <Typography variant="h4" sx={{
                                fontSize: { xs: '32px', md: '64px' },
                                fontFamily: 'impact',
                                fontStyle: 'italic',
                                background: `-webkit-linear-gradient(90deg, ${track !== null ? planets[tracks[track]?.planet]?.color : "#FFFFFF"} 0%, rgba(255, 0, 0, 0) 100%)`,
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent"
                            }}>
                                {isLoading ? <Skeleton width={500} /> : tracks[track]?.name.toUpperCase() + "⠀"}
                            </Typography>
                        </Stack>

                    </Stack>
                    <LeaderboardControls options={con_options} color={planetcolor} disabled={con_disabled} board={track} Conditions={Conditions} setConditions={setConditions} Sheet={Sheet} setQualifying={setQualifying} Qualifying={Qualifying} Tas={Tas} setTas={setTas} setSheet={setSheet} />
                    <SourceTabs Sheet={Sheet} setSheet={setSheet} />
                    {track !== 'all' && Board == 'leaderboard' && <Leaderboard runs={rankedruns} track={track} Sheet={Sheet} loading={isLoading} color = {planetcolor} />}
                    {track == 'all' && Board == 'leaderboard' && <LeaderboardAll BestTimes={bestruns} tracks={tracks} />}
                    {Board == 'dashboard' && <Dashboard runs={rankedruns} racers={racers} />}
                </Box>}
            </Container>
        </Box>
    )
}

