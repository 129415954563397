import { useState, useEffect } from 'react';
import { projectDatabase } from '../firebase/config';

export function useRacers() {
  const [racers, setRacers] = useState([]);

  useEffect(() => {
    const racersRef = projectDatabase.ref('racers');
    racersRef.once('value', (snapshot) => {
      const racersData = snapshot.val();
      const racersList = [];
      for (let id in racersData) {
        racersList.push({
          id: id,
          ...racersData[id],
        });
      }
      setRacers(racersList);
    });
  }, []);

  return racers;
}