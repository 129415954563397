import { useParams } from 'react-router-dom'
import { useCollection } from '../../../hooks/useCollection';
import { Link } from 'react-router-dom'
import { Button } from '@mui/material';
import { Typography, Grid, Card, Box, Chip, Stack, Tooltip, FormGroup, FormControlLabel, Checkbox, Modal, Switch } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import GavelIcon from '@mui/icons-material/Gavel';
import MicIcon from '@mui/icons-material/Mic';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { planets, racers, tracks } from '../../../data.js'
import { timefix, timetoSeconds } from '../../../tools.js'
import BlockIcon from '@mui/icons-material/Block';
import CloseIcon from '@mui/icons-material/Close';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import TokenIcon from '@mui/icons-material/Token';
import RepeatIcon from '@mui/icons-material/Repeat';
import RuleIcon from '@mui/icons-material/Rule';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import NotesIcon from '@mui/icons-material/Notes';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import { useFirebase } from '../../../hooks/useFirebase';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom'
import React, { useState } from 'react'


export default function Match() {
    const history = useHistory()
    const { addDocument, updateDocument, response } = useFirebase('tourney/matches/')
    const { id } = useParams()
    const [ModalOpen, setModalOpen] = useState({})
    const matchCollection = useCollection('tourney/matches/')
    const participantCollection = useCollection('tourney/participants')
    const rulesetCollection = useCollection('tourney/rulesets/saved')
    const tourneyCollection = useCollection('tourney/tournaments')
    let thismatch = null
    let previous = [], next = []
    let tourneys = null
    let rulesets = null
    let racetracks = null, specialcon = []
    let thisdate = null
    let participants = null
    let players = []
    const conmap = {
        l3: "3 Laps",
        l2: "2 Laps",
        l1: "1 Lap",
        l4: "4 Laps",
        l5: "5 Laps",
        um: "Unmirrored",
        mi: "Mirrored",
        tt: "Total Time",
        fl: "Fast Lap",
        ft: "Full Track",
        sk: "Skips",
        mu: "Upgrades Allowed",
        nu: "No Upgrades",
        ng: "New Game"
    }
    function getUsername(user) {
        participants = participantCollection.documents
        let name = ""
        Object.values(participants).forEach(participant => {
            if (participant.id == user) {
                name = participant.name
                return
            }
        })
        return name
    }
    const openNotes = (event) => {
        let modals = { ...ModalOpen }
        let index = event.target.id
        modals[index] = true
        setModalOpen(modals)
        console.log(ModalOpen)
    }
    const closeNotes = () => {
        let modals = { ...ModalOpen }
        Object.keys(modals).forEach(key => {
            modals[key] = false
        })
        setModalOpen(modals)
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const eventmap = {
        selection: "selected a",
        tempban: "temp-banned a",
        permaban: "perma-banned a",
        override: "overrode a"
    }
    if (![undefined, null].includes(matchCollection.documents) && ![undefined, null].includes(participantCollection.documents) && ![undefined, null].includes(tourneyCollection.documents) && ![undefined, null].includes(rulesetCollection.documents)) {
        tourneys = tourneyCollection.documents
        let matches = matchCollection.documents
        rulesets = rulesetCollection.documents
        participants = participantCollection.documents
        thismatch = matches[id]
        racetracks = []
        if (thismatch?.players) {
            players = Object.values(thismatch.players)
        } else if (thismatch.races) {
            players = Object.values(thismatch.races[0].runs).map(r => r.player)
        }
        previous = Object.keys(matches).filter(key => {
            return Number(matches[key].datetime) < Number(thismatch.datetime)
        }).sort(function (a, b) {
            return Number(matches[b].datetime) - Number(matches[a].datetime)
        })
        next = Object.keys(matches).filter(key => {
            return Number(matches[key].datetime) > Number(thismatch.datetime)
        }).sort(function (a, b) {
            return Number(matches[a].datetime) - Number(matches[b].datetime)
        })
        if (thismatch.races) {
            thismatch.races.forEach(race => {
                let thistrack = null
                let conditions = null
                if (thismatch.ruleset) {
                    conditions = { ...rulesets[thismatch.ruleset].general.default }
                }
                if (race.events) {
                    Object.values(race.events).forEach(event => {
                        if (event.event == 'selection' && event.type == 'track') {
                            thistrack = event.selection
                        }
                        if (event.event == 'override' && event.type == 'condition') {
                            specialcon.push(event.selection)
                            if (event.selection == 'nu') {
                                conditions.upgr = 'nu'
                            }
                            if (event.selection == 'fl') {
                                conditions.time = 'fl'
                            }
                            if (event.selection == 'sk') {
                                conditions.trak = 'sk'
                            }
                        }
                    })
                }
                racetracks.push({ track: thistrack, con: conditions })
            })
        }
    }
    if (thismatch?.datetime) {
        thisdate = new Date(Number(thismatch?.datetime))//.toISOString().slice(0, 16)
        let offset = thisdate.getTimezoneOffset() * 60 * 1000;
        thisdate = thisdate - offset
        thisdate = new Date(thisdate).toISOString().slice(0, 16)
    }

    return (
        <Grid container alignItems='center' justifyContent='center'>
            <Grid item xs={12} md={8}>

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        {false && <Link to={previous.length == 0 ? `/tournaments/matches/${id}` : `/tournaments/matches/${previous[0]}/edit`}><Button sx={{ 'text-decoration': "none", mr: 1 }} disabled={previous.length == 0}><ChevronLeftIcon /></Button></Link>}
                        <Button href={'/tournaments/matches'} sx={{ 'text-decoration': "none", mr: 1 }}>ALL MATCHES</Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {false && <Link to={next.length == 0 ? `/tournaments/matches/${id}` : `/tournaments/matches/${next[0]}/edit`}><Button mr={2} sx={{ 'text-decoration': "none" }} disabled={next.length == 0}><ChevronRightIcon /></Button></Link>}
                    </Box>
                    <Card>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Typography ml={2} sx={{ fontSize: 14, mr: 1 }}>{tourneys?.[thismatch?.tourney]?.name + (tourneys?.[thismatch?.tourney]?.stages?.[thismatch?.bracket] ? ": " + tourneys?.[thismatch?.tourney]?.stages?.[thismatch?.bracket].bracket : ": " + thismatch?.bracket)}<br /></Typography>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Typography ml={2} mr={2} sx={{ fontSize: 14, }} color="text.secondary">{new Date(thismatch?.datetime).toLocaleString()}</Typography>
                        </Box>
                        {thismatch?.players && <Typography ml={2} variant="h4">{(thismatch?.players ? thismatch?.players.map(p => getUsername(p)).join(" vs ") : thismatch?.races[0].runs.map(run => getUsername(run.player)).join(" vs "))}</Typography>}
                        <Typography mb={1} mt={1} ml={2} sx={{ fontSize: 14 }} color="text.secondary">
                            <GavelIcon sx={{ fontSize: 16, }} /> {rulesets?.[thismatch?.ruleset]?.general?.name} •  <MicIcon sx={{ fontSize: 16, }} /> {thismatch?.commentators && thismatch?.commentators.map(com => getUsername(com)).join(", ")}
                        </Typography>
                    </Card>
                    <br/>
                    {thismatch && participants && tourneys &&
                        <React.Fragment>
                            <FormControl sx={{ m: 1, minWidth: 240 }}>
                                <InputLabel id='tourneyselect'>Tournament</InputLabel>
                                <Select
                                    labelId="tourneyselect"
                                    id="demo-simple-select-helper"
                                    value={thismatch?.tourney}
                                    variant="standard"
                                    label='Tournament'
                                    onChange={(e) => {
                                        thismatch.tourney = e.target.value
                                        updateDocument(id, thismatch)
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>-</em>
                                    </MenuItem>
                                    {tourneys && Object.keys(tourneys).map((t) => <MenuItem value={t}>{tourneys[t].name}</MenuItem>)}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, minWidth: 240 }}>
                                <InputLabel id='bracketselect'>Bracket</InputLabel>
                                <Select
                                    labelId="bracket"
                                    id="demo-simple-select-helper"
                                    value={thismatch?.bracket}
                                    variant="standard"
                                    label='Bracket'
                                    onChange={(e) => {
                                        thismatch.bracket = e.target.value
                                        thismatch.ruleset = tourneys[String(thismatch.tourney)].stages[thismatch.bracket].ruleset
                                        thismatch.round = tourneys[String(thismatch.tourney)].stages[thismatch.bracket].round
                                        updateDocument(id, thismatch)
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>-</em>
                                    </MenuItem>
                                    {tourneys && thismatch && ![null, undefined, ""].includes(thismatch.tourney) && tourneys[String(thismatch?.tourney)]?.stages && Object.keys(tourneys[String(thismatch?.tourney)]?.stages).map((s) => <MenuItem value={s}>{tourneys[String(thismatch?.tourney)]?.stages[s]?.bracket + " " + tourneys[String(thismatch.tourney)]?.stages[s]?.round}</MenuItem>)}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, minWidth: 240 }}>
                                <InputLabel id='playerselect'>Players</InputLabel>
                                <Select
                                    labelId="playerselect"
                                    id="demo-simple-select-helper"
                                    value={thismatch?.players ? thismatch?.players : []}
                                    variant="standard"
                                    multiple
                                    label='Players'
                                    onChange={(e) => {
                                        thismatch.players = e.target.value
                                        updateDocument(id, thismatch)
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>-</em>
                                    </MenuItem>
                                    {participants ? Object.values(participants).sort((a, b) => a.name.localeCompare(b.name)).map((p) => <MenuItem value={p.id}>{p.name}</MenuItem>) : ""}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, minWidth: 240 }}>
                                <InputLabel id='commselect'>Commentators</InputLabel>
                                <Select
                                    labelId="commselect"
                                    id="demo-simple-select-helper"
                                    value={thismatch?.commentators ? thismatch?.commentators : []}
                                    variant="standard"
                                    multiple
                                    label='Commentators'
                                    onChange={(e) => {
                                        thismatch.commentators = e.target.value
                                        updateDocument(id, thismatch)
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>-</em>
                                    </MenuItem>
                                    {participants && Object.values(participants).sort((a, b) => a.name.localeCompare(b.name)).map((p) => <MenuItem value={p.id}>{p.name}</MenuItem>)}
                                </Select>
                            </FormControl>



                            <TextField
                                sx={{ m: 1, minWidth: 240 }}
                                id="datetime-local"
                                type="datetime-local"
                                value={thisdate}
                                label='Date & Time'
                                variant='standard'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    thismatch.datetime = new Date(e.target.value).getTime()

                                    updateDocument(id, thismatch)
                                }}
                            />

                            <TextField
                                sx={{ m: 1, minWidth: 240 }}
                                defaultValue={thismatch.vod}
                                variant='standard'
                                label='Vod Link'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => {
                                    thismatch.vod = e.target.value
                                    updateDocument(id, thismatch)
                                }}
                            />

                            <FormControl sx={{ m: 1, minWidth: 240 }}>
                                <InputLabel id='playerselect'>Forfeiting Player</InputLabel>
                                <Select
                                    labelId="playerselect"
                                    id="demo-simple-select-helper"
                                    value={thismatch?.forfeit?.player ? thismatch?.forfeit?.player : []}
                                    variant="standard"
                                    label='Players'
                                    onChange={(e) => {
                                        console.log(e.target.value)

                                        if (!thismatch.forfeit) {
                                            thismatch.forfeit = {
                                                player: "",
                                                reason: ""
                                            }
                                        }
                                        thismatch.forfeit.player = e.target.value

                                        updateDocument(id, thismatch)

                                    }}
                                >
                                    <MenuItem value="">
                                        <em>-</em>
                                    </MenuItem>
                                    {thismatch.players ? Object.values(thismatch.players).map((p) => <MenuItem value={p}>{getUsername(p)}</MenuItem>) : ""}
                                </Select>
                            </FormControl>

                            <TextField
                                defaultValue={thismatch?.forfeit?.reason}
                                sx={{ m: 1, minWidth: 240 }}
                                variant='standard'
                                label='Forfeit Reason'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => {

                                    if (!thismatch.forfeit) {
                                        thismatch.forfeit = {
                                            player: "",
                                            reason: ""
                                        }
                                    }
                                    thismatch.forfeit.reason = e.target.value

                                    updateDocument(id, thismatch)
                                }}
                            />


                            <Box ml={2} mt={2}>
                                <Grid container spacing={4}>
                                    {thismatch?.races?.map((race, index) => {
                                        return <Grid item xs={12}>
                                            <Box sx={{ maxWidth: '900px' }}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography variant="button" sx={{ fontSize: 14, }} color="text.secondary">RACE {index + 1}</Typography>
                                                    <IconButton onClick={() => {
                                                        delete thismatch.races[index]
                                                        updateDocument(id, thismatch)
                                                    }}><DeleteIcon /></IconButton>
                                                    <FormGroup>
                                                        <FormControlLabel control={<Checkbox checked={thismatch.races[index].highlight} onChange={(event) => {
                                                            thismatch.races[index].highlight = event.target.checked
                                                            updateDocument(id, thismatch)
                                                        }} />} label="Highlight-Worthy" />
                                                    </FormGroup>
                                                    <IconButton id={String(index)} onClick={openNotes}>
                                                        <NotesIcon id={index} />
                                                    </IconButton>
                                                    <Modal
                                                        open={ModalOpen[index]}
                                                        onClose={closeNotes}
                                                    >
                                                        <Box sx={style}>
                                                            <Stack spacing={2}>
                                                                <Typography id="modal-modal-title" variant="h6" component="h2" color='#FFFFFF'>
                                                                    {"Race " + (index + 1) + " Notes"}
                                                                </Typography>
                                                                <TextField
                                                                    label={"Gentleman's Agreement"}
                                                                    defaultValue={thismatch.races[index].gents?.terms}
                                                                    onBlur={(e) => {
                                                                        thismatch.races[index].gents = { terms: e.target.value, agreed: true }
                                                                        updateDocument(id, thismatch)
                                                                    }}>

                                                                </TextField>
                                                                {Object.keys(race.runs).map(key => {
                                                                    let run = race.runs[key]
                                                                    return <React.Fragment>
                                                                        <TextField
                                                                            label={getUsername(run.player) + " Notes"}
                                                                            defaultValue={thismatch.races[index].runs[key].notes}
                                                                            onBlur={(e) => {
                                                                                run.notes = e.target.value
                                                                                updateDocument(id, thismatch)

                                                                            }}>

                                                                        </TextField>
                                                                        <FormGroup>
                                                                            <FormControlLabel color='#FFFFFF' control={
                                                                                <Switch
                                                                                    //defaultChecked={Object.values(thismatch.races[index].events).filter(e => e.event == 'override').map(e => e.selection).flat().includes('sk')}
                                                                                    onChange={(e) => {
                                                                                        run.retroskip = e.target.checked
                                                                                        updateDocument(id, thismatch)

                                                                                    }} />
                                                                            } label={getUsername(run.player) + " Retroskip"} />
                                                                        </FormGroup>
                                                                    </React.Fragment>
                                                                })}
                                                            </Stack>

                                                        </Box>
                                                    </Modal>
                                                </Stack>
                                                <TableContainer component={Paper}>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align='left'>Player</TableCell>
                                                                <TableCell align='left'>Event</TableCell>
                                                                <TableCell align='left'>Type</TableCell>
                                                                <TableCell align='left'>Selection</TableCell>
                                                                <TableCell align='left'>Actions</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>

                                                            {race?.events && Object.values(race.events).map((event, index) => {
                                                                return <TableRow>
                                                                    <TableCell align='left' padding='none'>
                                                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                                            <Select
                                                                                labelId="demo-simple-select-helper-label"
                                                                                id="demo-simple-select-helper"
                                                                                value={event.player}
                                                                                variant="standard"
                                                                                onChange={(e) => {
                                                                                    event.player = e.target.value
                                                                                    updateDocument(id, thismatch)
                                                                                }}
                                                                            >
                                                                                <MenuItem value="">
                                                                                    <em>-</em>
                                                                                </MenuItem>
                                                                                {players && Object.values(players).map((p) => <MenuItem value={p}>{getUsername(p)}</MenuItem>)}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell align='left' padding='none'>
                                                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                                            <Select
                                                                                labelId="demo-simple-select-helper-label"
                                                                                id="demo-simple-select-helper"
                                                                                value={event.event}
                                                                                variant="standard"
                                                                                onChange={(e) => {
                                                                                    event.event = e.target.value
                                                                                    updateDocument(id, thismatch)
                                                                                }}
                                                                            >
                                                                                <MenuItem value="selection">
                                                                                    Selected
                                                                                </MenuItem>
                                                                                <MenuItem value="tempban">
                                                                                    Temp-banned
                                                                                </MenuItem>
                                                                                <MenuItem value="permaban">
                                                                                    Perma-banned
                                                                                </MenuItem>
                                                                                <MenuItem value="override">
                                                                                    Overrode
                                                                                </MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell align='left' padding='none'>
                                                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                                            <Select
                                                                                labelId="demo-simple-select-helper-label"
                                                                                id="demo-simple-select-helper"
                                                                                value={event.type}
                                                                                variant="standard"
                                                                                onChange={(e) => {
                                                                                    event.type = e.target.value
                                                                                    updateDocument(id, thismatch)
                                                                                }}
                                                                            >
                                                                                <MenuItem value="racer">
                                                                                    Racer
                                                                                </MenuItem>
                                                                                <MenuItem value="track">
                                                                                    Track
                                                                                </MenuItem>
                                                                                <MenuItem value="condition">
                                                                                    Condition
                                                                                </MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell align='left' padding='none'>
                                                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                                            <Select
                                                                                labelId="demo-simple-select-helper-label"
                                                                                id="demo-simple-select-helper"
                                                                                value={Array.isArray(event.selection) ? event.selection : [event.selection]}
                                                                                variant="standard"
                                                                                multiple={event.event !== 'selection'}
                                                                                onChange={(e) => {
                                                                                    console.log(e.target.value)
                                                                                    let value = e.target.value
                                                                                    if (Array.isArray(value)) {
                                                                                        value = value.filter(v => ![null, undefined, ""].includes(v))
                                                                                        if (value.length > 1) {
                                                                                            event.cost = 1
                                                                                        } else {
                                                                                            event.cost = 0
                                                                                        }
                                                                                    }
                                                                                    if (event.type == 'condition' && event.event == 'override') {
                                                                                        event.cost = 1
                                                                                    }
                                                                                    if (event.type == 'track' && event.event == 'selection' && Object.values(thismatch.races).map(r => Object.values(r.events)).filter(e => e.event == 'selection' && e.type == 'track' && e.selection == value).length > 1) {
                                                                                        event.repeat = true
                                                                                    }
                                                                                    event.selection = value
                                                                                    updateDocument(id, thismatch)
                                                                                }}
                                                                            >
                                                                                <MenuItem value="">
                                                                                    -
                                                                                </MenuItem>
                                                                                {event.type == 'racer' ? racers.map((r, index) => <MenuItem value={index}>{racers[Number(index)].name}</MenuItem>) :
                                                                                    event.type == 'track' ? tracks.map((t, index) => <MenuItem value={index}>{tracks[Number(index)].name}</MenuItem>) :
                                                                                        Object.keys(conmap).map(c => <MenuItem value={c}>{conmap[c]}</MenuItem>)}
                                                                            </Select>
                                                                        </FormControl></TableCell>
                                                                    <TableCell>
                                                                        <Stack mb={0} direction="row">
                                                                            <IconButton size='small' disabled={index + 1 == Object.values(race.events).length}><ArrowDropDownIcon /></IconButton>
                                                                            <IconButton size='small' disabled={index == 0}><ArrowDropUpIcon /></IconButton>
                                                                            <IconButton size='small' onClick={() => {
                                                                                delete race.events[index]
                                                                                updateDocument(id, thismatch)
                                                                            }}><RemoveIcon /></IconButton>
                                                                        </Stack>
                                                                    </TableCell>
                                                                </TableRow>
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Button onClick={() => {
                                                    if (race.events) {
                                                        race.events = Object.values(race.events)
                                                    } else {
                                                        race.events = []
                                                    }
                                                    race.events.push({
                                                        player: "",
                                                        event: "",
                                                        type: "",
                                                        selection: "",
                                                        cost: 0
                                                    })
                                                    updateDocument(id, thismatch)
                                                }} sx={{ width: "100%" }}><AddIcon />Add Event</Button>
                                                {racetracks[index].track && <Box mt={2} sx={{ display: 'flex', flexDirection: 'row', pt: 0, alignItems: "center", justifyContent: "center", borderBottom: 2, borderColor: planets[tracks[racetracks[index].track].planet].color }} >
                                                    <img src={planets[tracks[racetracks[index].track].planet].img} vertical-align='middle' width={20} height={20} />
                                                    <Typography ml={1} variant="h5" sx={{ verticalAlign: 'top' }}>  {tracks[racetracks[index].track].name}</Typography>
                                                    <Box sx={{ flex: '1 1 auto' }} />
                                                    <Stack mb={0} direction="row" spacing={1}>{Object.values(racetracks[index].con).filter(c => !['mi', 'um'].includes(c)).map(c => <Chip color={specialcon.includes(c) ? 'primary' : 'default'} variant="outlined" size="small" sx={{ fontSize: 12, }} label={conmap[c]} />)}</Stack>
                                                </Box>}
                                                <TableContainer component={Paper}>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Player</TableCell>
                                                                <TableCell>Pod</TableCell>
                                                                <TableCell >Time</TableCell>
                                                                <TableCell>Deaths</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {race?.runs && Object.values(race.runs).map((run, index) => {
                                                                return <TableRow key={run.player}>
                                                                    <TableCell>
                                                                        {getUsername(run.player)}
                                                                    </TableCell>
                                                                    <TableCell padding='none'><FormControl sx={{ m: 1, minWidth: 120 }}>
                                                                        <Select
                                                                            labelId="demo-simple-select-helper-label"
                                                                            id="demo-simple-select-helper"
                                                                            value={run.pod}
                                                                            variant="standard"
                                                                            onChange={(e) => {
                                                                                run.pod = e.target.value
                                                                                updateDocument(id, thismatch)
                                                                            }}
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em>-</em>
                                                                            </MenuItem>
                                                                            {racers.map((r, index) => <MenuItem value={index}>{racers[Number(index)].name}</MenuItem>)}
                                                                        </Select>

                                                                    </FormControl></TableCell>
                                                                    <TableCell padding='none' align="left"><TextField
                                                                        required
                                                                        id="outlined-required"
                                                                        defaultValue={timefix(run.time)}
                                                                        onBlur={(e) => {
                                                                            if (String(e.target.value).toLowerCase() !== 'dnf') {
                                                                                run.time = timetoSeconds(e.target.value)
                                                                            } else {
                                                                                run.time = e.target.value
                                                                            }
                                                                            updateDocument(id, thismatch)

                                                                        }}
                                                                        variant="standard"
                                                                    /></TableCell>
                                                                    <TableCell padding='none' align="left"><TextField
                                                                        id="outlined-number"
                                                                        type="number"
                                                                        value={run.deaths}
                                                                        variant="standard"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        onChange={(e) => {
                                                                            let value = e.target.value < 0 ? "" : e.target.value
                                                                            run.deaths = value
                                                                            updateDocument(id, thismatch)
                                                                        }}
                                                                    /></TableCell>
                                                                </TableRow>
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </Box>
                                        </Grid>
                                    })}


                                </Grid>
                                <Button mt={2} variant="contained" sx={{ width: "900px" }} size="large" onClick={() => {
                                    let newraceindex = 0
                                    if (thismatch.races) {
                                        newraceindex = Object.values(thismatch.races).length
                                    } else {
                                        thismatch.races = []
                                    }
                                    thismatch.races[newraceindex] = {
                                        events: {},
                                        runs: {}
                                    }
                                    thismatch.players.forEach(player => {
                                        thismatch.races[newraceindex].runs[player] = {
                                            deaths: "",
                                            notes: "",
                                            platform: "",
                                            player: player,
                                            pod: "",
                                            time: ""
                                        }
                                    })
                                    updateDocument(id, thismatch)
                                }}><AddIcon />Add Race</Button>
                            </Box>
                        </React.Fragment>
                    }
                </Box >

            </Grid>
        </Grid>
    )
}
