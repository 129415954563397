import {
    Chart as ChartJS,
    CategoryScale,
    TimeScale,
    PointElement,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip as ChartTooltip,
    Legend,
} from 'chart.js'
import { Chart, Bar, Line } from 'react-chartjs-2';
import UserName from '../../tools/UserName';
import { Grid } from '@mui/material';
import moment from 'moment';
ChartJS.register(
    TimeScale,
    CategoryScale,
    PointElement,
    LinearScale,
    LineElement,
    BarElement,
    Title,
    ChartTooltip,
    Legend
)


export default function Dashboard({ runs, racers, users }) {
    let Records = []
    let podbins = { other: [], nextnextbestpod: [], nextbestpod: [], bestpod: [], unknown: [] }
    let labels = []
    let lowesttime = null
    let highesttime = null
    let bestpod = null
    let nextbestpod = null
    let nextnextbestpod = null
    runs.sort((a, b) => moment(a.date) - moment(b.date))
    let best = null
    runs.forEach(r => {
        if ((best == null || r.time < best) && ![null, undefined, '', 'DNF'].includes(r.time)) {
            Records.push(r)
            best = r.time
        }
    })
    const numbins = 60
    lowesttime = runs.filter(r => ![null, "", undefined, "DNF"].includes(r.time)).sort((a, b) => Number(a.time) - Number(b.time))[0]
    bestpod = lowesttime.pod
    nextbestpod = runs.filter(r => ![null, "", undefined, "DNF"].includes(r.time) && Number(r.pod) !== Number(bestpod)).sort((a, b) => Number(a.time) - Number(b.time))
    nextbestpod = nextbestpod.length > 0 ? nextbestpod[0].pod : null
    nextnextbestpod = runs.filter(r => ![null, "", undefined, "DNF"].includes(r.time) && Number(r.pod) !== Number(bestpod) && Number(r.pod) !== Number(nextbestpod)).sort((a, b) => Number(a.time) - Number(b.time))
    nextnextbestpod = nextnextbestpod.length > 0 ? nextnextbestpod[0].pod : null
    lowesttime = Math.floor(Number(lowesttime.time)) * 1000
    highesttime = Math.ceil(Number(runs.filter(r => ![null, "", undefined, "DNF"].includes(r.time)).sort((a, b) => Number(b.time) - Number(a.time))[0].time)) * 1000
    if (highesttime > lowesttime * 1.5 && lowesttime > 60 * 1000) {
        highesttime = Math.ceil(Number(runs.filter(r => ![null, "", undefined, "DNF"].includes(r.time) && (r.time * 1000 - (lowesttime * 1.5) < 0)).sort((a, b) => Number(b.time) - Number(a.time))[0].time)) * 1000
    }
    let spread = numbins * 1000
    for (let i = 0; i < numbins; i++) {
        labels.push(lowesttime + spread * i / numbins)
    }
    runs.filter(r => r.time !== 'DNF').forEach(r => {
        for (let i = 0; i < numbins; i++) {
            let pod = 'other'
            if (r.pod == bestpod) {
                pod = 'bestpod'
            } else if (r.pod == nextbestpod) {
                pod = 'nextbestpod'
            } else if (r.pod == nextnextbestpod) {
                pod = 'nextnextbestpod'
            } else if ([null, undefined, ''].includes(pod)){
                pod = 'unknown'
            }
            if (!podbins[pod][i]) {
                podbins[pod][i] = { x: lowesttime + spread * i / numbins, y: 0 }
            }
            if (Number(r.time) * 1000 - (lowesttime + spread * i / numbins) >= 0 && Number(r.time) * 1000 - (lowesttime + spread * (i + 1) / numbins) < 0) {
                podbins[pod][i].y++
            }
        }
    })
    let colors = ['white', 'red', 'cyan', 'yellow', 'grey']

    let linedata = {
        labels: Records.map(run => moment(run.date).format("YYYY-MM-DD")),//Records.map(run => users.find(u => u.id == run.player)?.name ?? 'unknown'),
        datasets: [
            {
                label: 'Time',
                data: Records.map(run => Number(run.time) * 1000),
                borderColor: "yellow",
                fill: false,

                // Change the stepped mode to explore different stepped chart options
                // false: no stepping
                // true: stepped before interpolation
                // 'before': step before interpolation
                // 'after': step after interpolation
                // 'middle': step middle interpolation
                stepped: true,
            }
        ]
    }
    let lineoptions = {
        responsive: true,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            title: {
                display: true,
                text: (ctx) => {
                    const { intersect, mode } = ctx.chart.options.interaction;
                    return 'Mode: ' + mode + ', intersect: ' + intersect;
                }
            },
        },
        scales: {
            y: {
                type: "time",
                grid: {
                    color: 'rgba(80, 80, 80, 10)'
                },
                time: {
                    unit: 'second',
                    displayFormats: {
                        second: "m:ss"
                    },
                    tooltipFormat: 'm:ss.SSS'
                },
                min: (Math.min(...Records.map(run => Number(run.time) * 1000)) - 1000),
                max: (Math.max(...Records.map(run => Number(run.time) * 1000)) + 1000)
            },
            x: {
                type: "time",
                time: {
                    unit: 'year',
                    displayFormats: {
                        second: "yyyy"
                    },
                    tooltipFormat: 'YYYY-MM-DD'
                },
                grid: {
                    display: false,
                },
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    beforeTitle: function (context) {
                        let index = context[0].dataIndex
                        return Records[index].user.name//`${tournaments[Records[index].tourney].nickname} - ${tournaments[Records[index].tourney].stages[Records[index].bracket].bracket} ${tournaments[Records[index].tourney].stages[Records[index].bracket].round}`
                    }
                }
            }
        }
    }
    let data = {
        labels: labels,
        datasets: Object.keys(podbins).sort((a, b) => Object.values(podbins[b]).length - Object.values(podbins[a]).length).map((key, index) => {
            return {
                label: (key == 'bestpod' ? racers[bestpod]?.name : key == 'nextbestpod' ? racers[nextbestpod]?.name : key == 'nextnextbestpod' ? racers[nextnextbestpod]?.name : key == 'total' ? 'Total' : "Other"),
                data: podbins[key],
                backgroundColor: colors[index],
                barPercentage: 1,
                categoryPercentage: 1
            }
        })
    }
    let options = {
        scales: {
            x: {
                type: "time",
                stacked: true,
                time: {
                    unit: 'second',
                    displayFormats: {
                        second: "m:ss"
                    },
                    tooltipFormat: 'm:ss.SSS'
                },
                offset: false,
                ticks: {
                    source: 'labels'
                },
                grid: {
                    display: false,
                },
                min: Math.floor((lowesttime / 1000) - 1) * 1000,
                max: lowesttime + spread,
                step: 1
            },
            y: {
                step: 1,
                precision: 1,
                stacked: true,
                grid: {
                    display: false,
                },
            }
        }
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <Bar height={'100px'} type='bar' options={options} data={data} />
            </Grid>
            <Grid item xs={12}>
                <Line height={'100px'} type='line' options={lineoptions} data={linedata} />
            </Grid>
        </Grid>
    )
}
